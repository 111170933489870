import React from 'react'
import './Page12Card.scss'
import { Link } from 'react-router-dom'
import { FaArrowRightLong } from "react-icons/fa6";
import { FormattedMessage } from 'react-intl'

const Page12Card = ({ bgImage, data, title, description, buttonText }) => {

    const bgImageDiv = {
        backgroundImage: `url(${bgImage})`
    }

  return (
    <div className='Page12Card'>
        <div className="page12Card__top" style={bgImageDiv}>
            <p className="leftTop">
                {buttonText}
            </p>

        </div>

        <div className="page12Card__bottom">
            <span>
            {data}
            </span>

            <h3>
            {title}
            </h3>

            <p>
            {description}
            </p>

            <Link to="">
                <FormattedMessage id='page12Card__1' defaultMessage={"Read More"} />
                <span>
                <FaArrowRightLong />
                </span>
            </Link>

        </div>

    </div>
  )
}

export default Page12Card