import React, { useEffect, useState } from 'react'
import './AboutUsPage.scss'
import Navbar from '../../components/shared/Navbar/Navbar'
import Footer from '../../components/shared/Footer/Footer'
import { Link } from 'react-router-dom'
import axios from 'axios'
import AOS from 'aos';
import { FormattedMessage } from 'react-intl'
import Page7Card from '../../components/Home/Page7/Page7Card/Page7Card'


const AboutUsPage = () => {
  const [pageData, setPageData] = useState([])

  useEffect(() => {
    axios.get('./data/HelpUsDoMore/HelpUsDoMore.json')
    .then(res => {
      setPageData(res.data)
    })
    .catch(error => {
        console.error("HelpUsDoMore : ", error)
    })
  }, [])

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div className='AboutUsPage'>
        <Navbar />
        
        <div className="AboutUsPage__inside">
          <div className="AboutUsPage__1 ">
            <div className="AboutUsPage__1__filter"></div>
            <div className="kosovaeducation"></div>
            <h1>
            About us
            </h1>
            <p>
            The hope for a better education in Kosovo.
            </p>
          </div>

          <div className="AboutUsPage__2">
            <div className="left">
              <h1 className="aboutTitle">
              Who is KosovaEducation?
              </h1>

              <p className="aboutText">
              Welcome to KosovaEducation, a beacon of hope and progress in the educational landscape of Kosovo. Our journey began with a simple yet powerful vision: to revolutionize the realm of education and make learning accessible to every individual in our community. At KosovaEducation, we are dedicated to reimagining the landscape of education in Kosovo. Our organization stands as a pioneering force in educational advocacy, development, and resource provision.
              </p>
            </div>

            <div className="right">
              {/* <div className="circle1"></div>
              <div className="circle2"></div>
              <div className="circle3"></div>
              <div className="circle4"></div> */}
              <div className="right__left">
                <div className="circle1"></div>
              </div>
              <div className="right__right">
                <div className="circle2"></div>
                <div className="circle3"></div>
                <div className="circle4"></div>
              </div>
            </div>
          </div>

          <div className="AboutUsPage__3">
            <div className="left">
              <div className="image1"></div>
              <div className="onlyCircle"></div>
            </div>

            <div className="right">
              <h1 className="aboutTitle">
              Our mission
              </h1>
              <p className="aboutText">
              At the heart of KosovaEducation lies our unwavering commitment to enhancing the quality and accessibility of education in Kosovo. We believe that education is not just a right but a powerful tool for personal and societal transformation. Our mission is to advocate for educational reforms, provide professional development opportunities, and ensure that every learner, educator, and institution in Kosovo can connect and grow together.
              </p>
            </div>
          </div>

          <div className="AboutUsPage__4">
          </div>

          <div className="AboutUsPage__5">
            <div className="left">
              <h1 className="aboutTitle">
                What we do
              </h1>
              <p className="aboutText">
                We work tirelessly to enhance the quality and accessibility of education across the nation, focusing on transformative leadership, innovative learning methods, and comprehensive professional development for educators. Through our strategic initiatives and advisory services, we support schools and educational leaders in creating vibrant, inclusive, and forward-thinking learning environments. 
              </p>
            </div>

            <div className="right">
              <p className="aboutText">
              At KosovaEducation, our goal is to empower every individual in Kosovo with the knowledge, skills, and opportunities to thrive in a rapidly evolving world.
              </p>

              <Link to="/praticearea">
              Practice Areas
              </Link>
            </div>
          </div>


          <div className="AboutUsPage__6">
            <div className="left">
              <div className="image1"></div>
              <div className="onlyCircle"></div>
            </div>

            <div className="right">
              <h1 className="aboutTitle">
              Our story
              </h1>
              <p className="aboutText">
              KosovaEducation was founded by a group of passionate educators and advocates who recognized the need for a transformative approach to education in Kosovo. Starting with small community workshops, we have grown into a leading NGO that not only advocates for better education policies but also provides a unique platform connecting educational institutions, academies, courses, and teachers across Kosovo. Our website serves as a comprehensive resource, making information about education easily accessible to all.
              </p>
            </div>
          </div>

          <div className="AboutUsPage__7">
            <div className="numbers">
              <h1 className="aboutNumber">
                74
              </h1>
              <p className='aboutNumberSmall'>
              Active Projects
              </p>
            </div>

            <div className="numbers">
              <h1 className="aboutNumber">
              381
              </h1>
              <p className='aboutNumberSmall'>
              Volunteers
              </p>
            </div>

            <div className="numbers">
              <h1 className="aboutNumber">
              40
              </h1>
              <p className='aboutNumberSmall'>
              Team members
              </p>
            </div>

            <div className="numbers">
              <h1 className="aboutNumber">
                632
              </h1>
              <p className='aboutNumberSmall'>
              Institutions registered
              </p>
            </div>

            <div className="numbers">
              <h1 className="aboutNumber">
                583
              </h1>
              <p className='aboutNumberSmall'>
              Courses & Trainings
              </p>
            </div>
          </div>

          <div className="AboutUsPage__8">
              <h1 className="aboutTitle">
                Join our journey
              </h1>
              <p className="aboutText">
              As we continue to strive towards a brighter educational future, we invite you to join us in this noble endeavor. Whether you are an educator, a student, a parent, or simply someone who believes in the power of education, your support can make a difference.
              </p>
          </div>

          <div className="AboutUsPage__9">
            <div className="page7__cards" >
            {
              pageData.map((item) => (
                <Page7Card 
                  key={item.id}
                  bgImage={item.bgImage}
                  title={
                    <FormattedMessage id={`page7Title__${item.id}`} defaultMessage={item.title} />
                  }
                  description={
                    <FormattedMessage id={`page7Description__${item.id}`} defaultMessage={item.description} />
                  }
                  buttonText={
                    <FormattedMessage id={`page7ButtonText__${item.id}`} defaultMessage={item.buttonText} />
                  }
                />
              ))
            }
          </div>
          </div>
          



        </div>

        <Footer />
    </div>
  )
}

export default AboutUsPage