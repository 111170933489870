import React from 'react'
import './TeamCard.scss'

const TeamCard = ({ bgImage ,name, profession2 }) => {

    const bgImageDiv = {
        backgroundImage: `url(${bgImage})`
    }

  return (
    <div className='TeamCard'>
        <div className="teamCard__top" style={bgImageDiv}></div>

        <div className="teamCard__bottom">
            <h3>
            {name}
            </h3>
            <p>
            {profession2}
            </p>
        </div>

    </div>
  )
}

export default TeamCard