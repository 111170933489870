import React, { useEffect, useState } from "react";
import "./BottomEducation.scss";
import { IoIosArrowDown, IoIosSearch } from "react-icons/io";
import { BiMenuAltLeft } from "react-icons/bi";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { FaList } from "react-icons/fa";
import axios from "axios";
import Page4Card from "../../Home/Page4/Page4Card/Page4Card";
import { MdClear, MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { IoIosArrowDropdown } from "react-icons/io";

const BottomEducation = () => {
  
    const[openCategories, setOpenCategories] = useState(true)
    const[openAge, setOpenAge] = useState(true)
    const[openLocation, setOpenLocation] = useState(true)
    const[openReviews, setOpenReviews] = useState(true)
    const[openFilter, setOpenFilter] = useState(false)

    const [pageData, setPageData] = useState([]);
    const [originalPageData, setOriginalPageData] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [inGrid, inLine] = useState(false);
    const [perPageNumber, setPerPageNumber] = useState(6);

    const [selectedCategory, setSelectedCategory] = useState(["All"]);
    const [selectAge, setSelectAge] = useState([]);
    const [selectStar, setSelectStar] = useState([]);
    const [sortOrder, setSortOrder] = useState("ASC");

    const [name, setName] = useState("")

  const data = [
    "Deçan",
    "Dragash",
    "Drenas",
    "Ferizaj",
    "Fushë Kosovë",
    "Gjakovë",
    "Gjilan",
    "Istog",
    "Kaçanik",
    "Kamenicë",
    "Klinë",
    "Lipjan",
    "Malishevë",
    "Mitrovicë",
    "Obiliq",
    "Pejë",
    "Podujevë",
    "Prishtinë",
    "Prizren",
    "Rahovec",
    "Skenderaj",
    "Suharekë",
    "Shtërpcë",
    "Shtime",
    "Viti",
    "Vushtrri",
  ];

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ROUTE}BottomEducation/BottomEducation.json`)
      .then((res) => {
        setPageData(res.data);
        setOriginalPageData(res.data);

        const allNames = res.data.map((item) => item.name);
        setName(allNames);
  
      })
      .catch((error) => {
        console.error("BottomEducation : ", error);
      });
  }, []);



  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const cityParam = urlParams.get('location');
    const categoryParam = urlParams.get('category');
    const nameParam = urlParams.get('name');
  
    if (cityParam) {
      handleCitySelect(cityParam);
    }
  
    if (categoryParam) {
      handleCategorySelect(categoryParam);
    }

    if(nameParam) {
      handleSearchTerm(nameParam)
    }
  }, []);
  

  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchTerm = (searchTerm) => {
    setSearchTerm(searchTerm);
    const filteredDataBySearch = originalPageData.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    setPageData(filteredDataBySearch);
  };

  const handleGrid = () => {
    inLine(!inGrid);
  };


  const handleSort = (property) => {
    const sortedData = [...filteredData].sort((a, b) => {
      if (sortOrder === "ASC") {
        return a[property] > b[property] ? 1 : -1;
      } else {
        return a[property] < b[property] ? 1 : -1;
      }
    });

    setPageData(sortedData);
    setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
  };


  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const [selectedCity, setSelectedCity] = useState(null);

  const handleCitySelect = (city) => {
    const updatedSelectedCities = selectedCities.includes(city)
      ? selectedCities.filter((selectedCity) => selectedCity !== city)
      : [...selectedCities, city];
    setSelectedCities(updatedSelectedCities);
  };

  const handleAgeSelect = (age) => {
    const ageFilterMap = {
      "0-1": { yoStart: 0, yoEnd: 1 },
      "1-5": { yoStart: 1, yoEnd: 5 },
      "6-15": { yoStart: 6, yoEnd: 15 },
      "15-18": { yoStart: 15, yoEnd: 18 },
      "18+": { yoStart: 18, yoEnd: Infinity },
    };

    const newSelectAge = selectAge.includes(age)
      ? selectAge.filter((selectedAge) => selectedAge !== age)
      : [...selectAge, age];

    const filteredDataByAge = newSelectAge.length
      ? originalPageData.filter((item) =>
          newSelectAge.some(
            (selectedAge) =>
              item.yoStart <= ageFilterMap[selectedAge].yoEnd &&
              item.yoEnd >= ageFilterMap[selectedAge].yoStart
          )
        )
      : originalPageData;

    setSelectAge(newSelectAge);
    setPageData(filteredDataByAge);
  };

    //Star   
  const handleStarSelect = (star2) => {
    const starFilterMap = {
      "5Star": { star: 5 },
      "4Star": { star: 4 },
      "3Star": { star: 3 },
      "2Star": { star: 2 },
      "1Star": { star: 1 },
    };

    const newSelectStar = selectStar.includes(star2)
      ? selectStar.filter((selectedStar) => selectedStar !== star2)
      : [...selectStar, star2];

    const filteredDataByStar = newSelectStar.length
      ? originalPageData.filter((item) =>
          newSelectStar.some(
            (selectedStar) => item.star === starFilterMap[selectedStar].star
          )
        )
      : originalPageData;

    setSelectStar(newSelectStar);
    setPageData(filteredDataByStar);
  };
    //END Star   


    const filteredData = pageData.filter(
      (item) =>
      (selectedCategory.includes("All") ||
        item.category === selectedCategory) &&
      (selectedCities.length === 0 || selectedCities.includes(item.city)) 
    );
  

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = perPageNumber;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = pageData.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filteredData.length / recordsPerPage);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const numbersToShow = 3;
  const totalPages = npage;

  const getPageNumbers = () => {
    const midNumber = Math.floor(numbersToShow / 2);
    let startPage = Math.max(1, currentPage - midNumber);
    let endPage = Math.min(startPage + numbersToShow - 1, totalPages);

    if (endPage - startPage + 1 < numbersToShow) {
      startPage = Math.max(1, endPage - numbersToShow + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };
  // End Pagination


  const filteredAndPaginatedData = filteredData.slice(
    (currentPage - 1) * perPageNumber,
    currentPage * perPageNumber
  );

  return (
    <div className="BottomEducation">
        <div className="forMobile" onClick={() => setOpenFilter(!openFilter)}>
            <p>
                Filters 
                <IoIosArrowDropdown className={`${openFilter ? "" : "rotateSvg"}`} />
            </p>
        </div>

      <div className={`bottomEducation__left ${openFilter ? "openFilter" : "closeFilter"}`}>

        <div className="bottomEducation__left__options bottomEducation__left__1">
          <h2 onClick={() => setOpenCategories(!openCategories)}>
            <FormattedMessage
              id="BottomEducation__1"
              defaultMessage={"Categories"}
            />
          </h2>

          <ul className={`mobileEducation ${openCategories ? "hide" : "show"}`}> 
            <li>
              <input
                type="checkbox"
                id="All-institutions"
                checked={selectedCategory.includes("All")}
                onChange={() => handleCategorySelect("All")}
              />
              <label htmlFor="All-institutions">
                <span className="bottomIcon bottom__icon1"></span>
                <FormattedMessage
                  id="BottomEducation__2"
                  defaultMessage={"All institutions"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Academies"
                checked={selectedCategory.includes("Academies")}
                onChange={() => handleCategorySelect("Academies")}
              />
              <label htmlFor="Academies">
                <span className="bottomIcon bottom__icon2"></span>
                <FormattedMessage
                  id="BottomEducation__3"
                  defaultMessage={"Academies"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Pre-schools"
                checked={selectedCategory.includes("Pre-schools")}
                onChange={() => handleCategorySelect("Pre-schools")}
              />
              <label htmlFor="Pre-schools">
                <span className="bottomIcon bottom__icon3"></span>
                <FormattedMessage
                  id="BottomEducation__4"
                  defaultMessage={"Pre-schools"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Elementary-Schools"
                checked={selectedCategory.includes("Elementary Schools")}
                onChange={() => handleCategorySelect("Elementary Schools")}
              />
              <label htmlFor="Elementary-Schools">
                <span className="bottomIcon bottom__icon4"></span>
                <FormattedMessage
                  id="BottomEducation__5"
                  defaultMessage={"Elementary Schools"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="High-Schools"
                checked={selectedCategory.includes("High Schools")}
                onChange={() => handleCategorySelect("High Schools")}
              />
              <label htmlFor="High-Schools">
                <span className="bottomIcon bottom__icon5"></span>
                <FormattedMessage
                  id="BottomEducation__6"
                  defaultMessage={"High Schools"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Universities&Colleges"
                checked={selectedCategory.includes("Universities & Colleges")}
                onChange={() => handleCategorySelect("Universities & Colleges")}
              />
              <label htmlFor="Universities&Colleges">
                <span className="bottomIcon bottom__icon6"></span>
                <FormattedMessage
                  id="BottomEducation__7"
                  defaultMessage={"Universities & Colleges"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Private-Institutions"
                checked={selectedCategory.includes("Private Institutions")}
                onChange={() => handleCategorySelect("Private Institutions")}
              />
              <label htmlFor="Private-Institutions">
                <span className="bottomIcon bottom__icon7"></span>
                <FormattedMessage
                  id="BottomEducation__8"
                  defaultMessage={"Private Institutions"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Daycare"
                checked={selectedCategory.includes("Daycare")}
                onChange={() => handleCategorySelect("Daycare")}
              />
              <label htmlFor="Daycare">
                <span className="bottomIcon bottom__icon8"></span>
                <FormattedMessage
                  id="BottomEducation__9"
                  defaultMessage={"Daycare"}
                />
              </label>
            </li>
          </ul>
        </div>

        <div className="bottomEducation__left__options bottomEducation__left__2">
          <h2 onClick={() => setOpenAge(!openAge)}>
            <FormattedMessage id="BottomEducation__10" defaultMessage={"Age"} />
          </h2>

          <ul className={`mobileEducation ${openAge ? "hide" : "show"}`}>
            <li>
              <input
                type="checkbox"
                id="0-1"
                checked={selectAge.includes("0-1")}
                onChange={() => handleAgeSelect("0-1")}
              />
              <label htmlFor="0-1">
                <FormattedMessage
                  id="BottomEducation__11"
                  defaultMessage={"0-1 years old"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="1-5"
                checked={selectAge.includes("1-5")}
                onChange={() => handleAgeSelect("1-5")}
              />
              <label htmlFor="1-5">
                <FormattedMessage
                  id="BottomEducation__12"
                  defaultMessage={"1-5 years old"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="6-15"
                checked={selectAge.includes("6-15")}
                onChange={() => handleAgeSelect("6-15")}
              />
              <label htmlFor="6-15">
                <FormattedMessage
                  id="BottomEducation__13"
                  defaultMessage={"5-15 years old"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="15-18"
                checked={selectAge.includes("15-18")}
                onChange={() => handleAgeSelect("15-18")}
              />
              <label htmlFor="15-18">
                <FormattedMessage
                  id="BottomEducation__14"
                  defaultMessage={"15-18 years old"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="18+"
                checked={selectAge.includes("18+")}
                onChange={() => handleAgeSelect("18+")}
              />
              <label htmlFor="18+">
                <FormattedMessage
                  id="BottomEducation__15"
                  defaultMessage={"18+ years old"}
                />
              </label>
            </li>
          </ul>
        </div>

        <div className="bottomEducation__left__options bottomEducation__left__3">
          <h2 onClick={() => setOpenLocation(!openLocation)}>
            <FormattedMessage
              id="BottomEducation__16"
              defaultMessage={"location"}
            />
          </h2>

          <ul className={`mobileEducation ${openLocation ? "hide" : "show"}`}>
            {data.slice(0, 9).map((city, index) => (
              <li key={index}>
                <input
                  type="checkbox"
                  id={city}
                  checked={selectedCities.includes(city)}
                  onChange={() => handleCitySelect(city)}
                />
                <label htmlFor={city}>{city}</label>
              </li>
            ))}

            <div className="bottom__ul">
              <p >
                <FormattedMessage
                  id="BottomEducation__111111"
                  defaultMessage={`${
                    selectedCity !== null ? selectedCity : "Show More"
                  }`}
                />
                <IoIosArrowDown />
              </p>
              <ul className="onlyThisUl">
                {data.slice(9, data.length).map((city, index) => (
                  <li key={index}>
                    <input
                      type="checkbox"
                      id={city}
                      checked={selectedCities.includes(city)}
                      onChange={() => handleCitySelect(city)}
                    />
                    <label htmlFor={city}>{city}</label>
                  </li>
                ))}
              </ul>
            </div>
          </ul>
        </div>

        <div className="bottomEducation__left__options bottomEducation__left__4">
          <h2 onClick={() => setOpenReviews(!openReviews)}>
            <FormattedMessage
              id="BottomEducation__18"
              defaultMessage={"reviews"}
            />
          </h2>

          <ul className={`mobileEducation ${openReviews ? "hide" : "show"}`}>
            <li>
              <input
                type="checkbox"
                id="5Star"
                checked={selectStar.includes("5Star")}
                onChange={() => handleStarSelect("5Star")}
              />
              <label htmlFor="5Star">
                5<span className="star"></span>
                <span className="star"></span>
                <span className="star"></span>
                <span className="star"></span>
                <span className="star"></span>
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="4Star"
                checked={selectStar.includes("4Star")}
                onChange={() => handleStarSelect("4Star")}
              />
              <label htmlFor="4Star">
                4<span className="star"></span>
                <span className="star"></span>
                <span className="star"></span>
                <span className="star"></span>
                <span className="grayStar"></span>
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="3Star"
                checked={selectStar.includes("3Star")}
                onChange={() => handleStarSelect("3Star")}
              />
              <label htmlFor="3Star">
                3<span className="star"></span>
                <span className="star"></span>
                <span className="star"></span>
                <span className="grayStar"></span>
                <span className="grayStar"></span>
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="2Star"
                checked={selectStar.includes("2Star")}
                onChange={() => handleStarSelect("2Star")}
              />
              <label htmlFor="2Star">
                2<span className="star"></span>
                <span className="star"></span>
                <span className="grayStar"></span>
                <span className="grayStar"></span>
                <span className="grayStar"></span>
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="1Star"
                checked={selectStar.includes("1Star")}
                onChange={() => handleStarSelect("1Star")}
              />
              <label htmlFor="1Star">
                1<span className="star"></span>
                <span className="grayStar"></span>
                <span className="grayStar"></span>
                <span className="grayStar"></span>
                <span className="grayStar"></span>
              </label>
            </li>
          </ul>
        </div>
      </div>

      <div className="bottomEducation__right">
        <div className="bottomEducation__right__top">
          <div className="left">
              <div className="exploreAndResults">
                <h1>
                  <FormattedMessage
                    id="BottomEducation__19"
                    defaultMessage={"Explore"}
                  />
                </h1>

                <p>
                  {
                    searchTerm
                    ?
                    (filteredAndPaginatedData.filter((item) => (
                      item.name.toLowerCase().includes(searchTerm.toLowerCase())
                    )).length)
                    :
                    filteredData.length

                  }
                  &nbsp;
                  <FormattedMessage
                    id="BottomEducation__20"
                    defaultMessage={" results"}
                  />
                </p>
              </div>

              <div className="addName">
                <input 
                  type="text"
                  placeholder="Search by name"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />

                  <div className="buttonIclearSearch">
                    {
                      searchTerm
                      ? 
                      <button onClick={() => setSearchTerm("")}>
                        <MdClear />
                      </button>
                      :
                      <button onClick={() => setSearchTerm("")}>
                        <IoIosSearch />
                      </button>
                    }
                  </div>

              </div>
          </div>

          <div className="right">
            <p>
              <FormattedMessage
                id="BottomEducation__21"
                defaultMessage={"view"}
              />
            </p>



            <p className="pBorder perPageMain">
              {perPageNumber}
              <FormattedMessage
                id="BottomEducation__22"
                defaultMessage={" per page "}
              />
              <IoIosArrowDown />

              <div className="perPage">
                <p onClick={() => setPerPageNumber(6)}>
                  6
                  <FormattedMessage
                    id="BottomEducation__22"
                    defaultMessage={" per page "}
                  />
                </p>

                <p onClick={() => setPerPageNumber(12)}>
                  12
                  <FormattedMessage
                    id="BottomEducation__22"
                    defaultMessage={" per page "}
                  />
                </p>

                <p onClick={() => setPerPageNumber(24)}>
                  24
                  <FormattedMessage
                    id="BottomEducation__22"
                    defaultMessage={" per page "}
                  />
                </p>
              </div>
            </p>

            <p className="pBorder perPageMain">
              <BiMenuAltLeft />
              <FormattedMessage
                id="BottomEducation__253"
                defaultMessage={sortOrder}
              />
              <IoIosArrowDown />

              <div className="perPage perPageSort">
                <p
                  className=" inRightpBorder insideP"
                  onClick={() => handleSort("name")}
                >
                  <span className={`${sortOrder ? "backColor" : ""}`}>
                    DESC
                  </span>
                  <span className={`${sortOrder ? "backColor" : ""}`}>ASC</span>
                </p>

                {/* <p className='pBorder inRightpBorder' onClick={() => handleSort('star')}>
                        {sortOrder === 'asc' ? 'High to Low Rating' : 'Low to High Rating'}
                        <IoIosArrowDown />
                        </p> */}
              </div>
            </p>

            <p className="pBorder inRightpBorder" onClick={handleGrid}>
              <BsFillGrid3X3GapFill className={`${inGrid ? "" : "orange"} `} />
              <span className="line"></span>
              <FaList className={`${inGrid ? "orange" : ""} `} />
            </p>
          </div>
        </div>

        {
        (filteredAndPaginatedData.filter((item) => (
          item.name.toLowerCase().includes(searchTerm.toLowerCase())
        )).length)
        ||
        filteredAndPaginatedData.length > 0
        ||
        filteredData.length > 0
        ? (
          <div className={`bottomEducation__right__mid ${inGrid ? "makeInLine" : ""}`}>
            {filteredAndPaginatedData.map((item) => (
              // Checking if the name includes the searchTerm before rendering
              item.name.toLowerCase().includes(searchTerm.toLowerCase()) && (
                <Link
                  to={{
                    pathname: `/education/${item.id}/${item.name.replace(/ /g, "_")}`,
                    state: { educationData: item },
                  }}
                  key={item.id}
                >
                  <Page4Card
                    key={item.id}
                    image={`${process.env.REACT_APP_BottomEducation_IMAGE}${item.image}`}
                    name={item.name}
                    yoEnd={item.yoEnd}
                    yoStart={item.yoStart}
                    category={item.category}
                    star={item.star}
                    city={item.city}
                  />
                </Link>
              )
            ))}
          </div>
        ) : (
          <p className="noResults">No Results found</p>
        )}


        {
          !filteredData.length
          ?
          filteredData.length
          :
          (filteredAndPaginatedData.filter((item) => (
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
          )).length)
        > 0 ? (
          <div className="bottomEducation__right__bottom">
            <ul className="bottom__numbers">
              <p onClick={prePage}>
                <MdKeyboardArrowLeft />
                <FormattedMessage
                  id="BottomEducation__24"
                  defaultMessage={"Previous"}
                />
              </p>

              {currentPage > numbersToShow && (
                <li>
                  <Link
                    to="#"
                    onClick={() => changeCPage(1)}
                    className="page-link"
                  >
                    1
                  </Link>
                </li>
              )}
              {currentPage > numbersToShow  && (
                <li>
                  <span>...</span>
                </li>
              )}

              {getPageNumbers().map((n) => (
                <li
                  key={n}
                  className={`page-item ${
                    currentPage === n ? "activeBottom" : ""
                  }`}
                >
                  <Link
                    to="#"
                    onClick={() => changeCPage(n)}
                    className="page-link"
                  >
                    {n}
                  </Link>
                </li>
              ))}

              {currentPage < totalPages - 2 && (
                <li>
                  <span>...</span>
                </li>
              )}
              {currentPage < totalPages - 2 && (
                <li>
                  <Link
                    to="#"
                    onClick={() => changeCPage(totalPages)}
                    className="page-link"
                  >
                    {totalPages}
                  </Link>
                </li>
              )}

              <p onClick={nextPage}>
                <FormattedMessage
                  id="BottomEducation__25"
                  defaultMessage={"Next"}
                />
                <MdKeyboardArrowRight />
              </p>
            </ul>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default BottomEducation;
