import React, { useEffect, useRef, useState } from "react";
import "./Page4.scss";
import { Link } from "react-router-dom";
import Page4Card from "./Page4Card/Page4Card";
import axios from "axios";
import Carousel from "react-elastic-carousel";
import { FormattedMessage } from "react-intl";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AOS from 'aos';
import "aos/dist/aos.css";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 1200, itemsToShow: 3 },
  { width: 1300, itemsToShow: 4 },
];

const Page4 = () => {
  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    AOS.init();
  }, [])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ROUTE}BottomEducation/BottomEducation.json`)
      .then((res) => {
        setPageData(res.data);
      })
      .catch((error) => {
        console.error("Page4 : ", error);
      });
  }, []);

  const carouselRef = useRef(null);
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo("single-slide".length);
    }
  };

  const [filteredData, setFilteredData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("Pre-schools");

  useEffect(() => {
    const filtered = pageData.filter(
      (item) => item.category === selectedCategory
    );
    setFilteredData(filtered);
  }, [selectedCategory, pageData]);

  const handleTabClick = (category) => {
    setSelectedCategory(category);
  };



  return (
    <div className="Page4">
      <div className="shared-our-work">
        <h1>
          <FormattedMessage
            id="page4__1"
            defaultMessage={"Educational institutions"}
          />
        </h1>

        <Tabs className="tabs">
          <TabList data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            <Tab onClick={() => handleTabClick("Pre-schools")}>
              <FormattedMessage id="page4__2" defaultMessage={"Pre-schools"} />
            </Tab>

            <Tab onClick={() => handleTabClick("Elementary Schools")}>
              <FormattedMessage
                id="page4__3"
                defaultMessage={"Elementary Schools"}
              />
            </Tab>

            <Tab onClick={() => handleTabClick("High Schools")}>
              <FormattedMessage id="page4__4" defaultMessage={"High Schools"} />
            </Tab>

            <Tab onClick={() => handleTabClick("Universities")}>
              <FormattedMessage id="page4__5" defaultMessage={"Universities"} />
            </Tab>

            <Tab onClick={() => handleTabClick("Academies")}>
              <FormattedMessage id="page4__6" defaultMessage={"Academies"} />
            </Tab>

            <Tab onClick={() => handleTabClick("Private")}>
              <FormattedMessage id="page4__7" defaultMessage={"Private"} />
            </Tab>
          </TabList>
          <div className="carouselPage4" data-aos="fade-up">
            <Carousel
              className="slider styled-arrows"
              breakPoints={breakPoints}
              ref={carouselRef}
              onPrevStart={onPrevStart}
              onNextStart={onNextStart}
              disableArrowsOnEnd={false}
            >
              {filteredData.map((item) => (
                <Link className="hoverA"
                  to={{ pathname: `/education/${item.id}/${item.name.replace(/ /g, "_")}`, state: { educationData: item } }} key={item.id}>
                <Page4Card
                  image={`${process.env.REACT_APP_BottomEducation_IMAGE}${item.image}`}
                  name={item.name}
                  yoEnd={item.yoEnd}
                  yoStart={item.yoStart}
                  category={item.category}
                  star={item.star}
                  city={item.city}
                />
              </Link>
              ))}
            </Carousel>
          </div>
        </Tabs>
      </div>

      <Link to="/education" className="page4Explore" data-aos="fade-down">
        <FormattedMessage id="page4__8" defaultMessage={"Explore all"} />
      </Link>
    </div>
  );
};

export default Page4;
