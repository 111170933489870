import './App.scss';
import './dark.scss'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { translate } from './translation/translate'
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import EducationPage from './pages/Education/EducationPage';
import ScrollToTop from './ScrollToTop';
import ActiveProjects from './pages/ActiveProjects/ActiveProjects';
import Featured from './pages/Featured/Featured';
import EducationProfile from './pages/EducationProfile/EducationProfile';

import BlogPage from './pages/BlogPage/BlogPage';
import BlogDetails from './pages/BlogDetails/BlogDetails';
import FeaturedDetails from './pages/FeaturedDetails/FeaturedDetails';
import AboutUsPage from './pages/AboutUsPage/AboutUsPage';
import PraticeAreas from './pages/PraticeAreas/PraticeAreas';

function App(props) {
  const language = useSelector((state) => state.language.language);
  return (
    <div className="App">
      <IntlProvider 
        locale={language}
        formats={{ number: 'en' }}
        messages={translate[language]}
      >
        <BrowserRouter >
          <ScrollToTop />
          <Routes>
            <Route path='/' element={<Home />}/>
            <Route path='/education' element={<EducationPage />}/>
            <Route path='/education/:id/:name' element={<EducationProfile />} />
            <Route path='/featured/:id/:title' element={<FeaturedDetails />} />
            {/* <Route path="/education/:city?/:category?" component={<EducationProfile />} /> */}
            <Route path="/education/:city?/:category?/:name" component={<EducationProfile />} />

            <Route path='/login' element={<Login />}/>
            <Route path='/register' element={<Register />}/>
            <Route path='/activeprojects' element={<ActiveProjects />}/>
            <Route path='/featured' element={<Featured />}/>
            <Route path='/blog' element={<BlogPage />}/>
            <Route path='/blog/:id/:title' element={<BlogDetails />} />
            <Route path='/aboutus' element={<AboutUsPage />}/>
            <Route path='/praticearea' element={<PraticeAreas />}/>

          </Routes>
        </BrowserRouter>
      </IntlProvider>

    </div>
  );
}

export default App;
