import axios from 'axios'
import React, { useEffect, useState } from 'react'
import MobileCard from '../MobileCard/MobileCard'
import './ResourcesMobile.scss'
import { FormattedMessage } from 'react-intl'

import icon1 from '../../../../assets/Resources/icon1.svg'
import icon2 from '../../../../assets/Resources/icon2.svg'
import icon3 from '../../../../assets/Resources/icon3.svg'
import icon4 from '../../../../assets/Resources/icon4.svg'
import icon5 from '../../../../assets/Resources/icon5.svg'
import icon6 from '../../../../assets/Resources/icon6.svg'
import icon7 from '../../../../assets/Resources/icon7.svg'
import icon8 from '../../../../assets/Resources/icon8.svg'

const leftData = [
    {
        id: 1,
        title: "Clubs",
        description: "Discover our clubs and communities",
        image: icon1,
    },
    {
        id: 2,
        title: "Careers",
        description: "Become a team member",
        image: icon2,
    },
    {
        id: 3,
        title: "Webinars",
        description: "Join our online webinars",
        image: icon3,
    },
    {
        id: 4,
        title: "Blog",
        description: "Read our latest articles",
        image: icon4,
    },
    {
        id: 5,
        title: "Events",
        description: "Reserve your spot",
        image: icon5,
    },
    {
        id: 6,
        title: "FAQs",
        description: "Get answers to all your questions",
        image: icon6,
    },
    {
        id: 7,
        title: "Partners",
        description: "Meet our partners",
        image: icon7,
    },
    {
        id: 8,
        title: "Guides",
        description: "Explore career guides",
        image: icon8,
    },
];

const ResourcesMobile = () => {
  return (
    <div className='ResourcesMobile'>
        {
            leftData.map((item) => (
                <div 
                    className='insideMobileMega'
                    key={item.id}
                >
                    <MobileCard 
                        bgImage={item.image}
                        titleMobile={
                            <FormattedMessage id={`resourcesTitle__${item.id}`} defaultMessage={item.title} />
                        }
                    />
                </div>
            ))
        }
    </div>
  )
}

export default ResourcesMobile
