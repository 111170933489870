import React, { useRef, useState } from 'react'
import './Page13.scss'
import Carousel from 'react-elastic-carousel'
import { FormattedMessage } from 'react-intl'
        
const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width:400, itemsToShow: 2 },
    { width:700, itemsToShow: 3 },
    { width:900, itemsToShow: 4 },
    { width:1100, itemsToShow: 5 },
  ];

const Page13 = () => {
    const carouselRef = useRef(null);
    const onNextStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
          carouselRef.current.goTo(0);
        }
    };
    const onPrevStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
          carouselRef.current.goTo('single-slide'.length);
        }
    };

  return (
    <div className='Page13'>
        
        <h1>
        <FormattedMessage id="page13__1" defaultMessage={"Partners"} />
        </h1>

        <div className="carouselPage8">
            <Carousel className="slider styled-arrows" 
                breakPoints={breakPoints}
                ref={carouselRef}
                onPrevStart={onPrevStart}
                onNextStart={onNextStart}
                disableArrowsOnEnd={false}
            >
                <div className="companyWidth company1"></div>
                <div className="companyWidth company2"></div>
                <div className="companyWidth company3"></div>
                <div className="companyWidth company4"></div>
                <div className="companyWidth company5"></div>
                <div className="companyWidth company1"></div>
                <div className="companyWidth company2"></div>
                <div className="companyWidth company3"></div>
                <div className="companyWidth company4"></div>
                <div className="companyWidth company5"></div>
                <div className="companyWidth company1"></div>
            </Carousel>
        </div> 

    </div>
  )
}

export default Page13