import React from 'react'
import './ServicesCard.scss'
import { HiOutlineUserGroup } from 'react-icons/hi2'

const ServicesCard = ({ bgImage, year, firstTitle, title, description }) => {

    const bgImageDiv = {
        backgroundImage: `url(${bgImage})`
    }

  return (
    <div className='ServicesCard'>

        <div className="servicesCard__top" style={bgImageDiv}>
        </div>

        <div className="servicesCard__bottom">
            {
                year 
                ?
                <span>
                    <HiOutlineUserGroup />
                    {year}
                    yo
                </span>
                :
                <h2>
                    {firstTitle}
                </h2>
            }

            <h2>
            {title}
            </h2>

            <p>
            {description}
            </p>

        </div>


    </div>
  )
}

export default ServicesCard