import React, { useEffect } from 'react'
import './Page11.scss'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import AOS from 'aos';
import "aos/dist/aos.css";


const Page11 = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className='Page11'>
        
        <h1 data-aos="fade-right">
          <FormattedMessage id="page11__1" defaultMessage={"Join your success now"} />
        </h1>

        <p data-aos="fade-left">
          <FormattedMessage id="page11__2" defaultMessage={"Our community members credit their success to our accessible platform."} />
        </p>


        <Link to="" className='page11Explore' data-aos="fade-left">
          <FormattedMessage id="page11__3" defaultMessage={"Get started"} />
        </Link>

        <div className="page11Bg" data-aos="fade-up">
            
        </div>

    </div>
  )
}

export default Page11