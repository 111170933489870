import React, { useEffect, useRef, useState } from 'react'
import './Page9.scss'
import Carousel from 'react-elastic-carousel'
import { Link } from 'react-router-dom';
import axios from 'axios';
import Page9Card from './Page9Card/Page9Card';
import { FormattedMessage } from 'react-intl'
import AOS from 'aos';
import "aos/dist/aos.css";


const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width:550, itemsToShow: 2 },
  { width:900, itemsToShow: 3 },
  { width:1200, itemsToShow: 4 }
];

const Page9 = () => {
    const [pageData, setPageData] = useState([])

    useEffect(() => {
      AOS.init();
    }, [])
  
    useEffect(() => {
      axios
      .get(`${process.env.REACT_APP_API_ROUTE}DonateByShopping/DonateByShopping.json`)
      .then(res => {
        setPageData(res.data)
      })
      .catch(error => {
          console.error("DonateByShopping : ", error)
      })
    }, [])
    
    const carouselRef = useRef(null);
    const onNextStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
          carouselRef.current.goTo(0);
        }
    };
    const onPrevStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
          carouselRef.current.goTo('single-slide'.length);
        }
    };

  return (
    <div className='Page9'>
        
        <h1 data-aos="fade-right">
          <FormattedMessage id="page9__1" defaultMessage={"Donate by shopping"} />
        </h1>

        <p data-aos="fade-left">
          <FormattedMessage id="page9__2" defaultMessage={"We will donate an amount of money to an NGO in Kosovo for each purchase you make"} />
        </p>

        <div className="page9__top">
            <div className="page9__top__filter"></div>
            <div className="page9__top__left">
                <h3>
                  <FormattedMessage id="page9__3" defaultMessage={"Feel cool while helping others"} data-aos="fade-left" />
                </h3>

                <p>
                  <FormattedMessage id="page9__4" defaultMessage={"Use our student discount."} data-aos="fade-right" />
                </p>
            </div>

            <div className="page9__top__right">
              {/* <div className="page9__top__right__1">
                <p>
                  SPECIAL
                  <span>
                    offer
                  </span>
                </p>
              </div>
              <div className="page9__top__right__2">
                BIG SALE
              </div>
              <div className="page9__top__right__3">
                UP TO <span>50%</span> OFF
              </div> */}
            
            </div>
        </div>

        <div className="carouselPage9" data-aos="fade-up">
            <Carousel className="slider styled-arrows" 
                breakPoints={breakPoints}
                ref={carouselRef}
                onPrevStart={onPrevStart}
                onNextStart={onNextStart}
                disableArrowsOnEnd={false}
            >
                {
                  pageData.map((item) => (
                    <Page9Card 
                      key={item.id}
                      bgImage={`${process.env.REACT_APP_DonateByShopping_IMAGE}${item.bgImage}`}
                      bgColor={item.bgColor}
                      circle1={item.circle1}
                      circle2={item.circle2}
                      circle3={item.circle3}
                      title={
                        <FormattedMessage id={`page9Title__${item.id}`} defaultMessage={item.title} />
                      }
                      price={item.price}
                      donationPage9={item.donationPage9}
                    />
                  ))
                }

            </Carousel>
        </div>
        
        <Link to="" className='page9Explore'>
          <FormattedMessage id="page9__5" defaultMessage={"Explore Shop"} />
        </Link>
    </div>
  )
}

export default Page9