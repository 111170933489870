import React from 'react'
import './Education.scss'
import NavbarButtons from '../../NavbarButtons/NavbarButtons'
import NavbarCard from '../../NavbarCard/NavbarCard'
import { FormattedMessage } from 'react-intl'

import icon1 from '../../../../assets/EducationNav/icon1.svg'
import icon2 from '../../../../assets/EducationNav/icon2.svg'
import icon3 from '../../../../assets/EducationNav/icon3.svg'
import icon4 from '../../../../assets/EducationNav/icon4.svg'
import icon5 from '../../../../assets/EducationNav/icon5.svg'
import icon6 from '../../../../assets/EducationNav/icon6.svg'
import icon7 from '../../../../assets/EducationNav/icon7.svg'
import icon8 from '../../../../assets/EducationNav/icon8.svg'
import image1 from '../../../../assets/EducationNav/img1.webp'

const leftData = [
    {
        id: 1,
        title: "Educational Institutions",
        description: "Explore all institutions in Kosovo",
        image: icon1,
    },
    {
        id: 2,
        title: "Advocating",
        description: "An enhanced and innovative education",
        image: icon2,
    },
    {
        id: 3,
        title: "Courses",
        description: "Search for professional courses",
        image: icon3,
    },
    {
        id: 4,
        title: "Mobile App",
        description: "Download our learning mobile app",
        image: icon4,
    },
    {
        id: 5,
        title: "Teachers",
        description: "Find out more about teachers",
        image: icon5,
    },
    {
        id: 6,
        title: "Library",
        description: "Discover more",
        image: icon6,
    },
    {
        id: 7,
        title: "Learning Center",
        description: "Learn new skills every day",
        image: icon7,
    },
    {
        id: 8,
        title: "Research",
        description: "Exploring new ideas",
        image: icon8,
    },
];

const rightData = [
    {
        id__right: 1,
        title__right: "KosovaEducation Platform",
        description__right: "Explore all the features",
        image__right: image1
    }
]


const Education = () => {
  return (
    <div className='Education'>

        <h3>
            <FormattedMessage id='education__1__1' defaultMessage={"education"} />
        </h3>

        <div className="insideAbout">
            <div className="aboutUs__left">
                <div className="inside__about">
                    {
                        leftData.map((item) => (
                            <NavbarButtons 
                                key={item.id}
                                bgImage={item.image}
                                title={
                                    <FormattedMessage id={`educationTitle__${item.id}`} defaultMessage={item.title} />
                                }
                                description={
                                    <FormattedMessage id={`educationDescription__${item.id}`} defaultMessage={item.description} />
                                }
                            />
                        ))
                    }
                </div>
            </div>

            <div className="aboutUs__right">
                {
                    rightData.map((item) => (
                        <NavbarCard 
                            key={item.id__right}
                            bgImage={item.image__right}
                            description={
                                <FormattedMessage id={`educationDescriptionRight__${item.id__right}`} defaultMessage={item.description__right} />
                            }
                            title={item.title__right}
                        />
                    ))
                }
            </div>
        </div>
    </div>
  )
}

export default Education