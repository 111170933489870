import React, { useEffect, useState } from 'react'
import './Page7.scss'
import Page7Card from './Page7Card/Page7Card'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import AOS from 'aos';
import "aos/dist/aos.css";

const Page7 = () => {
  const [pageData, setPageData] = useState([])

  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_API_ROUTE}HelpUsDoMore/HelpUsDoMore.json`)
    .then(res => {
      setPageData(res.data)
    })
    .catch(error => {
        console.error("HelpUsDoMore : ", error)
    })
  }, [])

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div className='Page7'>
        <div className="page7__filter"></div>
        <h1 data-aos="fade-right">
          <FormattedMessage id="page7__1" defaultMessage={"Help us do more"} />
        </h1>

        <div className="page7__cards" data-aos="fade-down">
          {
            pageData.map((item) => (
              <Page7Card 
                key={item.id}
                bgImage={`${process.env.REACT_APP_HelpUsDoMore_IMAGE}${item.bgImage}`}
                title={
                  <FormattedMessage id={`page7Title__${item.id}`} defaultMessage={item.title} />
                }
                description={
                  <FormattedMessage id={`page7Description__${item.id}`} defaultMessage={item.description} />
                }
                buttonText={
                  <FormattedMessage id={`page7ButtonText__${item.id}`} defaultMessage={item.buttonText} />
                }
              />
            ))
          }
        </div>

    </div>
  )
}

export default Page7