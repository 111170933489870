import React, { useEffect } from 'react'
import './Page3.scss'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import AOS from 'aos';
import "aos/dist/aos.css";


const Page3 = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className='Page3'>
        
        <div className="page3__top">
            <p data-aos="fade-right">
            <FormattedMessage id='page3__1' defaultMessage={"For the past 5 years we have been working on changing children's lives for better, paying particular attention to the most vulnerable children living in challenging circumstances."}/>
            </p>

            <div className="page3Circle"></div>
        </div>

        <div className="page3__bottom">
            <div className="page3__bottom__left">
              <div className="page3__bottom__left__circle1"></div>
              <div className="page3__bottom__left__circle2"></div>

            </div>

            <div className="page3__bottom__right">
                <p data-aos="fade-left">
                  <FormattedMessage id='page3__2' defaultMessage={"We are a progressive organization dedicated to breaking the cycle of poverty through education, healthcare, nutrition, and social services. Our mission is to increase accessibility for unconventional communities through education, outreach and awesome displays of tech teamwork. "}/>
                </p>

                <Link to="" data-aos="fade-up">
                  <FormattedMessage id='page3__3' defaultMessage={"Our Story"}/>
                </Link>
            </div>


        </div>


    </div>
  )
}

export default Page3