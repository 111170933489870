import axios from 'axios'
import React, { useEffect, useState } from 'react'
import MobileCard from '../MobileCard/MobileCard'
import './WhatWeDoMobile.scss'
import { FormattedMessage } from 'react-intl'


import icon1 from '../../../../assets/WhatWeDo/icon1.svg'
import icon2 from '../../../../assets/WhatWeDo/icon2.svg'
import icon3 from '../../../../assets/WhatWeDo/icon3.svg'
import icon4 from '../../../../assets/WhatWeDo/icon4.svg'
import icon5 from '../../../../assets/WhatWeDo/icon5.svg'
import icon6 from '../../../../assets/WhatWeDo/icon6.svg'
import icon7 from '../../../../assets/WhatWeDo/icon7.svg'
import icon8 from '../../../../assets/WhatWeDo/icon8.svg'

const whatWeDoData = [
    {
        id: 1,
        title: "Our Work",
        description: "Explore what we do",
        image: icon1,
    },
    {
        id: 2,
        title: "Children Support",
        description: "Learn more about children support",
        image: icon2,
    },
    {
        id: 3,
        title: "Our Approach",
        description: "For a better education",
        image: icon3,
    },
    {
        id: 4,
        title: "Active Projects",
        description: "Our latest projects and campaigns",
        image: icon4,
    },
    {
        id: 5,
        title: "Stories",
        description: "Check out successful stories",
        image: icon5,
    },
    {
        id: 6,
        title: "Learning Materials",
        description: "Explore our free learning materials",
        image: icon6,
    },
    {
        id: 7,
        title: "Innovation & Technology",
        description: "Developing new systems",
        image: icon7,
    },
    {
        id: 8,
        title: "Youth Empowerment",
        description: "We care for children's future",
        image: icon8,
    },
];



const WhatWeDoMobile = () => {
  return (
    <div className='WhatWeDoMobile'>
        {
            whatWeDoData.map((item) => (
                <div 
                    className='insideMobileMega'
                    key={item.id}
                >
                    <MobileCard 
                        bgImage={item.image}
                        titleMobile={
                            <FormattedMessage id={`whatWeDoTitle__${item.id}`} defaultMessage={item.title} />
                        }
                    />
                </div>
            ))
        }
    </div>
  )
}

export default WhatWeDoMobile