import React, { useEffect, useState } from 'react'
import './TopEducation.scss'
import { FormattedMessage } from 'react-intl'
import axios from 'axios'
import { Link } from 'react-router-dom'

const TopEducation = (props) => {
  const [pageData, setPageData] = useState([])
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    axios
      .get("../../data/BottomEducation/BottomEducation.json")
      .then((res) => {
          setPageData(res.data);
      })
      .catch((error) => {
          console.error("Page4 : ", error);
    });
  }, []);


  const filteredData = pageData.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className='TopEducation'>
        <div className="TopEducationFilter"></div>

        <div className="insideTopEducation">
            <div className="topEducation__line"></div>

            <h1>
            <FormattedMessage id='TopEducation__1' defaultMessage={"Educational institutions"} />
            </h1>

            <p>
            <FormattedMessage id='TopEducation__1' defaultMessage={"Explore all institutions in one place"} />
            </p>

            <div className="topEducation__search">
                <div className="insideTopEducation__filter"></div>
                <span className='search'></span>
                <input 
                  type="search" 
                  placeholder='Search by keyword'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}  
                />
                <span className="icon2Filter"></span>

  
            </div>


            
          {searchTerm && (
            <div className="filteredData">
              {filteredData.map((item) => (
                <Link
                  key={item.id}
                  to={{ pathname: `/education/${item.id}/${item.name.replace(/ /g, "_")}`, state: { educationData: item } }}
                >
                  <h2>{item.name}</h2>
                </Link>
              ))}
            </div>
          )}

      </div>
    </div>
  )
}

export default TopEducation