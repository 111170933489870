import React from "react";
import "./Settings.scss";
import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";
import ColorSwitch from "../Footer/ColorSwitch";
import { IoSettingsSharp } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";

const Settings = () => {
  return (
    <div className="Settings">
      <h1>
        <IoSettingsSharp className="settingsSvg" />
        <IoIosArrowDown className="arrowSvg" />
      </h1>

      <div className="openSettings">
        <h2>Mode</h2>

        <div className="forThemeSwithcher">
          <p>Light mode</p>
          <ThemeSwitcher />
          <p>Dark mode</p>
        </div>

        <div className="colorSwitcher">
          <h2>THEME</h2>
          <ColorSwitch />
        </div>
      </div>
    </div>
  );
};

export default Settings;
