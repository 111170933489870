import React from "react";
import "./ActiveProjects.scss";
import TopActiveProjects from "../../components/ActiveProejcts/TopActiveProjects/TopActiveProjects";
import BottomActiveProejcts from "../../components/ActiveProejcts/BottomActiveProejcts/BottomActiveProejcts";
import Navbar from "../../components/shared/Navbar/Navbar";
import Footer from "../../components/shared/Footer/Footer";
import Page13 from "../../components/Home/Page13/Page13";

const ActiveProjects = () => {
  return (
    <div className="ActiveProjects">
      <Navbar />

      <div className="inside__EducationInstitution">
        <TopActiveProjects />
        <BottomActiveProejcts />
      </div>

      <Page13 />
      <Footer />
    </div>
  );
};

export default ActiveProjects;
