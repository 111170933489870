import React from 'react'
import './TopFeatured.scss'
import { FormattedMessage } from 'react-intl'

const TopFeatured = () => {
  return (
    <div className='TopFeatured'>
        <div className="TopFeaturedFilter"></div>

        <div className="insideTopFeatured">
            <div className="topFeatured__line"></div>

            <h1>
            <FormattedMessage id='TopFeatured__1__3' defaultMessage={"Courses & Trainings"} />
            </h1>

            <p>
            <FormattedMessage id='TopFeatured__1__4' defaultMessage={"Explore courses offered by many institutions"} />
            </p>

            <div className="topFeatured__search">
                <div className="insideTopFeatured__filter"></div>
                <span className='search'></span>
                <input type="search" placeholder='Search by keyword' />
                <span className="icon2Filter"></span>
            </div>
        </div>
    </div>
  )
}

export default TopFeatured