import React from 'react'
import './BenefitsCard.scss'

const BenefitsCard = ({ bgImage, title, description }) => {
    const bgImageDiv = {
        content: `url(${bgImage})`
    }
  return (
    <div className='BenefitsCard'>

        <div className="benefitsIcon" style={bgImageDiv}>

        </div>

        <div className='divInsideBenefits'>
          <h2>
          {title}
          </h2>

          <p>
          {description}
          </p>
        </div>

    </div>
  )
}

export default BenefitsCard