import React from 'react'
import './Page10Card.scss'
import { IoMdQuote } from "react-icons/io";

const Page10Card = ({ bgImage, description, name, who }) => {

    const bgImageDiv = {
        backgroundImage: `url(${bgImage})`
    }

  return (
    <div className='Page10Card'>
        <div className="page10Card__left" style={bgImageDiv}></div>

        <div className="page10Card__right">
            <div className="page10Card__quotes">
                <IoMdQuote />
            </div>

            <p>
            {description}
            </p>

            <div className="who">
                <h4>
                {name}
                </h4>

                <span>
                {who}
                </span>
            </div>

        </div>

    </div>
  )
}

export default Page10Card