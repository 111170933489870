import React, { useEffect, useState } from "react";
import "./BottomFeaturedPage.scss";
import { IoIosArrowDown, IoIosArrowDropdown } from "react-icons/io";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { FaList } from "react-icons/fa";
import axios from "axios";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { IoMdCloseCircleOutline } from "react-icons/io";
import FeaturedCard from "../FeaturedCard/FeaturedCard";
import { BiMenuAltLeft } from "react-icons/bi";
import Page4Card from "../../Home/Page4/Page4Card/Page4Card";
import Page6Card from "../../Home/Page6/Page6Card/Page6Card";

const BottomFeaturedPage = () => {
    
  const[openCategories, setOpenCategories] = useState(true)
  const[openAge, setOpenAge] = useState(true)
  const[openInstitutions, setOpenInstitutions] = useState(true)
  const[openLevel, setOpenLevel] = useState(true)
  const[openDuration, setOpenDuration] = useState(true)

  const[openFollow, setOpenFollow] = useState(true)
  const[openReviews, setOpenReviews] = useState(true)
  const[openFilter, setOpenFilter] = useState(false)

  const [pageData, setPageData] = useState([]);
  const [originalPageData, setOriginalPageData] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [inGrid, inLine] = useState(false);
  const [perPageNumber, setPerPageNumber] = useState(6);


  const [selectInstitutions, setSelectInstitutions] = useState([]);
  const [sortOrder, setSortOrder] = useState("ASC");

const data = [
  "Deçan",
  "Dragash",
  "Drenas",
  "Ferizaj",
  "Fushë Kosovë",
  "Gjakovë",
  "Gjilan",
  "Istog",
  "Kaçanik",
  "Kamenicë",
  "Klinë",
  "Lipjan",
  "Malishevë",
  "Mitrovicë",
  "Obiliq",
  "Pejë",
  "Podujevë",
  "Prishtinë",
  "Prizren",
  "Rahovec",
  "Skenderaj",
  "Suharekë",
  "Shtërpcë",
  "Shtime",
  "Viti",
  "Vushtrri",
];

useEffect(() => {
  axios
    .get(`${process.env.REACT_APP_API_ROUTE}FeaturedPage/FeaturedPage.json`)
    .then((res) => {
      setPageData(res.data);
      setOriginalPageData(res.data);
    })
    .catch((error) => {
      console.error("FeaturedPage : ", error);
    });
}, []);



const handleGrid = () => {
  inLine(!inGrid);
};


const handleSort = (property) => {
  const sortedData = [...filteredData].sort((a, b) => {
    if (sortOrder === "ASC") {
      return a[property] > b[property] ? 1 : -1;
    } else {
      return a[property] < b[property] ? 1 : -1;
    }
  });

  setPageData(sortedData);
  setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
};

  const [selectedCategories, setSelectedCategories] = useState(['All'])
  const handleCategorySelect = (categories) => {
    setSelectedCategories(categories);
  };


const [selectedAge, setSelectedAge] = useState([])
const handleAgeSelect = (age) => {
  if(age) {
    const updatedSelectedLocation = selectedAge.includes(age)
    ? selectedAge.filter((selectedAge) => selectedAge !== age)
    : [...selectedAge, age];
    setSelectedAge(updatedSelectedLocation);
  } else {
    setSelectedAge([]);
  }
}


const [selectedInstitutions, setSelectedInstitutions] = useState([])
const handleInstitutionsSelect = (institutions) => {
  if(institutions) {
    const updatedSelectedInstitutions = selectedInstitutions.includes(institutions)
    ? selectedInstitutions.filter((selectedInstitutions) => selectedInstitutions !== institutions)
    : [...selectedInstitutions, institutions];
    setSelectedInstitutions(updatedSelectedInstitutions);
  } else {
    setSelectedInstitutions([]);
  }
}

const [selectedLevel, setSelectedLevel] = useState([])
const handleLevelSelect = (level) => {
  if(level) {
    const updatedSelectedLevel = selectedLevel.includes(level)
    ? selectedLevel.filter((selectedLevel) => selectedLevel !== level)
    : [...selectedLevel, level];
    setSelectedLevel(updatedSelectedLevel);
  } else {
    setSelectedLevel([]);
  }
}


const [selectedFollow, setSelectedFollow] = useState([])
const handleFollowSelect = (level) => {
  if(level) {
    const updatedSelectedFollow = selectedFollow.includes(level)
    ? selectedFollow.filter((selectedFollow) => selectedFollow !== level)
    : [...selectedFollow, level];
    setSelectedFollow(updatedSelectedFollow);
  } else {
    setSelectedFollow([]);
  }
}





  //Star   
  const [selectedStar, setSelectedStar] = useState([])
  const handleStarSelect = (star2) => {
    if(star2) {
      const updatedSelectedStar = selectedStar.includes(star2)
      ? selectedStar.filter((selectedStar) => selectedStar !== star2)
      : [...selectedStar, star2];
      setSelectedStar(updatedSelectedStar);
    } else {
      setSelectedStar([]);
    }
  };
  //END Star   


  const filteredData = pageData.filter(
    (item) =>
    (selectedCategories.includes("All") || item.categories === selectedCategories) &&
    (selectedAge.length === 0 || selectedAge.includes(item.age)) &&
    (selectedInstitutions.length === 0 || selectedInstitutions.includes(item.institutions)) &&
    (selectedLevel.length === 0 || selectedLevel.includes(item.level)) &&
    (selectedFollow.length === 0 || selectedFollow.includes(item.follow)) &&
    (selectedStar.length === 0 || selectedStar.includes(item.star))

  );


// Pagination
const [currentPage, setCurrentPage] = useState(1);
const recordsPerPage = perPageNumber;
const lastIndex = currentPage * recordsPerPage;
const firstIndex = lastIndex - recordsPerPage;
const records = pageData.slice(firstIndex, lastIndex);
const npage = Math.ceil(filteredData.length / recordsPerPage);

function prePage() {
  if (currentPage !== 1) {
    setCurrentPage(currentPage - 1);
  }
}

function changeCPage(id) {
  setCurrentPage(id);
}

function nextPage() {
  if (currentPage !== npage) {
    setCurrentPage(currentPage + 1);
  }
}

const numbersToShow = 3;
const totalPages = npage;

const getPageNumbers = () => {
  const midNumber = Math.floor(numbersToShow / 2);
  let startPage = Math.max(1, currentPage - midNumber);
  let endPage = Math.min(startPage + numbersToShow - 1, totalPages);

  if (endPage - startPage + 1 < numbersToShow) {
    startPage = Math.max(1, endPage - numbersToShow + 1);
  }

  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  return pages;
};
// End Pagination




const filteredAndPaginatedData = filteredData.slice(
  (currentPage - 1) * perPageNumber,
  currentPage * perPageNumber
);






















  return (
    <div className="BottomFeaturedPage">
      <div className="forMobile" onClick={() => setOpenFilter(!openFilter)}>
            <p>
                Filters 
                <IoIosArrowDropdown className={`${openFilter ? "" : "rotateSvg"}`} />
            </p>
        </div>

      <div className={`bottomEducation__left ${openFilter ? "openFilter" : "closeFilter"}`}>

        <div className="bottomEducation__left__options bottomEducation__left__1">
          <h2 onClick={() => setOpenCategories(!openCategories)}>
            <FormattedMessage
              id="BottomEducation__1__2__1"
              defaultMessage={"Categories"}
            />
          </h2>

          <ul className={`mobileEducation ${openCategories ? "hide" : "show"}`}> 
            <li>
              <input
                type="checkbox"
                id="All"
                checked={selectedCategories.includes("All")}
                onChange={() => handleCategorySelect("All")}
              />
              <label htmlFor="All">
                <FormattedMessage
                  id="BottomEducation__2__2"
                  defaultMessage={"All"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Programming"
                checked={selectedCategories.includes("Programming")}
                onChange={() => handleCategorySelect("Programming")}
              />
              <label htmlFor="Programming">
                <FormattedMessage
                  id="BottomEducation__3__3"
                  defaultMessage={"Programming"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Design"
                checked={selectedCategories.includes("Design")}
                onChange={() => handleCategorySelect("Design")}
              />
              <label htmlFor="Design">
                <FormattedMessage
                  id="BottomEducation__4__4"
                  defaultMessage={"Design"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Business & Management"
                checked={selectedCategories.includes("Business & Management")}
                onChange={() => handleCategorySelect("Business & Management")}
              />
              <label htmlFor="Business & Management">
                <FormattedMessage
                  id="BottomEducation__5__5"
                  defaultMessage={"Business & Management"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Marketing & Social Media"
                checked={selectedCategories.includes("Marketing & Social Media")}
                onChange={() => handleCategorySelect("Marketing & Social Media")}
              />
              <label htmlFor="Marketing & Social Media">
                <FormattedMessage
                  id="BottomEducation__6__7"
                  defaultMessage={"Marketing & Social Media"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Accounting & Finance"
                checked={selectedCategories.includes("Accounting & Finance")}
                onChange={() => handleCategorySelect("Accounting & Finance")}
              />
              <label htmlFor="Accounting & Finance">
                <FormattedMessage
                  id="BottomEducation__7__8"
                  defaultMessage={"Accounting & Finance"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Languages"
                checked={selectedCategories.includes("Languages")}
                onChange={() => handleCategorySelect("Languages")}
              />
              <label htmlFor="Languages">
                <FormattedMessage
                  id="BottomEducation__8__9"
                  defaultMessage={"Languages"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Information Technology"
                checked={selectedCategories.includes("Information Technology")}
                onChange={() => handleCategorySelect("Information Technology")}
              />
              <label htmlFor="Information Technology">
                <FormattedMessage
                  id="BottomEducation__9__10"
                  defaultMessage={"Information Technology"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Personal Development"
                checked={selectedCategories.includes("Personal Development")}
                onChange={() => handleCategorySelect("Personal Development")}
              />
              <label htmlFor="Personal Development">
                <FormattedMessage
                  id="BottomEducation__9__11"
                  defaultMessage={"Personal Development"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="School & Education"
                checked={selectedCategories.includes("School & Education")}
                onChange={() => handleCategorySelect("School & Education")}
              />
              <label htmlFor="School & Education">
                <FormattedMessage
                  id="BottomEducation__9__12"
                  defaultMessage={"School & Education"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Photography & Video"
                checked={selectedCategories.includes("Photography & Video")}
                onChange={() => handleCategorySelect("Photography & Video")}
              />
              <label htmlFor="Photography & Video">
                <FormattedMessage
                  id="BottomEducation__9__13"
                  defaultMessage={"Photography & Video"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Music"
                checked={selectedCategories.includes("Music")}
                onChange={() => handleCategorySelect("Music")}
              />
              <label htmlFor="Music">
                <FormattedMessage
                  id="BottomEducation__9__14"
                  defaultMessage={"Music"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Cybersecurity"
                checked={selectedCategories.includes("Cybersecurity")}
                onChange={() => handleCategorySelect("Cybersecurity")}
              />
              <label htmlFor="Cybersecurity">
                <FormattedMessage
                  id="BottomEducation__9__15"
                  defaultMessage={"Cybersecurity"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Arts & Crafts"
                checked={selectedCategories.includes("Arts & Crafts")}
                onChange={() => handleCategorySelect("Arts & Crafts")}
              />
              <label htmlFor="Arts & Crafts">
                <FormattedMessage
                  id="BottomEducation__9__16"
                  defaultMessage={"Arts & Crafts"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Kids & Youth"
                checked={selectedCategories.includes("Kids & Youth")}
                onChange={() => handleCategorySelect("Kids & Youth")}
              />
              <label htmlFor="Kids & Youth">
                <FormattedMessage
                  id="BottomEducation__9__17"
                  defaultMessage={"Kids & Youth"}
                />
              </label>
            </li>
          </ul>
        </div>

        <div className="bottomEducation__left__options bottomEducation__left__2">
          <h2 onClick={() => setOpenAge(!openAge)}>
            <FormattedMessage id="BottomEducation__10" defaultMessage={"Age"} />
          </h2>

          <ul className={`mobileEducation ${openAge ? "hide" : "show"}`}>
            <li>
              <input
                type="checkbox"
                id="1-5"
                checked={selectedAge.includes("gr1to5")}
                onChange={() => handleAgeSelect("gr1to5")}
              />
              <label htmlFor="1-5">
                <FormattedMessage
                  id="BottomEducation__11__2"
                  defaultMessage={"1-5 years old"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="5-10"
                checked={selectedAge.includes("gr5to10")}
                onChange={() => handleAgeSelect("gr5to10")}
              />
              <label htmlFor="5-10">
                <FormattedMessage
                  id="BottomEducation__12__2"
                  defaultMessage={"5-10 years old"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="10-15"
                checked={selectedAge.includes("gr10to15")}
                onChange={() => handleAgeSelect("gr10to15")}
              />
              <label htmlFor="10-15">
                <FormattedMessage
                  id="BottomEducation__13__2"
                  defaultMessage={"10-15 years old"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="15-18"
                checked={selectedAge.includes("gr15to18")}
                onChange={() => handleAgeSelect("gr15to18")}
              />
              <label htmlFor="15-18">
                <FormattedMessage
                  id="BottomEducation__15__2"
                  defaultMessage={"15-18 years old"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="18+"
                checked={selectedAge.includes("gr18")}
                onChange={() => handleAgeSelect("gr18")}
              />
              <label htmlFor="18+">
                <FormattedMessage
                  id="BottomEducation__15__3"
                  defaultMessage={"18+ years old"}
                />
              </label>
            </li>
          </ul>
        </div>



        <div className="bottomEducation__left__options bottomEducation__left__3">
          <h2 onClick={() => setOpenInstitutions(!openInstitutions)}>
            <FormattedMessage id="BottomEducation__10__3__2" defaultMessage={"institutions"} />
          </h2>

          <ul className={`mobileEducation ${openInstitutions ? "hide" : "show"}`}>
            <li>
              <input
                type="checkbox"
                id="Outkos Academy"
                checked={selectedInstitutions.includes("Outkos Academy")}
                onChange={() => handleInstitutionsSelect("Outkos Academy")}
              />
              <label htmlFor="Outkos Academy">
                <FormattedMessage
                  id="BottomEducation__11__2__3"
                  defaultMessage={"Outkos Academy"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Kosova Training"
                checked={selectedInstitutions.includes("Kosova Training")}
                onChange={() => handleInstitutionsSelect("Kosova Training")}
              />
              <label htmlFor="Kosova Training">
                <FormattedMessage
                  id="BottomEducation__12__2__3"
                  defaultMessage={"Kosova Training"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Dizajn Academy"
                checked={selectedInstitutions.includes("Dizajn Academy")}
                onChange={() => handleInstitutionsSelect("Dizajn Academy")}
              />
              <label htmlFor="Dizajn Academy">
                <FormattedMessage
                  id="BottomEducation__13__2__3"
                  defaultMessage={"Dizajn Academy"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Programim Academy"
                checked={selectedInstitutions.includes("Programim Academy")}
                onChange={() => handleInstitutionsSelect("Programim Academy")}
              />
              <label htmlFor="Programim Academy">
                <FormattedMessage
                  id="BottomEducation__14__2__3"
                  defaultMessage={"Programim Academy"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Visualkos Academy"
                checked={selectedInstitutions.includes("Visualkos Academy")}
                onChange={() => handleInstitutionsSelect("Visualkos Academy")}
              />
              <label htmlFor="Visualkos Academy">
                <FormattedMessage
                  id="BottomEducation__15__2__3"
                  defaultMessage={"Visualkos Academy"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="MediaKos Academy"
                checked={selectedInstitutions.includes("MediaKos Academy")}
                onChange={() => handleInstitutionsSelect("MediaKos Academy")}
              />
              <label htmlFor="MediaKos Academy">
                <FormattedMessage
                  id="BottomEducation__15__3__3"
                  defaultMessage={"MediaKos Academy"}
                />
              </label>
            </li>
            
            <li>
              <input
                type="checkbox"
                id="Seckos Academy"
                checked={selectedInstitutions.includes("Seckos Academy")}
                onChange={() => handleInstitutionsSelect("Seckos Academy")}
              />
              <label htmlFor="Seckos Academy">
                <FormattedMessage
                  id="BottomEducation__15__3__4"
                  defaultMessage={"Seckos Academy"}
                />
              </label>
            </li>
          </ul>
        </div>



        <div className="bottomEducation__left__options bottomEducation__left__3">
          <h2 onClick={() => setOpenLevel(!openLevel)}>
            <FormattedMessage id="BottomEducation__10__4__3" defaultMessage={"Level"} />
          </h2>

          <ul className={`mobileEducation ${openLevel ? "hide" : "show"}`}>
            <li>
              <input
                type="checkbox"
                id="Beginner"
                checked={selectedLevel.includes("Beginner")}
                onChange={() => handleLevelSelect("Beginner")}
              />
              <label htmlFor="Beginner">
                <FormattedMessage
                  id="BottomEducation__11__2__3__1"
                  defaultMessage={"Beginner"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Intermediate "
                checked={selectedLevel.includes("Intermediate")}
                onChange={() => handleLevelSelect("Intermediate")}
              />
              <label htmlFor="Intermediate ">
                <FormattedMessage
                  id="BottomEducation__12__2__3__2"
                  defaultMessage={"Intermediate "}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Advanced"
                checked={selectedLevel.includes("Advanced")}
                onChange={() => handleLevelSelect("Advanced")}
              />
              <label htmlFor="Advanced">
                <FormattedMessage
                  id="BottomEducation__13__2__3__3"
                  defaultMessage={"Advanced"}
                />
              </label>
            </li>
          </ul>
        </div>




        <div className="bottomEducation__left__options bottomEducation__left__3">
          <h2 onClick={() => setOpenDuration(!openDuration)}>
            <FormattedMessage id="BottomEducation__10__4__3__1" defaultMessage={"Duration"} />
          </h2>

          <ul className={`mobileEducation ${openDuration ? "hide" : "show"}`}>
            <li>
              <input
                type="checkbox"
                id="1-2"
                checked={selectInstitutions.includes("days1to2")}
                onChange={() => handleAgeSelect("days1to2")}
              />
              <label htmlFor="1-2">
                <FormattedMessage
                  id="BottomEducation__11__2__3__1__1"
                  defaultMessage={"1-2 days"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="1-4"
                checked={selectInstitutions.includes("weeks1to4")}
                onChange={() => handleAgeSelect("weeks1to4")}
              />
              <label htmlFor="1-4">
                <FormattedMessage
                  id="BottomEducation__12__2__3__2__2"
                  defaultMessage={"1-4 weeks"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="1-3"
                checked={selectInstitutions.includes("months1to3")}
                onChange={() => handleAgeSelect("months1to3")}
              />
              <label htmlFor="1-3">
                <FormattedMessage
                  id="BottomEducation__13__2__3__3__3"
                  defaultMessage={"1-3 months"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="3-6"
                checked={selectInstitutions.includes("months3to6")}
                onChange={() => handleAgeSelect("months3to6")}
              />
              <label htmlFor="3-6">
                <FormattedMessage
                  id="BottomEducation__13__2__3__3__4"
                  defaultMessage={"3-6 months"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="More than 6 months"
                checked={selectInstitutions.includes("moreThan6")}
                onChange={() => handleAgeSelect("moreThan6")}
              />
              <label htmlFor="More than 6 months">
                <FormattedMessage
                  id="BottomEducation__13__2__3__3__5"
                  defaultMessage={"More than 6 months"}
                />
              </label>
            </li>
          </ul>
        </div>





        <div className="bottomEducation__left__options bottomEducation__left__3">
          <h2 onClick={() => setOpenFollow(!openFollow)}>
            <FormattedMessage id="BottomEducation__10__4__3__1" defaultMessage={"Follow"} />
          </h2>

          <ul className={`mobileEducation ${openFollow ? "hide" : "show"}`}>
            <li>
              <input
                type="checkbox"
                id="Online (Remote)"
                checked={selectedFollow.includes("Online (Remote)")}
                onChange={() => handleFollowSelect("Online (Remote)")}
              />
              <label htmlFor="Online (Remote)">
                <FormattedMessage
                  id="BottomEducation__11__2__3__1__1"
                  defaultMessage={"Online (Remote)"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="In Class (Physical)"
                checked={selectedFollow.includes("In Class (Physical)")}
                onChange={() => handleFollowSelect("In Class (Physical)")}
              />
              <label htmlFor="In Class (Physical)">
                <FormattedMessage
                  id="BottomEducation__12__2__3__2__2"
                  defaultMessage={"In Class (Physical)"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Pre-recorded"
                checked={selectedFollow.includes("Pre-recorded")}
                onChange={() => handleFollowSelect("Pre-recorded")}
              />
              <label htmlFor="Pre-recorded">
                <FormattedMessage
                  id="BottomEducation__13__2__3__3__3"
                  defaultMessage={"Pre-recorded"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Personal (1 to 1)"
                checked={selectedFollow.includes("Personal (1 to 1)")}
                onChange={() => handleFollowSelect("Personal (1 to 1)")}
              />
              <label htmlFor="Personal (1 to 1)">
                <FormattedMessage
                  id="BottomEducation__13__2__3__3__4"
                  defaultMessage={"Personal (1 to 1)"}
                />
              </label>
            </li>
          </ul>
        </div>





        <div className="bottomEducation__left__options bottomEducation__left__4">
          <h2 onClick={() => setOpenReviews(!openReviews)}>
            <FormattedMessage
              id="BottomEducation__18"
              defaultMessage={"reviews"}
            />
          </h2>

          <ul className={`mobileEducation ${openReviews ? "hide" : "show"}`}>
            <li>
              <input
                type="checkbox"
                id="5Star"
                checked={selectedStar.includes(5)}
                onChange={() => handleStarSelect(5)}
              />
              <label htmlFor="5Star">
                5<span className="star"></span>
                <span className="star"></span>
                <span className="star"></span>
                <span className="star"></span>
                <span className="star"></span>
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="4Star"
                checked={selectedStar.includes(4)}
                onChange={() => handleStarSelect(4)}
              />
              <label htmlFor="4Star">
                4<span className="star"></span>
                <span className="star"></span>
                <span className="star"></span>
                <span className="star"></span>
                <span className="grayStar"></span>
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="3Star"
                checked={selectedStar.includes(3)}
                onChange={() => handleStarSelect(3)}
              />
              <label htmlFor="3Star">
                3<span className="star"></span>
                <span className="star"></span>
                <span className="star"></span>
                <span className="grayStar"></span>
                <span className="grayStar"></span>
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="2Star"
                checked={selectedStar.includes(2)}
                onChange={() => handleStarSelect(2)}
              />
              <label htmlFor="2Star">
                2<span className="star"></span>
                <span className="star"></span>
                <span className="grayStar"></span>
                <span className="grayStar"></span>
                <span className="grayStar"></span>
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="1Star"
                checked={selectedStar.includes(1)}
                onChange={() => handleStarSelect(1)}
              />
              <label htmlFor="1Star">
                1<span className="star"></span>
                <span className="grayStar"></span>
                <span className="grayStar"></span>
                <span className="grayStar"></span>
                <span className="grayStar"></span>
              </label>
            </li>
          </ul>
        </div>
      </div>

      <div className="bottomEducation__right">
        <div className="bottomEducation__right__top">
          <div className="left">
            <h1>
              <FormattedMessage
                id="BottomEducation__19__555"
                defaultMessage={"Courses"}
              />
            </h1>

            <p>
              {filteredData.length}
              &nbsp;
              <FormattedMessage
                id="BottomEducation__20"
                defaultMessage={" results"}
              />
            </p>
          </div>

          <div className="right">
            <p>
              <FormattedMessage
                id="BottomEducation__21"
                defaultMessage={"view"}
              />
            </p>

            <p className="pBorder perPageMain">
              {perPageNumber}
              <FormattedMessage
                id="BottomEducation__22"
                defaultMessage={" per page "}
              />
              <IoIosArrowDown />

              <div className="perPage">
                <p onClick={() => setPerPageNumber(6)}>
                  6
                  <FormattedMessage
                    id="BottomEducation__22"
                    defaultMessage={" per page "}
                  />
                </p>

                <p onClick={() => setPerPageNumber(12)}>
                  12
                  <FormattedMessage
                    id="BottomEducation__22"
                    defaultMessage={" per page "}
                  />
                </p>

                <p onClick={() => setPerPageNumber(24)}>
                  24
                  <FormattedMessage
                    id="BottomEducation__22"
                    defaultMessage={" per page "}
                  />
                </p>
              </div>
            </p>

            <p className="pBorder perPageMain">
              <BiMenuAltLeft />
              <FormattedMessage
                id="BottomEducation__253"
                defaultMessage={sortOrder}
              />
              <IoIosArrowDown />

              <div className="perPage perPageSort">
                <p
                  className=" inRightpBorder insideP"
                  onClick={() => handleSort("name")}
                >
                  <span className={`${sortOrder ? "backColor" : ""}`}>
                    DESC
                  </span>
                  <span className={`${sortOrder ? "backColor" : ""}`}>ASC</span>
                </p>

                {/* <p className='pBorder inRightpBorder' onClick={() => handleSort('star')}>
                        {sortOrder === 'asc' ? 'High to Low Rating' : 'Low to High Rating'}
                        <IoIosArrowDown />
                        </p> */}
              </div>
            </p>

            <p className="pBorder inRightpBorder" onClick={handleGrid}>
              <BsFillGrid3X3GapFill className={`${inGrid ? "" : "orange"} `} />
              <span className="line"></span>
              <FaList className={`${inGrid ? "orange" : ""} `} />
            </p>
          </div>
        </div>

        <div
          className={`bottomEducation__right__mid ${
            inGrid ? "makeInLine" : ""
          }`}
        >
            {filteredAndPaginatedData.map((item) => (
                <Link 
                  key={item.id}
                  to={
                    { 
                      pathname: `/featured/${item.id}/${item.title.replace(/ /g, "_")}`, 
                      state: { featuredData: item } 
                    }
                  } 
                >
                  <Page6Card
                    key={item.id}
                    bgImage={`${process.env.REACT_APP_FeaturedPage_IMAGE}${item.bgImage}`}
                    offerdBy={item.offerdBy}
                    title={item.title}
                    description={item.description}
                    months={item.months}
                  />
                </Link>
            ))}

        </div>

        <div className="bottomEducation__right__bottom">
          <ul className="bottom__numbers">
            <p onClick={prePage}>
              <MdKeyboardArrowLeft />
              <FormattedMessage
                id="BottomEducation__24"
                defaultMessage={"Previous"}
              />
            </p>

            {currentPage > 2 && (
              <li>
                <Link
                  to="#"
                  onClick={() => changeCPage(1)}
                  className="page-link"
                >
                  1
                </Link>
              </li>
            )}
            {currentPage > numbersToShow - 1 && (
              <li>
                <span>...</span>
              </li>
            )}

            {getPageNumbers().map((n) => (
              <li
                key={n}
                className={`page-item ${
                  currentPage === n ? "activeBottom" : ""
                }`}
              >
                <Link
                  to="#"
                  onClick={() => changeCPage(n)}
                  className="page-link"
                >
                  {n}
                </Link>
              </li>
            ))}

            {currentPage < totalPages - 1 && (
              <li>
                <span>...</span>
              </li>
            )}
            {currentPage < totalPages - 1 && (
              <li>
                <Link
                  to="#"
                  onClick={() => changeCPage(totalPages)}
                  className="page-link"
                >
                  {totalPages}
                </Link>
              </li>
            )}

            <p onClick={nextPage}>
              <FormattedMessage
                id="BottomEducation__25"
                defaultMessage={"Next"}
              />
              <MdKeyboardArrowRight />
            </p>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BottomFeaturedPage;
