import React from 'react'
import './ReviewsCard.scss'
import { FaStar } from 'react-icons/fa'

const ReviewsCard = ({ bgImage, name, description, dataWhere, star }) => {

    const bgImageDiv = {
        backgroundImage: `url(${bgImage})`
    }

        // Utility function to generate stars dynamically
        const renderStars = () => {
            const stars = [];
            for (let i = 1; i <= 5; i++) {
                stars.push(
                    <FaStar
                        key={i}
                        className={i <= star ? "orange" : "gray"}
                    />
                );
            }
            return stars;
        }

  return (
    <div className='ReviewsCard'>
        <div className="top">
            <p>
            {description}
            </p>

            <span>
                {star ? (
                    <>
                        {renderStars()}
                        &nbsp;
                        {star.toFixed(1)} {/* Display star rating, assuming star is a number */}
                    </>
                ) : (
                    <>
                        <FaStar className="gray2" />
                        <FaStar className="gray2" />
                        <FaStar className="gray2" />
                        <FaStar className="gray2" />
                        <FaStar className="gray2" />
                        &nbsp;5.0
                    </>
                )}
            </span>
        </div>

        <span>
            {dataWhere}
        </span>

        <div className='bottom'>
            <div className="reviewsImg" style={bgImageDiv}></div>

            <p className="name">
            {name}
            </p>


        </div>

    </div>
  )
}

export default ReviewsCard