import React from "react";
import "./BlogPage.scss";
import Navbar from "../../components/shared/Navbar/Navbar";
import Footer from "../../components/shared/Footer/Footer";
import BottomBlog from "../../components/BlogPage/BottomBlog/BottomBlog";

const BlogPage = () => {
  return (
    <div className="BlogPage">
      <Navbar />

      <div className="BlogPageInside">
        <div className="BlogTop">
          <div className="blogTopFilter"></div>

          <div className="onlyLogo">
            <div className="hat"></div>
            <div className="kosova"></div>
          </div>

          <h2>KosovaEducation Blog</h2>
        </div>

        <BottomBlog />
      </div>

      <Footer />
    </div>
  );
};

export default BlogPage;
