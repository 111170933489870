import React from "react";
import "./Featured.scss";
import Footer from "../../components/shared/Footer/Footer";
import Page13 from "../../components/Home/Page13/Page13";
import Navbar from "../../components/shared/Navbar/Navbar";
import BottomFeaturedPage from "../../components/Featured/BottomFeaturedPage/BottomFeaturedPage";
import TopFeatured from "../../components/Featured/TopFeatured/TopFeatured";

const Featured = () => {
  return (
    <div className="Featured">
      <Navbar />

      <div className="inside__EducationInstitution">
        <TopFeatured />
        <BottomFeaturedPage />
      </div>

      <Page13 />
      <Footer />
    </div>
  );
};

export default Featured;
