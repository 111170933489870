import React, { useState } from "react";
import "./Login.scss";
import Navbar from "../../components/shared/Navbar/Navbar";
import Footer from "../../components/shared/Footer/Footer";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const Login = () => {
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleshow1 = () => {
    setShow1(!show1);
  };

  const handleshow2 = () => {
    setShow2(!show2);
  };

  return (
    <div className="Login">
      <Navbar />

      <div className="LoginPage">
        <div className="register__left">
          <div className="form">
            <h1>
              <FormattedMessage
                id="LoginPage__1__2"
                defaultMessage={"Log In"}
              />
            </h1>

            <div className="buttonForLoginAndSignUp">
              <Link to="/login">Log In</Link>

              <Link to="/register">Sign Up</Link>
            </div>

            <div className="inputForm">
              <label>Email</label>
              <div className="inputIcon">
                <span className="smallIcon email"></span>
                <input type="email" placeholder="Write email here" />
              </div>
            </div>

            <div className="inputForm">
              <label>
                <FormattedMessage
                  id="LoginPage__6"
                  defaultMessage={"Password"}
                />
              </label>
              <div className="inputIcon">
                <span className="smallIcon password"></span>
                <input
                  type={show1 ? "text" : "password"}
                  placeholder="Enter your password"
                />
                <span
                  className="smallIcon passwordLast"
                  onClick={handleshow1}
                ></span>
              </div>
            </div>

            <div className="checkboxInput">
              <input type="checkbox" id="loginCheckbox" />
              <label htmlFor="loginCheckbox">
                <FormattedMessage
                  id="LoginPage__8"
                  defaultMessage={"Remember me "}
                />

                <Link to="" className="pink registerSpace privacy">
                  <FormattedMessage
                    id="LoginPage__11"
                    defaultMessage={"Forgot password?"}
                  />
                </Link>
              </label>
            </div>

            <div className="loginButtons">
              <button className="onHoverButton">
                <FormattedMessage
                  id="LoginPage__12"
                  defaultMessage={"Log in"}
                />
              </button>
              <div>
                <Link to="" className="dontHave">
                  <FormattedMessage
                    id="login__2__1"
                    defaultMessage={"Don't have an account? "}
                  />
                  <span>
                    <FormattedMessage
                      id="login__2__2"
                      defaultMessage={"Sign Up"}
                    />
                  </span>
                </Link>
              </div>

              <p>
                <FormattedMessage
                  id="LoginPage__16__1"
                  defaultMessage={"Or continue with"}
                />
              </p>

              <div className="loginWithOther">
                <button className="onHoverButton withGoogle">
                  <span className="google"></span>
                  <FormattedMessage
                    id="LoginPage__12__1"
                    defaultMessage={"Continue with "}
                  />{" "}
                  google
                </button>

                <button className="onHoverButton withGoogle">
                  <span className="google apple"></span>
                  <FormattedMessage
                    id="LoginPage__12__2"
                    defaultMessage={"Continue with "}
                  />{" "}
                  Apple
                </button>
              </div>
            </div>
          </div>

          <div className="registerBgTriangle"></div>
        </div>

        <div className="register__right">
          <div className="register__right__filter"></div>

          <div className="inside__register">
            <h1>
              <FormattedMessage
                id="LoginPage__13"
                defaultMessage={"Bringing innovations in education systems"}
              />
            </h1>

            <p>
              <FormattedMessage
                id="LoginPage__14"
                defaultMessage={
                  "KosovaEducation is working hard towards making the education in Kosovo better through innovative ideas and challenges."
                }
              />
            </p>

            <Link to="">
              <FormattedMessage
                id="LoginPage__15"
                defaultMessage={"About us"}
              />
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Login;
