import axios from 'axios'
import React, { useEffect, useState } from 'react'
import MobileCard from '../MobileCard/MobileCard'
import './TakeActionMobile.scss'
import { FormattedMessage } from 'react-intl'

import icon1 from '../../../../assets/TakeAction/icon1.svg'
import icon2 from '../../../../assets/TakeAction/icon2.svg'
import icon3 from '../../../../assets/TakeAction/icon3.svg'
import icon4 from '../../../../assets/TakeAction/icon4.svg'

const leftData = [
    {
        id: 1,
        title: "Free Education",
        description: "Everyone deserves to learn",
        image: icon1,
    },
    {
        id: 2,
        title: "Fundraise",
        description: "Start a fundraiser",
        image: icon2,
    },
    {
        id: 3,
        title: "Donate",
        description: "Everything helps us to do more",
        image: icon3,
    },
    {
        id: 4,
        title: "Shop",
        description: "Donate by shopping",
        image: icon4,
    },
];


const TakeActionMobile = () => {
  return (
    <div className='TakeActionMobile'>
        {
            leftData.map((item) => (
                <div 
                    className='insideMobileMega'
                    key={item.id}
                >
                    <MobileCard 
                        bgImage={item.image}
                        titleMobile={
                            <FormattedMessage id={`takeActionTitle__${item.id}`} defaultMessage={item.title} />
                        }
                    />
                </div>
            ))
        }
    </div>
  )
}

export default TakeActionMobile
