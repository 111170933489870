import React, { useEffect, useState } from "react";
import "./BlogDetails.scss";
import Navbar from "../../components/shared/Navbar/Navbar";
import Footer from "../../components/shared/Footer/Footer";
import axios from "axios";
import { Link, useLocation, useParams } from "react-router-dom";

import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import Page12Card from "../../components/Home/Page12/Page12Card/Page12Card";

const BlogDetails = () => {
  const location = useLocation();
  const blogData = location.state?.blogData || {};
  const { id, title } = useParams();

  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ROUTE}Blog/Blog.json`)
      .then((res) => {
        setPageData(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.error("Blog : ", error);
      });
  }, []);

  const item =
    pageData.find((item) => item.id === Number(id) && item.title.replace(/ /g, "_") === title) ||
    blogData;

  const bgImageDiv = {
    backgroundImage: `url(${process.env.REACT_APP_BLOG_IMAGE}${item.bgImage || ""})`,
  };

  const item2 = pageData[(item.id + 1) % 8];
  const item3 = pageData[(item.id - 2 + 8) % 8];

  return (
    <div className="BlogDetails">
      <Navbar />

      <div className="inside__BlogDetails">
        <div className="top__BlogDetails" style={bgImageDiv}></div>

        <div className="bottom__BlogDetails">
          <div className="left">
            <h1 className="firstTitle">{item.title}</h1>

            <div>
              <h2 className="smallTitle">{item.title2}</h2>

              <p className="textBlog">{item.description2}</p>
            </div>

            <div>
              <h2 className="smallTitle">{item.title3}</h2>

              <p className="textBlog">{item.description3}</p>
            </div>

            <div>
              <h2 className="smallTitle">{item.title4}</h2>

              <p className="textBlog">{item.description4}</p>
            </div>

            <div className="blogImage" style={bgImageDiv}></div>

            <div>
              <h2 className="smallTitle">{item.title5}</h2>

              <p className="textBlog">{item.description5}</p>
            </div>

            <div>
              <h2 className="smallTitle">{item.title6}</h2>

              <p className="textBlog">{item.description6}</p>
            </div>

            <div>
              <h2 className="smallTitle">{item.title7}</h2>

              <p className="textBlog">{item.description7}</p>
            </div>

            <div>
              <h2 className="smallTitle">{item.title8}</h2>

              <p className="textBlog">{item.description8}</p>
            </div>

            <div className="social">
              <p className="textBlog">Share this article:</p>
              <Link to="" className="facebook">
                <FaFacebookF className="facebook" />
                Facebook
              </Link>

              <Link to="" className="linkedin">
                <FaLinkedinIn className="linkedin" />
                LinkedIn
              </Link>

              <Link to="" className="twitter">
                <FaTwitter className="twitter" />
                Twitter
              </Link>
            </div>
          </div>

          <div className="right">
            <h2 className="smallTitle">Suggested articles</h2>

            <div className="card">
              {item2 && (
                <Link
                  className="leftBlogLink"
                  to={{
                    pathname: `/blog/${item2.id}/${item2.title.replace(/ /g, "_")}`,
                    state: { blogData: item2 },
                  }}
                >
                  <Page12Card
                    bgImage={`${process.env.REACT_APP_BLOG_IMAGE}${item.bgImage}`}
                    buttonText={item2.buttonText}
                    data={item2.data}
                    description={item2.description}
                    title={item2.title}
                    key={item2.id}
                  />
                </Link>
              )}
              {item3 && (
                <Link
                  className="leftBlogLink"
                  to={{
                    pathname: `/blog/${item3.id}/${item3.title.replace(/ /g, "_")}`,
                    state: { blogData: item3 },
                  }}
                >
                  <Page12Card
                    bgImage={`${process.env.REACT_APP_BLOG_IMAGE}${item.bgImage}`}
                    buttonText={item3.buttonText}
                    data={item3.data}
                    description={item3.description}
                    title={item3.title}
                    key={item3.id}
                  />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default BlogDetails;
