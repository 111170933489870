import React, { useEffect, useState } from 'react'
import './AboutUsMobile.scss'
import MobileCard from '../MobileCard/MobileCard'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'

import icon1 from '../../../../assets/Aboutus/icon1.svg'
import icon2 from '../../../../assets/Aboutus/icon2.svg'
import icon3 from '../../../../assets/Aboutus/icon3.svg'
import icon4 from '../../../../assets/Aboutus/icon4.svg'
import icon5 from '../../../../assets/Aboutus/icon5.svg'
import icon6 from '../../../../assets/Aboutus/icon6.svg'
import icon7 from '../../../../assets/Aboutus/icon7.svg'
import icon8 from '../../../../assets/Aboutus/icon8.svg'



const leftData = [
    {
      id: 1,
      title: "KosovaEducation",
      description: "Read our story",
      image: icon1,
    },
    {
      id: 2,
      title: "Institutions",
      description: "See all institutions you can join",
      image: icon2,
    },
    {
      id: 3,
      title: "Our NGO",
      description: "We care for the future",
      image: icon3,
    },
    {
      id: 4,
      title: "Our Values",
      description: "What we value the most?",
      image: icon4,
    },
    {
      id: 5,
      title: "Our Practice Areas",
      description: "See what we do and help",
      image: icon5,
    },
    {
      id: 6,
      title: "Contacts",
      description: "Contact us now and get help",
      image: icon6,
    },
    {
      id: 7,
      title: "Leadership",
      description: "Meet our team",
      image: icon7,
    },
    {
      id: 8,
      title: "Community",
      description: "Join our community to help",
      image: icon8,
    },
  ];

const AboutUsMobile = () => {

  return (
    <div className='AboutUsMobile'>
        {
            leftData.map((item) => (
                <div 
                    className='insideMobileMega'
                    key={item.id}
                >
                    <MobileCard 
                        bgImage={item.image}
                        titleMobile={
                            <FormattedMessage id={`aboutUsTitle__${item.id}`} defaultMessage={item.title} />
                        }
                    />
                </div>
            ))
        }
    </div>
  )
}

export default AboutUsMobile