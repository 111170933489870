import React, { useEffect, useState } from "react";
import "./BottomBlog.scss";
import { IoIosArrowDown } from "react-icons/io";
import { BiMenuAltLeft } from "react-icons/bi";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { FaList } from "react-icons/fa";
import axios from "axios";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import Page12Card from "../../Home/Page12/Page12Card/Page12Card";
import { IoSearchSharp } from "react-icons/io5";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";

const BottomBlog = () => {
  const [openCategories, setOpenCategories] = useState(true);
  const [openAge, setOpenAge] = useState(true);
  const [openLocation, setOpenLocation] = useState(true);
  const [openReviews, setOpenReviews] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);

  const [pageData, setPageData] = useState([]);
  const [originalPageData, setOriginalPageData] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [inGrid, inLine] = useState(false);
  const [perPageNumber, setPerPageNumber] = useState(8);

  const [sortOrder, setSortOrder] = useState("Latest");

  const data = [
    "Deçan",
    "Dragash",
    "Drenas",
    "Ferizaj",
    "Fushë Kosovë",
    "Gjakovë",
    "Gjilan",
    "Istog",
    "Kaçanik",
    "Kamenicë",
    "Klinë",
    "Lipjan",
    "Malishevë",
    "Mitrovicë",
    "Obiliq",
    "Pejë",
    "Podujevë",
    "Prishtinë",
    "Prizren",
    "Rahovec",
    "Skenderaj",
    "Suharekë",
    "Shtërpcë",
    "Shtime",
    "Viti",
    "Vushtrri",
  ];

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ROUTE}Blog/Blog.json`)

      .then((res) => {
        setPageData(res.data);
        setOriginalPageData(res.data);
      })
      .catch((error) => {
        console.error("Blog : ", error);
      });
  }, []);

  const handleGrid = () => {
    inLine(!inGrid);
  };

  const handleSort = (property) => {
    const sortedData = [...filteredData].sort((a, b) => {
      if (sortOrder === "Latest") {
        return a[property] > b[property] ? 1 : -1;
      } else {
        return a[property] < b[property] ? 1 : -1;
      }
    });

    setPageData(sortedData);
    setSortOrder(sortOrder === "Latest" ? "Newest" : "Latest");
  };

  const [selectedCategory, setSelectedCategory] = useState([]);
  const handleCategorySelect = (buttonText) => {
    if (buttonText) {
      const updatedSelectedCategory = selectedCategory.includes(buttonText)
        ? selectedCategory.filter(
            (selectedCategory) => selectedCategory !== buttonText
          )
        : [...selectedCategory, buttonText];
      setSelectedCategory(updatedSelectedCategory);
    } else {
      setSelectedCategory([]);
    }
  };

  const [selectedYear, setSelectedYear] = useState();
  const handleYearSelected = (year) => {
    setSelectedYear(year);
  };

  const [selectedMonth, setSelectedMonth] = useState();
  const handleMonthSelected = (year) => {
    setSelectedMonth(year);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    const filteredData = originalPageData.filter((item) =>
      item.title.toLowerCase().includes(term)
    );
    setPageData(filteredData);
  };

  const filteredData = pageData.filter(
    (item) =>
      (selectedCategory.length === 0 ||
        selectedCategory.includes(item.buttonText)) &&
      (selectedYear == null || selectedYear.includes(item.year)) &&
      (selectedMonth == null || selectedMonth.includes(item.month))
  );

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = perPageNumber;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = pageData.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filteredData.length / recordsPerPage);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const numbersToShow = 3;
  const totalPages = npage;

  const getPageNumbers = () => {
    const midNumber = Math.floor(numbersToShow / 2);
    let startPage = Math.max(1, currentPage - midNumber);
    let endPage = Math.min(startPage + numbersToShow - 1, totalPages);

    if (endPage - startPage + 1 < numbersToShow) {
      startPage = Math.max(1, endPage - numbersToShow + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };
  // End Pagination

  const filteredAndPaginatedData = filteredData.slice(
    (currentPage - 1) * perPageNumber,
    currentPage * perPageNumber
  );

  return (
    <div className="BottomBlog">
      <div className="bottomBlog__left">
        <div className="search">
          <h3 className="bottomBlog__title">Search blog</h3>

          <div className="form">
            <IoSearchSharp />
            <input
              type="search"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>

        <div className="yearMonth">
          <div className="year">
            <h3 className="bottomBlog__title">Year</h3>

            <div className="insideYear">
              <p>{!selectedYear ? <>All years</> : selectedYear}</p>

              <MdOutlineKeyboardArrowDown />

              <div className="onHoverOpen">
                <ul>
                  <li onClick={() => handleYearSelected()}>All years</li>
                  <li onClick={() => handleYearSelected("2023")}>2023</li>
                  <li onClick={() => handleYearSelected("2022")}>2022</li>
                  <li onClick={() => handleYearSelected("2021")}>2021</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="month">
            <h3 className="bottomBlog__title">Month</h3>

            <div className="insideMonth">
              <p>{!selectedMonth ? <>All months</> : selectedMonth}</p>

              <MdOutlineKeyboardArrowDown />

              <div className="onHoverOpen">
                <ul>
                  <li onClick={() => handleMonthSelected()}>All months</li>
                  <li onClick={() => handleMonthSelected("Jan")}>Jan</li>
                  <li onClick={() => handleMonthSelected("Feb")}>Feb</li>
                  <li onClick={() => handleMonthSelected("Apr")}>Apr</li>
                  <li onClick={() => handleMonthSelected("May")}>May</li>
                  <li onClick={() => handleMonthSelected("Jun")}>Jun</li>
                  <li onClick={() => handleMonthSelected("Jul")}>Jul</li>

                  <li onClick={() => handleMonthSelected("Aug")}>Aug</li>

                  <li onClick={() => handleMonthSelected("Sep")}>Sep</li>

                  <li onClick={() => handleMonthSelected("Oct")}>Oct</li>

                  <li onClick={() => handleMonthSelected("Nov")}>Nov</li>

                  <li onClick={() => handleMonthSelected("Dec")}>Dec</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="categories">
          <h3 className="bottomBlog__title">Categories</h3>

          <div className="insideCategories">
            <Link
              onClick={() => handleCategorySelect("News")}
              to=""
              checked={selectedCategory.includes("News")}
              className={`${
                selectedCategory == "News" ? "activeLink" : "unActive"
              }`}
            >
              News
            </Link>

            <Link
              onClick={() => handleCategorySelect("Stories")}
              to=""
              checked={selectedCategory.includes("Stories")}
              className={`${
                selectedCategory == "Stories" ? "activeLink" : "unActive"
              }`}
            >
              Stories
            </Link>

            <Link
              onClick={() => handleCategorySelect("Guides")}
              to=""
              checked={selectedCategory.includes("Guides")}
              className={`${
                selectedCategory == "Guides" ? "activeLink" : "unActive"
              }`}
            >
              Guides
            </Link>

            <Link
              onClick={() => handleCategorySelect("Education")}
              to=""
              checked={selectedCategory.includes("Education")}
              className={`${
                selectedCategory == "Education" ? "activeLink" : "unActive"
              }`}
            >
              Education
            </Link>

            <Link
              onClick={() => handleCategorySelect("Programs")}
              to=""
              checked={selectedCategory.includes("Programs")}
              className={`${
                selectedCategory == "Programs" ? "activeLink" : "unActive"
              }`}
            >
              Programs
            </Link>

            <Link
              onClick={() => handleCategorySelect("Partners")}
              to=""
              checked={selectedCategory.includes("Partners")}
              className={`${
                selectedCategory == "Partners" ? "activeLink" : "unActive"
              }`}
            >
              Partners
            </Link>

            <Link
              onClick={() => handleCategorySelect("Jobs")}
              to=""
              checked={selectedCategory.includes("Jobs")}
              className={`${
                selectedCategory == "Jobs" ? "activeLink" : "unActive"
              }`}
            >
              Jobs
            </Link>

            <Link
              onClick={() => handleCategorySelect("Projects")}
              to=""
              checked={selectedCategory.includes("Projects")}
              className={`${
                selectedCategory == "Projects" ? "activeLink" : "unActive"
              }`}
            >
              Projects
            </Link>

            <Link
              onClick={() => handleCategorySelect("Educational Institutions")}
              to=""
              checked={selectedCategory.includes("Educational Institutions")}
              className={`${
                selectedCategory == "Educational Institutions"
                  ? "activeLink"
                  : "unActive"
              }`}
            >
              Educational Institutions
            </Link>

            <Link
              onClick={() => handleCategorySelect("Courses")}
              to=""
              checked={selectedCategory.includes("Courses")}
              className={`${
                selectedCategory == "Courses" ? "activeLink" : "unActive"
              }`}
            >
              Courses
            </Link>

            <Link
              onClick={() => handleCategorySelect("Teachers")}
              to=""
              checked={selectedCategory.includes("Teachers")}
              className={`${
                selectedCategory == "Teachers" ? "activeLink" : "unActive"
              }`}
            >
              Teachers
            </Link>

            <Link
              onClick={() => handleCategorySelect("Others")}
              to=""
              checked={selectedCategory.includes("Others")}
              className={`${
                selectedCategory == "Others" ? "activeLink" : "unActive"
              }`}
            >
              Others
            </Link>
          </div>
        </div>

        <div className="subscribe">
          <div className="subscribeFilter"></div>

          <h2>Subscribe to our newsletter</h2>

          <p>Receive latest articles in your inbox.</p>

          <div className="insideSubscribe">
            <h3 className="bottomBlog__title">Categories</h3>

            <div className="form">
              <MdOutlineEmail />
              <input type="text" placeholder="Enter your email" />
            </div>

            <Link to="">Subscribe</Link>
          </div>
        </div>
      </div>

      <div className="bottomEducation__right">
        <div className="bottomEducation__right__top">
          <div className="left">
            <h1>
              <FormattedMessage
                id="BottomEducation__19__33"
                defaultMessage={"Articles"}
              />
            </h1>
          </div>

          <div className="right">
            <p>
              <FormattedMessage
                id="BottomEducation__21"
                defaultMessage={"view"}
              />
            </p>

            <p className="pBorder perPageMain">
              {perPageNumber}
              <FormattedMessage
                id="BottomEducation__22"
                defaultMessage={" per page "}
              />
              <IoIosArrowDown />

              <div className="perPage">
                <p onClick={() => setPerPageNumber(8)}>
                  8
                  <FormattedMessage
                    id="BottomEducation__22"
                    defaultMessage={" per page "}
                  />
                </p>

                <p onClick={() => setPerPageNumber(16)}>
                  16
                  <FormattedMessage
                    id="BottomEducation__22"
                    defaultMessage={" per page "}
                  />
                </p>

                <p onClick={() => setPerPageNumber(24)}>
                  24
                  <FormattedMessage
                    id="BottomEducation__22"
                    defaultMessage={" per page "}
                  />
                </p>
              </div>
            </p>

            <p className="pBorder perPageMain">
              <BiMenuAltLeft />
              <FormattedMessage
                id="BottomEducation__253"
                defaultMessage={sortOrder}
              />
              <IoIosArrowDown />

              <div className="perPage perPageSort">
                <p
                  className=" inRightpBorder insideP"
                  onClick={() => handleSort("name")}
                >
                  <span className={`${sortOrder ? "backColor" : ""}`}>
                    Latest
                  </span>
                  <span className={`${sortOrder ? "backColor" : ""}`}>
                    Newest
                  </span>
                </p>

                {/* <p className='pBorder inRightpBorder' onClick={() => handleSort('star')}>
                    {sortOrder === 'asc' ? 'High to Low Rating' : 'Low to High Rating'}
                    <IoIosArrowDown />
                </p> */}
              </div>
            </p>

            <p className="pBorder inRightpBorder" onClick={handleGrid}>
              <BsFillGrid3X3GapFill className={`${inGrid ? "" : "orange"} `} />
              <span className="line"></span>
              <FaList className={`${inGrid ? "orange" : ""} `} />
            </p>
          </div>
        </div>

        {filteredData.length > 0 ? (
          <div
            className={`bottomEducation__right__mid ${
              inGrid ? "makeInLine" : ""
            }`}
          >
            {filteredAndPaginatedData.map((item) => (
              <Link 
                key={item.id} 
                to={
                  { 
                    pathname: `/blog/${item.id}/${item.title.replace(/ /g, "_")}`, 
                    state: { blogData: item } 
                  } 
                } 
              >
                <Page12Card
                  key={item.key}
                  bgImage={`${process.env.REACT_APP_BLOG_IMAGE}${item.bgImage}`}
                  data={item.data}
                  title={
                    <FormattedMessage
                      id={`page12Title__${item.id}`}
                      defaultMessage={item.title}
                    />
                  }
                  description={
                    <FormattedMessage
                      id={`page12Description__${item.id}`}
                      defaultMessage={item.description}
                    />
                  }
                  buttonText={item.buttonText}
                />
              </Link>
            ))}
          </div>
        ) : (
          <p className="noResults">No Results found</p>
        )}

        {filteredData.length > 0 ? (
          <div className="bottomEducation__right__bottom">
            <ul className="bottom__numbers">
              <p onClick={prePage}>
                <MdKeyboardArrowLeft />
                <FormattedMessage
                  id="BottomEducation__24"
                  defaultMessage={"Previous"}
                />
              </p>

              {currentPage > 2 && (
                <li>
                  <Link
                    to="#"
                    onClick={() => changeCPage(1)}
                    className="page-link"
                  >
                    1
                  </Link>
                </li>
              )}
              {currentPage > numbersToShow - 1 && (
                <li>
                  <span>...</span>
                </li>
              )}

              {getPageNumbers().map((n) => (
                <li
                  key={n}
                  className={`page-item ${
                    currentPage === n ? "activeBottom" : ""
                  }`}
                >
                  <Link
                    to="#"
                    onClick={() => changeCPage(n)}
                    className="page-link"
                  >
                    {n}
                  </Link>
                </li>
              ))}

              {currentPage < totalPages - 1 && (
                <li>
                  <span>...</span>
                </li>
              )}
              {currentPage < totalPages - 1 && (
                <li>
                  <Link
                    to="#"
                    onClick={() => changeCPage(totalPages)}
                    className="page-link"
                  >
                    {totalPages}
                  </Link>
                </li>
              )}

              <p onClick={nextPage}>
                <FormattedMessage
                  id="BottomEducation__25"
                  defaultMessage={"Next"}
                />
                <MdKeyboardArrowRight />
              </p>
            </ul>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default BottomBlog;
