import React from 'react'
import './TopActiveProjects.scss'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

const TopActiveProjects = () => {
  return (
    <div className='TopActiveProjects'>
      <div className="TopEducationFilter"></div>

      <div className="insideTopEducation">

          <h1>
          <FormattedMessage id='TopActiveProjects__1' defaultMessage={"Donating to school construction to improve education"} />
          </h1>

          <p>
          <FormattedMessage id='TopActiveProjects__2' defaultMessage={"KosovaEducation collaborates with the best institutions of Kosova to bring the best opportunities to your kids"} />
          </p>

          <Link to="">
           <FormattedMessage id='TopActiveProjects__3' defaultMessage={"See all institutions"} />
          </Link>
   
      </div>
    </div>
  )
}

export default TopActiveProjects