import React, { useEffect, useState } from "react";
import "./TakeAction.scss";
import NavbarButtons from "../../NavbarButtons/NavbarButtons";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { FormattedMessage } from "react-intl";

import icon1 from "../../../../assets/TakeAction/icon1.svg";
import icon2 from "../../../../assets/TakeAction/icon2.svg";
import icon3 from "../../../../assets/TakeAction/icon3.svg";
import icon4 from "../../../../assets/TakeAction/icon4.svg";

const leftData = [
  {
    id: 1,
    title: "Free Education",
    description: "Everyone deserves to learn",
    image: icon1,
  },
  {
    id: 2,
    title: "Fundraise",
    description: "Start a fundraiser",
    image: icon2,
  },
  {
    id: 3,
    title: "Donate",
    description: "Everything helps us to do more",
    image: icon3,
  },
  {
    id: 4,
    title: "Shop",
    description: "Donate by shopping",
    image: icon4,
  },
];

const TakeAction = () => {
  return (
    <div className="TakeAction">
      <h3>
        <FormattedMessage
          id="takeAction__1__1"
          defaultMessage={"take action"}
        />
      </h3>

      <div className="insideAbout">
        <div className="aboutUs__left">
          <div className="inside__about">
            {leftData.map((item) => (
              <NavbarButtons
                key={item.id}
                bgImage={item.image}
                title={
                  <FormattedMessage
                    id={`takeActionTitle__${item.id}`}
                    defaultMessage={item.title}
                  />
                }
                description={
                  <FormattedMessage
                    id={`takeActionDescription__${item.id}`}
                    defaultMessage={item.description}
                  />
                }
              />
            ))}
          </div>
        </div>

        <div className="aboutUs__right">
          <div className="takeAction__img"></div>
          <h3>
            <FormattedMessage
              id="takeAction__1__2"
              defaultMessage={"Be a hero - volunteer"}
            />
          </h3>

          <Link to="">
            <FormattedMessage
              id="takeAction__1__3"
              defaultMessage={"Join now"}
            />
            <span>
              <FaArrowRightLong />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TakeAction;
