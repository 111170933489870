import React from "react";
import "./Home.scss";
import Navbar from "../../components/shared/Navbar/Navbar";
import Footer from "../../components/shared/Footer/Footer";
import Page1 from "../../components/Home/Page1/Page1";
import Page2 from "../../components/Home/Page2/Page2";
import Page3 from "../../components/Home/Page3/Page3";
import Page4 from "../../components/Home/Page4/Page4";
import Page5 from "../../components/Home/Page5/Page5";
import Page6 from "../../components/Home/Page6/Page6";
import Page7 from "../../components/Home/Page7/Page7";
import Page8 from "../../components/Home/Page8/Page8";
import Page9 from "../../components/Home/Page9/Page9";
import Page10 from "../../components/Home/Page10/Page10";
import Page11 from "../../components/Home/Page11/Page11";
import Page12 from "../../components/Home/Page12/Page12";
import Page13 from "../../components/Home/Page13/Page13";

const Home = () => {
  return (
    <div className="Home">
      <Navbar />

      <Page1 />
      <Page2 />
      <Page3 />
      <Page4 />
      <Page5 />
      <Page6 />
      <Page7 />
      <Page8 />
      <Page9 />
      <Page10 />
      <Page11 />
      <Page12 />
      {/* <Page13 /> */}

      <Footer />
    </div>
  );
};

export default Home;
