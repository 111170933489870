import React from "react";
import "./NavbarButtons.scss";

const NavbarButtons = ({ bgImage, title, description }) => {
  const bgImageDiv = {
    content: `url(${bgImage})`,
  };

  return (
    <div className="NavbarButtons">
      <div className="navbarButtons__left">
        <div className="navbarButtons__left__bg" style={bgImageDiv}></div>
      </div>

      <div className="navbarButtons__right">
        <h3 className="top">{title}</h3>

        <p className="bottom">{description}</p>
      </div>
    </div>
  );
};

export default NavbarButtons;
