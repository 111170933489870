import React, { useEffect, useRef, useState } from 'react'
import './Page12.scss'
import Page12Card from './Page12Card/Page12Card'
import axios from 'axios'
import Carousel from 'react-elastic-carousel'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import AOS from 'aos';
import "aos/dist/aos.css";


const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width:650, itemsToShow: 2 },
    { width:1200, itemsToShow: 3 },
];
  

const Page12 = () => {
    const [pageData, setPageData] = useState([])

    useEffect(() => {
      AOS.init();
    }, [])

    useEffect(() => {
      axios
      .get(`${process.env.REACT_APP_API_ROUTE}Blog/Blog.json`)
      .then(res => {
        setPageData(res.data)
      })
      .catch(error => {
          console.error("Blog : ", error)
      })
    }, [])
    
    const carouselRef = useRef(null);
    const onNextStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
          carouselRef.current.goTo(0);
        }
    };
    const onPrevStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
          carouselRef.current.goTo('single-slide'.length);
        }
    };

  return (
    <div className='Page12'>
        <h1 data-aos="fade-right">
        <FormattedMessage id="page12__1" defaultMessage={"Read our blog"} />
        </h1>

        <div className="carouselPage12">
            <Carousel className="slider styled-arrows" 
                breakPoints={breakPoints}
                ref={carouselRef}
                onPrevStart={onPrevStart}
                onNextStart={onNextStart}
                disableArrowsOnEnd={false}
            >   
               {
                pageData.map((item) => (
                  <Link 
                  className='hoverA'
                    to={
                      { 
                        pathname: `/blog/${item.id}/${item.title.replace(/ /g, "_")}`, 
                        state: { blogData: item } 
                      }
                    } 
                    key={item.id}
                  >
                    <Page12Card 
                      key={item.key}
                      bgImage={`${process.env.REACT_APP_BLOG_IMAGE}${item.bgImage}`}
                      data={item.data}
                      title={
                        <FormattedMessage id={`page12Title__${item.id}`} defaultMessage={item.title} />
                      }
                      description={
                        <FormattedMessage id={`page12Description__${item.id}`} defaultMessage={item.description} />
                      }
                      buttonText={item.buttonText}
                    />
                  </Link>
                ))
               }

            </Carousel>
        </div>

        <Link to="/blog" className='page12Explore' data-aos="fade-right">
          <FormattedMessage id="page12__2" defaultMessage={"View all"} />
        </Link>

    </div>
  )
}

export default Page12