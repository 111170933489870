import React from "react";
import "./MobileCard.scss";

const MobileCard = ({ bgImage, titleMobile }) => {
  const bgImageDiv = {
    content: `url(${bgImage})`,
  };

  return (
    <div className="MobileCard">
      <div className="mobileCard__left" style={bgImageDiv}></div>

      <p className="mobileCard__right">{titleMobile}</p>
    </div>
  );
};

export default MobileCard;
