import React from "react";
import "./NavbarCard.scss";
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { FormattedMessage } from "react-intl";

const NavbarCard = ({ bgImage, title, description }) => {
  const bgImageDiv = {
    backgroundImage: `url(${bgImage})`,
  };

  return (
    <div className="NavbarCard">
      <div className="navbarCard__top" style={bgImageDiv}></div>

      <div className="navbarCard__bottom">
        <h3>{title}</h3>

        <p>{description}</p>

        <Link to="">
          <FormattedMessage
            id="navbarCard__1__1"
            defaultMessage={"Learn more"}
          />
          <span>
            <FaArrowRightLong />
          </span>
        </Link>
      </div>
    </div>
  );
};

export default NavbarCard;
