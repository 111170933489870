import React from 'react'
import './Page6Card.scss'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const Page6Card = ({ bgImage, months, title, description, offerdBy }) => {
    const bgImageDiv = {
        backgroundImage: `url(${bgImage})`
    }

    const offerdByDiv = {
        content: `url(${offerdBy})`
    }


  return (
    <div className='Page6Card'>

        <div className="page6Card__top" style={bgImageDiv}>

        </div>

        <div className="page6Card__bottom">
            <p className='page6Card__months'>
                <span className='page6Card__smallIcon1'></span>
                {months}&nbsp;
                <FormattedMessage id='page6Card__1' defaultMessage={"months"} />
            </p>

            <div className='page6Card__text'>
                <h2>
                {title}
                </h2>

                <p>
                {description}
                </p>
            </div>

            <div className="page6Card__footer">
                <div className="offerd">
                    <p>
                        <FormattedMessage id='page6Card__2' defaultMessage={"Course offered by:"} />
                    </p>
                    <div className="offerdBy" style={offerdByDiv}></div>
                </div>

                <Link to="">
                <FormattedMessage id='page6Card__3' defaultMessage={"Apply now"} />
                </Link>

            </div>

        </div>

    </div>
  )
}

export default Page6Card