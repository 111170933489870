import React, { useEffect, useState } from "react";
import "./Page1.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { FormattedMessage } from "react-intl";
import { IoVideocamOutline } from "react-icons/io5";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import { createBrowserHistory } from "history";

const Page1 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [selectedContent, setSelectedContent] = useState("");
  const [selectedClassName, setSelectedClassName] = useState("");

  const handleMidClick = (content, className) => {
    setSelectedContent(content);
    setSelectedClassName(className);
  };

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const mid1Element = document.getElementById("mid0");
    if (mid1Element) {
      mid1Element.click();
    }
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerm1, setSearchTerm1] = useState("");
  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    axios
      .get("../../data/BottomEducation/BottomEducation.json")
      .then((res) => {
        setPageData(res.data);
      })
      .catch((error) => {
        console.error("Page1: ", error);
      });
  }, []);

  const handleSearch = () => {
    const queryString = `?category=${selectedContent}&location=${searchTerm}&name=${searchTerm1}`;
    navigate(`/education${queryString}`);
  };

  const handleCitySelect = (city) => {
    setSearchTerm(city);
  };

  const filteredCities = [
    "Deçan", "Dragash", "Drenas", "Ferizaj", "Fushë Kosovë", "Gjakovë", "Gjilan",
    "Istog", "Kaçanik", "Kamenicë", "Klinë", "Lipjan", "Malishevë", "Mitrovicë",
    "Obiliq", "Pejë", "Podujevë", "Prishtinë", "Prizren", "Rahovec", "Skenderaj",
    "Suharekë", "Shtërpcë", "Shtime", "Viti", "Vushtrri",
  ];

  const filteredData1 = pageData.filter((item) =>
    item.name.toLowerCase().includes(searchTerm1.toLowerCase())
  );


  return (
    <div className="Page1">
      <div className="page1Filter"></div>

      <div className="overPage1">
        <h1 data-aos="flip-left">
          <FormattedMessage
            id="page1__1"
            defaultMessage={
              "Advocating for high academic achievements and education enhancement"
            }
          />
        </h1>

        <p data-aos="flip-right">
          <FormattedMessage
            id="page1__2"
            defaultMessage={
              "The biggest education network in Kosovo. Explore schools, universities, teachers, education institutions, fundraisers, campaigns and much more. Everything in one place."
            }
          />
        </p>

        <div className="page1__buttons" data-aos="flip-up">
          <Link to="" className="page1__buttonOrange">
            <FormattedMessage id="page1__3" defaultMessage={"Get started"} />
          </Link>

          <Link to="" className="page1__buttons2">
            <span className="recordIcon">
              <IoVideocamOutline />
            </span>
            <FormattedMessage id="page1__4" defaultMessage={"Watch Video"} />
          </Link>
        </div>
      </div>

      <div className="page1__group">
        <div className="inside__page1__group" data-aos="flip-down">
            <div className="page1__inputs page1__input__1">
              <div className="page1__inputs">
                <span className="page1__search"></span>
                <input
                  type="search"
                  placeholder="Search for schools, teachers and more"
                  value={searchTerm1}
                  onChange={(e) => setSearchTerm1(e.target.value)}
                />
              </div>
              {/* {searchTerm1 && (
                <div className="filteredData">
                  {filteredData1.map((item) => (
                    <Link
                      key={item.id}
                      to={{
                        pathname: `/education/${item.id}/${item.name.replace(/ /g, "_")}`,
                        state: { educationData: item },
                      }}
                    >
                      <h2>{item.name}</h2>
                    </Link>
                  ))}
                </div>
              )} */}
            </div>

          <div className="page1__inputs page1__input__2">
            <div className="onClickDisplay">
              <span className={`${selectedClassName}`}>{selectedContent}</span>
              <p>{selectedContent}</p>
            </div>

            <div className="page1DropdownMid">
              <div
                  className="page1Mid1"
                  id="mid0"
                  onClick={() => handleMidClick("All", "midIcon1")}
                >
                  <span className="midIcon1"></span>
                  <p>
                    <FormattedMessage
                      id="page1__5__4"
                      defaultMessage={"All"}
                    />
                  </p>
              </div>
                      
              <div
                className="page1Mid1"
                id="mid1"
                onClick={() => handleMidClick("Academies", "midIcon1")}
              >
                <span className="midIcon1"></span>
                <p>
                  <FormattedMessage
                    id="page1__5"
                    defaultMessage={"Academies"}
                  />
                </p>
              </div>

              <div
                className="page1Mid1"
                id="mid2"
                onClick={() => handleMidClick("Pre-schools", "midIcon2")}
              >
                <span className="midIcon2"></span>
                <p>
                  <FormattedMessage
                    id="page1__6"
                    defaultMessage={"Pre-schools"}
                  />
                </p>
              </div>

              <div
                className="page1Mid1"
                id="mid3"
                onClick={() => handleMidClick("Elementary Schools", "midIcon3")}
              >
                <span className="midIcon3"></span>
                <p>
                  <FormattedMessage
                    id="page1__7"
                    defaultMessage={"Elementary Schools"}
                  />
                </p>
              </div>

              <div
                className="page1Mid1"
                id="mid4"
                onClick={() => handleMidClick("High Schools", "midIcon4")}
              >
                <span className="midIcon4"></span>
                <p>
                  <FormattedMessage
                    id="page1__8"
                    defaultMessage={"High Schools"}
                  />
                </p>
              </div>

              <div
                className="page1Mid1"
                id="mid5"
                onClick={() =>
                  handleMidClick("Universities & Colleges", "midIcon5")
                }
              >
                <span className="midIcon5"></span>
                <p>
                  <FormattedMessage
                    id="page1__9"
                    defaultMessage={"Universities & Colleges"}
                  />
                </p>
              </div>

              <div
                className="page1Mid1"
                id="mid6"
                onClick={() =>
                  handleMidClick("Private Institutions", "midIcon6")
                }
              >
                <span className="midIcon6"></span>
                <p>
                  <FormattedMessage
                    id="page1__10"
                    defaultMessage={"Private Institutions"}
                  />
                </p>
              </div>

              <div
                className="page1Mid1"
                id="mid7"
                onClick={() => handleMidClick("Daycare", "midIcon7")}
              >
                <span className="midIcon7"></span>
                <p>
                  <FormattedMessage id="page1__11" defaultMessage={"Daycare"} />
                </p>
              </div>

              <div
                className="page1Mid1"
                id="mid8"
                onClick={() => handleMidClick("Teachers", "midIcon8")}
              >
                <span className="midIcon8"></span>
                <p>
                  <FormattedMessage
                    id="page1__12"
                    defaultMessage={"Teachers"}
                  />
                </p>
              </div>

              <div
                className="page1Mid1"
                id="mid9"
                onClick={() => handleMidClick("Courses", "midIcon9")}
              >
                <span className="midIcon9"></span>
                <p>
                  <FormattedMessage id="page1__13" defaultMessage={"Courses"} />
                </p>
              </div>
            </div>
          </div>

          <div className="page1__inputs page1__input__3">
            <span className="page1__location"></span>
            <input
              type="text"
              placeholder="Location"
              value={searchTerm}
              onChange={handleSearch}
            />
            <ul>
              {filteredCities.map((city, index) => (
                <li key={index} onClick={() => handleCitySelect(city)}>
                  {city}
                </li>
              ))}
            </ul>
          </div>

          <button onClick={handleSearch}>
            <IoSearchOutline />
            <FormattedMessage id="page1__14" defaultMessage={"Search"} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Page1;
