import React, { useEffect, useRef, useState } from 'react'
import './Page10.scss'
import axios from 'axios';
import Page10Card from './Page10Card/Page10Card';
import Carousel from 'react-elastic-carousel'
import { FormattedMessage } from 'react-intl'
import AOS from 'aos';
import "aos/dist/aos.css";



const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width:550, itemsToShow: 1 },
    { width:1200, itemsToShow: 2 },
  ];
  

const Page10 = () => {
    const [pageData, setPageData] = useState([])

    useEffect(() => {
      AOS.init();
    }, [])

    useEffect(() => {
      axios
      .get(`${process.env.REACT_APP_API_ROUTE}WhatCommunitySays/WhatCommunitySays.json`)
      .then(res => {
        setPageData(res.data)
      })
      .catch(error => {
          console.error("WhatCommunitySays : ", error)
      })
    }, [])
    
    const carouselRef = useRef(null);
    const onNextStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
          carouselRef.current.goTo(0);
        }
    };
    const onPrevStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
          carouselRef.current.goTo('single-slide'.length);
        }
    };

    
  return (
    <div className='Page10'>
        <h1>
        <FormattedMessage id="page10__1" defaultMessage={"What community says"} />
        </h1>

        <div className="carouselPage10">
            <Carousel className="slider styled-arrows" 
                breakPoints={breakPoints}
                ref={carouselRef}
                onPrevStart={onPrevStart}
                onNextStart={onNextStart}
                disableArrowsOnEnd={false}
            >   
                {
                    pageData.map((item) => (
                        <Page10Card 
                            key={item.id}
                            bgImage={`${process.env.REACT_APP_WhatCommunitySays_IMAGE}${item.bgImage}`}
                            description={
                              <FormattedMessage id={`page10Description__${item.id}`} defaultMessage={item.description} />
                            }
                            name={item.name}
                            who={
                              <FormattedMessage id={`page10Who__${item.id}`} defaultMessage={item.who} />
                            }
                        />
                    ))
                }
            </Carousel>
        </div>

    </div>
  )
}

export default Page10