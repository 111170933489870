import React from 'react'
import './Page4Card.scss'
import { Link } from 'react-router-dom'
import { FaLocationDot } from "react-icons/fa6";
import { FormattedMessage } from 'react-intl'

const Page4Card = ({ yoStart, yoEnd, name, image, category, star, city }) => {

    const imageDiv = {
        backgroundImage: `url(${image})`
    }

  return (
    <div className='Page4Card'>
        <div className="page4Card__top" style={imageDiv}>
            <div className="page4Card__filter"></div>
            <div className="page4Card__yo">
                <div className="page4Card__users"></div>
                <p>
                    {yoStart}-{yoEnd} yo
                </p>
            </div>

            <div className="page4Card__circle">
                <div className="page4Card__star"></div>
                <p>
                    {star}
                </p>
            </div>

        </div>

        <div className="page4Card__bottom">

            <p className='mainColor'>
                {/* <FormattedMessage id='page4Card__1' defaultMessage={"Pre School:"} /> */}
                {category}
            <span className='whiteColor'> &nbsp;
            "{name}"
            </span>
            </p>

            <p>
            <FormattedMessage id='page4Card__2' defaultMessage={"Enroll your kids in the best pre-school institution now"} />
            </p>

            <div className="page4Card__footer">
                <p>
                    <FaLocationDot />
                    {city}, Kosova
                </p>

                <Link to="">
                
                    <FormattedMessage id='page4Card__3' defaultMessage={"Visit profile"} />
                </Link>
            </div>

        </div>


    </div>
  )
}

export default Page4Card