import React, { useEffect, useRef, useState } from 'react'
import './MobileNavbar.scss'
import { Link } from 'react-router-dom'
import { MdKeyboardArrowDown } from 'react-icons/md'
import AboutUsMobile from './AboutUsMobile/AboutUsMobile'
import WhatWeDoMobile from './WhatWeDoMobile/WhatWeDoMobile'
import EducationMobile from './EducationMobile/EducationMobile'
import ResourcesMobile from './ResourcesMobile/ResourcesMobile'
import TakeActionMobile from './TakeActionMobile/TakeActionMobile'
import { FormattedMessage } from 'react-intl'
import ThemeSwitcher from '../ThemeSwitcher/ThemeSwitcher'
import ColorSwitch from '../Footer/ColorSwitch'
import SelectLanguage from '../SelectLanguage/SelectLangugage'

const MobileNavbar = (props) => {
    const [openAbout, setOpenAbout] = useState(false)
    const [whatWeDo, setWhatWeDo] = useState(false)
    const [education, setEducation] = useState(false)
    const [resources, setResources] = useState(false)
    const [takeAction, setTakeAction] = useState(false)

    const [openMobile, setOpenMobile] = useState(false)

    const handleInsideClick = (e) => {
        e.stopPropagation();
      };

    const handleOpen = () => {
        props.setOpenMobile(props.openMobile);
    };

  return (
    <div className='MobileNavbar' onClick={handleOpen}> 
        <div className="insideMobileNavbar" onClick={handleInsideClick}>
            <div className="mobileNavbar__top">
                <div className="mobileLogo">
                    <div className="footer__logo">
                        <div className="onlyLogo">
                        <div className="hat"></div>
                        <div className="kosova"></div>
                        </div>
                        <p>
                            Kosova
                            <span>
                                Education
                            </span>
                        </p>
                    </div>
                </div>

                <div className="mobile__links mobile__links1"  >
                    <Link to="/aboutus" onClick={() => setOpenAbout(!openAbout)}>
                        <FormattedMessage id='mobileNavbar__1' defaultMessage={"About us"}/>
                        <MdKeyboardArrowDown className={`svgUp ${!openAbout ? "downSvg" : ""}`} />
                    </Link>

                    <div className={`mobile__mega ${!openAbout ? "closeMobileMega" : ""}`}>
                        <AboutUsMobile />
                    </div>
                </div>

                <div className="mobile__links">
                    <Link to="" onClick={() => setWhatWeDo(!whatWeDo)}>
                        <FormattedMessage id='mobileNavbar__2' defaultMessage={"What we do"}/>
                        <MdKeyboardArrowDown className={`svgUp ${!whatWeDo ? "downSvg" : ""}`} />
                    </Link>

                    <div className={`mobile__mega ${!whatWeDo ? "closeMobileMega" : ""}`}>
                        <WhatWeDoMobile />
                    </div>
                </div>

                <div className="mobile__links">
                    <Link to="" onClick={() => setEducation(!education)}>
                        <FormattedMessage id='mobileNavbar__3' defaultMessage={"Education"}/>
                        <MdKeyboardArrowDown className={`svgUp ${!education ? "downSvg" : ""}`} />
                    </Link>

                    <div className={`mobile__mega ${!education ? "closeMobileMega" : ""}`}>
                        <EducationMobile />
                    </div>
                </div>

                <div className="mobile__links">
                    <Link to="" onClick={() => setResources(!resources)}>
                        <FormattedMessage id='mobileNavbar__4' defaultMessage={"Resources"}/>
                        <MdKeyboardArrowDown className={`svgUp ${!resources ? "downSvg" : ""}`} />
                    </Link>

                    <div className={`mobile__mega ${!resources ? "closeMobileMega" : ""}`}>
                        <ResourcesMobile />
                    </div>
                </div>

                <div className="mobile__links">
                    <Link to="" onClick={() => setTakeAction(!takeAction)}>
                        <FormattedMessage id='mobileNavbar__5' defaultMessage={"Take action"}/>
                        <MdKeyboardArrowDown className={`svgUp ${!takeAction ? "downSvg" : ""}`} />
                    </Link>

                    <div className={`mobile__mega ${!takeAction ? "closeMobileMega" : ""}`}>
                        <TakeActionMobile />
                    </div>
                </div>

                <div className="mobileLogin">
                    <Link to='/login'>
                        <FormattedMessage id='navbar__9' defaultMessage={"Log in"}/>
                    </Link> 

                    <Link className='navbar__button' to='/register'>
                        <span id='createAccount'></span>
                        <FormattedMessage id='navbar__10' defaultMessage={"Create account"}/>
                    </Link> 
                </div>

                <div className="mobileColor">
                    <ColorSwitch />
                </div>

                <div className="mobileDarkMode">
                    <ThemeSwitcher />
                </div>

                {/* <div className="mobileLanguageSwitcher">
                    <SelectLanguage
                        setLanguage={props.setLanguage}
                        language={props.language}
                    />
                </div> */}


            </div>

           
        </div>
    </div>
  )
}

export default MobileNavbar