import React, { useEffect, useRef, useState } from 'react'
import './AboutUs.scss'
import NavbarButtons from '../../NavbarButtons/NavbarButtons'
import NavbarCard from '../../NavbarCard/NavbarCard';
import axios from 'axios';
import Carousel from 'react-elastic-carousel'
import { FormattedMessage } from 'react-intl';

import image1 from '../../../../assets/Aboutus/img1.webp'
import image2 from '../../../../assets/Aboutus/img2.webp'
import icon1 from '../../../../assets/Aboutus/icon1.svg'
import icon2 from '../../../../assets/Aboutus/icon2.svg'
import icon3 from '../../../../assets/Aboutus/icon3.svg'
import icon4 from '../../../../assets/Aboutus/icon4.svg'
import icon5 from '../../../../assets/Aboutus/icon5.svg'
import icon6 from '../../../../assets/Aboutus/icon6.svg'
import icon7 from '../../../../assets/Aboutus/icon7.svg'
import icon8 from '../../../../assets/Aboutus/icon8.svg'
import { Link } from 'react-router-dom';
import { FaArrowRightLong } from 'react-icons/fa6';

const breakPoints = [
    { width: 1, itemsToShow: 2 },
    { width:650, itemsToShow: 2 },
    { width:1200, itemsToShow: 2 },
];


const leftData = [
    {
      id: 1,
      title: "KosovaEducation",
      description: "Read our story",
      image: icon1,
    },
    {
      id: 2,
      title: "Institutions",
      description: "See all institutions you can join",
      image: icon2,
    },
    {
      id: 3,
      title: "Our NGO",
      description: "We care for the future",
      image: icon3,
    },
    {
      id: 4,
      title: "Our Values",
      description: "What we value the most?",
      image: icon4,
    },
    {
      id: 5,
      title: "Our Practice Areas",
      description: "See what we do and help",
      image: icon5,
    },
    {
      id: 6,
      title: "Contacts",
      description: "Contact us now and get help",
      image: icon6,
    },
    {
      id: 7,
      title: "Leadership",
      description: "Meet our team",
      image: icon7,
    },
    {
      id: 8,
      title: "Community",
      description: "Join our community to help",
      image: icon8,
    },
  ];

    const rightData = [
        {
            id__right: 1,
            title__right: "KosovaEducation - how we got here",
            image__right: image1,
        },
        {
            id__right: 2,
            title__right: "Get to know us better. How we work?",
            image__right: image2,
        },
        {
            id__right: 3,
            title__right: "KosovaEducation - how we got here",
            image__right: image1,
            },
            {
            id__right: 4,
            title__right: "Get to know us better. How we work?",
            image__right: image2,
            },
    ];


const AboutUs = () => {
    const carouselRef = useRef(null);
    const onNextStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
          carouselRef.current.goTo(0);
        }
    };
    const onPrevStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
          carouselRef.current.goTo('single-slide'.length);
        }
    };

  return (
    <div className='AboutUs'>

        <h3>
            <FormattedMessage id='aboutUs__1__1' defaultMessage={"About us"} />
        </h3>

        <div className="insideAbout">
            <div className="aboutUs__left">
                <div className="inside__about">
                    
                    {
                        leftData.map((item) => (
                            <NavbarButtons 
                                key={item.id}
                                bgImage={item.image}
                                description={item.description}
                                title={item.title}
                            />
                        ))
                    }
                </div>

            </div>


            <div className="aboutUs__right">
                <div className="carouselAboutUs">
                    <Carousel className="slider styled-arrows" 
                        breakPoints={breakPoints}
                        ref={carouselRef}
                        onPrevStart={onPrevStart}
                        onNextStart={onNextStart}
                        disableArrowsOnEnd={false}
                    >   
                    {
                        rightData.map((item) => (
                            <NavbarCard 
                                key={item.id__right}
                                bgImage={item.image__right}
                                title={item.title__right}
                            />
                        ))
                    }
                    </Carousel>
                </div>
            </div>
        </div>

        


    </div>
  )
}

export default AboutUs