import React, { useState } from "react";
import Navbar from "../../components/shared/Navbar/Navbar";
import Footer from "../../components/shared/Footer/Footer";
import "./Register.scss";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const Register = () => {
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleshow1 = () => {
    setShow1(!show1);
  };

  const handleshow2 = () => {
    setShow2(!show2);
  };

  return (
    <div className="Register">
      <Navbar />

      <div className="RegisterPage">
        <div className="register__left">
          <div className="form">
            <h1>
              <FormattedMessage
                id="registerPage__1__2"
                defaultMessage={"Sign Up"}
              />
            </h1>

            <div className="buttonForLoginAndSignUp">
              <Link to="/login">Log In</Link>

              <Link to="/register">Sign Up</Link>
            </div>

            <div className="fullname">
              <div className="inputForm">
                <label>
                  <FormattedMessage
                    id="registerPage__4__2"
                    defaultMessage={"Name"}
                  />
                </label>
                <div className="inputIcon">
                  <span className="smallIcon avatar"></span>
                  <input type="text" placeholder="Write name here" />
                </div>
              </div>

              <div className="inputForm">
                <label>
                  <FormattedMessage
                    id="registerPage__5__2"
                    defaultMessage={"Last Name "}
                  />
                </label>
                <div className="inputIcon">
                  <span className="smallIcon avatar"></span>
                  <input type="text" placeholder="Write last name here" />
                </div>
              </div>
            </div>

            <div className="inputForm">
              <label>Email</label>
              <div className="inputIcon">
                <span className="smallIcon email"></span>
                <input type="email" placeholder="Write email here" />
              </div>
            </div>

            <div className="fullname">
              <div className="inputForm">
                <label>
                  <FormattedMessage
                    id="registerPage__6"
                    defaultMessage={"Password"}
                  />
                </label>
                <div className="inputIcon">
                  <span className="smallIcon password"></span>
                  <input
                    type={show1 ? "text" : "password"}
                    placeholder="Enter your password"
                  />
                  <span
                    className="smallIcon passwordLast"
                    onClick={handleshow1}
                  ></span>
                </div>
              </div>

              <div className="inputForm">
                <label>
                  <FormattedMessage
                    id="registerPage__7"
                    defaultMessage={"Confirm password"}
                  />
                </label>
                <div className="inputIcon">
                  <span className="smallIcon password"></span>
                  <input
                    type={show2 ? "text" : "password"}
                    placeholder="Enter your password"
                  />
                  <span
                    className="smallIcon passwordLast"
                    onClick={handleshow2}
                  ></span>
                </div>
              </div>
            </div>

            <div className="checkboxInput">
              <input type="checkbox" id="loginCheckbox" />
              <label htmlFor="loginCheckbox">
                <p>
                  <FormattedMessage
                    id="register__3__1"
                    defaultMessage={"I agree to our "}
                  />
                  <span className="changeColor">
                    <FormattedMessage
                      id="register__3__3"
                      defaultMessage={"User Agreement "}
                    />
                  </span>
                  <FormattedMessage
                    id="register__3__4"
                    defaultMessage={" and "}
                  />
                  <span className="changeColor">
                    <FormattedMessage
                      id="register__3__5"
                      defaultMessage={" Privacy Policy"}
                    />
                  </span>
                </p>

                <Link to="" className="pink registerSpace privacy">
                  <FormattedMessage
                    id="register__3__2"
                    defaultMessage={"Suggest password?"}
                  />
                </Link>
              </label>
            </div>

            <div className="loginButtons">
              <button className="onHoverButton">
                <FormattedMessage
                  id="legisterPage2__2"
                  defaultMessage={"Sign Up"}
                />
              </button>
              <div>
                <Link to="" className="dontHave">
                  <FormattedMessage
                    id="register__2__1"
                    defaultMessage={"Already have an account? "}
                  />
                  <span>
                    <FormattedMessage
                      id="register__2__2"
                      defaultMessage={"Sign Up"}
                    />
                  </span>
                </Link>
              </div>

              <p>
                <FormattedMessage
                  id="LoginPage__16__1"
                  defaultMessage={"Or continue with"}
                />
              </p>

              <div className="loginWithOther">
                <button className="onHoverButton withGoogle">
                  <span className="google"></span>
                  <FormattedMessage
                    id="LoginPage__12__1"
                    defaultMessage={"Continue with "}
                  />{" "}
                  google
                </button>

                <button className="onHoverButton withGoogle">
                  <span className="google apple"></span>
                  <FormattedMessage
                    id="LoginPage__12__2"
                    defaultMessage={"Continue with "}
                  />{" "}
                  Apple
                </button>
              </div>
            </div>
          </div>

          <div className="registerBgTriangle"></div>
        </div>

        <div className="register__right">
          <div className="register__right__filter"></div>

          <div className="inside__register">
            <h1>
              <FormattedMessage
                id="registerPage__13"
                defaultMessage={
                  "Find all information related to education in Kosovo in one single platform."
                }
              />
            </h1>

            <p>
              <FormattedMessage
                id="registerPage__14"
                defaultMessage={
                  "The KosovaEducation platform serves as a comprehensive informational portal designed to facilitate access to a wide array of educational resources within Kosovo. This platform is adeptly structured to provide detailed insights into various educational institutions, extensive profiles of educators, a diverse range of courses, and additional relevant information. "
                }
              />
            </p>

            <div className="footer__logo">
              <div className="onlyLogo">
                <div className="hat"></div>
                <div className="kosova"></div>
              </div>
              <p>
                Kosova
                <span>Education</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Register;
