import React from 'react'
import { Link } from 'react-router-dom'
import { FaArrowRightLong } from "react-icons/fa6";
import './Page5Card.scss'
import { FormattedMessage } from 'react-intl';

const Page5Card = ({ bgImage, title, description }) => {
    const bgImageDiv = {
        backgroundImage: `url(${bgImage})`
    }
  return (
    <div className='Page5Card'>

        <div className="page5Card__icon" style={bgImageDiv}>
            <div className="page5Card__icon__filter"></div>
        </div>

        <div className="page5Text">
            <h2 className="page5Card__title">
            {title}
            </h2>

            <p className="page5Card__description">
            {description}
            </p>
        </div>
        
        <Link to="">
            <FormattedMessage id='navbarCard__1__1' defaultMessage={"Learn more"} />
            <span>
                <FaArrowRightLong />
            </span>
        </Link>

    </div>
  )
}

export default Page5Card