import React, { useEffect, useState } from 'react'
import './PraticeAreas.scss'
import Navbar from '../../components/shared/Navbar/Navbar'
import Footer from '../../components/shared/Footer/Footer'
import axios from 'axios'
import Page5Card from '../../components/Home/Page5/Page5Card/Page5Card'
import { FormattedMessage } from 'react-intl'

const PraticeAreas = () => {
    const [pageData, setPageData] = useState([])

    useEffect(() => {
  
      axios
      .get(`${process.env.REACT_APP_API_ROUTE}OurPracticeAreasPage/OurPracticeAreasPage.json`)
      .then(res => {
        setPageData(res.data)
      })
      .catch(error => {
          console.error("OurPracticeAreasPage : ", error)
      })
  
    }, [])

  return (
    <div className='PraticeAreas'>
        <Navbar />
            
            <div className="PraticeAreas__inside">
                <div className="PraticeAreas__1 ">
                    <div className="PraticeAreas__1__filter"></div>
                    <h1>
                    Our practice areas
                    </h1>
                </div>

                <div className="PraticeAreas__2">
                    <div className="page5__cards" >
                        {
                            pageData.map((item) => (
                                <Page5Card 
                                    key={item.id}
                                    bgImage={`${process.env.REACT_APP_OurPracticesAreas_IMAGE}${item.bgImage}`}
                                    title={
                                    <FormattedMessage id={`page5Title__${item.id}`} defaultMessage={item.title} />
                                    }
                                    description={
                                    <FormattedMessage id={`page5Description__${item.id}`} defaultMessage={item.description} />
                                    }
                                />
                            ))
                        }
                    </div>
                </div>




                <div className="PraticeAreas__3">
                    <div className="left">
                        <div className="image1"></div>
                        <div className="onlyCircle"></div>
                    </div>

                    <div className="right">
                        <h1 className="aboutTitle">
                        Our vision for the future
                        </h1>
                        <p className="aboutText">
                        As we expand our horizons, KosovaEducation remains committed to being a dynamic and responsive organization, continually adapting to the evolving educational landscape. We envision a future where every individual in Kosovo has access to a well-rounded, inclusive, and high-quality education. Through these additional practice areas, we aim to address the diverse needs of our community and prepare our students for the challenges and opportunities of the 21st century.
                        </p>
                    </div>
                </div>


            </div>

        <Footer />
    </div>
  )
}

export default PraticeAreas