import React, { useEffect, useState } from 'react'
import './Page5.scss'
import { Link } from 'react-router-dom'
import Page5Card from './Page5Card/Page5Card'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import AOS from 'aos';
import "aos/dist/aos.css";


const Page5 = () => {
    const [pageData, setPageData] = useState([])

    useEffect(() => {
  
      axios
      .get(`${process.env.REACT_APP_API_ROUTE}OurPracticesAreas/OurPracticesAreas.json`)
      .then(res => {
        setPageData(res.data)
      })
      .catch(error => {
          console.error("Page5 : ", error)
      })
  
    }, [])

    useEffect(() => {
      AOS.init();
    }, [])

  return (
    <div className='Page5'>
        <div className="page5__filter"></div>

        <div className="page5__top">
            <h1 data-aos="fade-right">
            <FormattedMessage id="page5__1" defaultMessage={"Our practices areas"} />
            </h1>

            <Link to="/praticearea" data-aos="fade-down">
              <FormattedMessage id="page5__2" defaultMessage={"View all"} />
            </Link>
        </div>

        <div className="page5__cards" data-aos="fade-left">
            {
                pageData.map((item) => (
                    <Page5Card 
                        key={item.id}
                        bgImage={`${process.env.REACT_APP_OurPracticesAreas_IMAGE}${item.bgImage}`}
                        title={
                          <FormattedMessage id={`page5Title__${item.id}`} defaultMessage={item.title} />
                        }
                        description={
                          <FormattedMessage id={`page5Description__${item.id}`} defaultMessage={item.description} />
                        }
                    />
                ))
            }
        </div>



    </div>
  )
}

export default Page5