import React, { useEffect } from 'react'
import './Page2.scss'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import AOS from 'aos';
import "aos/dist/aos.css";


const Page2 = () => {
  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div className='Page2'> 
        
        <div className="page2__left" data-aos="fade-right">
            <div className="page2__logo" data-aos="fade-down">
              <div className="footer__logo">
                    <div className="onlyLogo">
                      <div className="hat"></div>
                      <div className="kosova"></div>
                    </div>
                    <p>
                        Kosova
                        <span>
                            Education
                        </span>
                    </p>
                </div>
            </div>
            <h1>
            <FormattedMessage id='page2__1' defaultMessage={"We uphold education and learning"}/>
            </h1>

            <p>
            <FormattedMessage id='page2__2' defaultMessage={"We uphold education and learning as essential to the development of all people and societies. We also recognize that children are our hope for the future. Therefore, we must attend to children’s education, health, well-being, and safety so they can learn, grow, and develop optimally."}/>
            </p>

            <Link to="" data-aos="fade-up">
            
            <FormattedMessage id='page2__3' defaultMessage={"Learn more"}/>
            </Link>

        </div>

        <div className="page2__right">

          <div className="page2__bg__main"></div>
          <div className="page2__bg__1"></div>
          <div className="page2__bg__2"></div>
          <div className="page2__bg__3"></div>

          <div className="page2__bg__4">
              <div className="page2__bg__4__2"></div>
          </div>


        </div>


        
    </div>
  )
}

export default Page2