import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import ColorSwitch from "./ColorSwitch";
import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";
import SelectLanguage from "../SelectLanguage/SelectLangugage";

import { RiFacebookBoxFill } from "react-icons/ri";
import { FaInstagramSquare } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FormattedMessage } from "react-intl";

const Footer = (props) => {
  return (
    <div className="Footer">
      <div className="footer__1">
        <div className="footer__1__logo"></div>

        <p>
          <FormattedMessage
            id="footer__1"
            defaultMessage={
              "Subscribe to receive latest inspiring news in your inbox"
            }
          />
        </p>

        <div className="footer__1__email">
          <div className="insideEmail">
            <span className="footer__1__email__icon"></span>
            <input type="text" placeholder="Write your email here" />
          </div>
          <button>
            <FormattedMessage id="footer__2" defaultMessage={"Subscribe"} />
          </button>
        </div>
      </div>

      <div className="footer__2">
        <div className="footer__links">
          <h2>
            <FormattedMessage id="footer__3" defaultMessage={"About"} />
          </h2>

          <Link to="">
            <FormattedMessage id="footer__4" defaultMessage={"About us"} />
          </Link>

          <Link to="">
            <FormattedMessage id="footer__5" defaultMessage={"Supporters"} />
          </Link>

          <Link to="">
            <FormattedMessage id="footer__6" defaultMessage={"Our Team"} />
          </Link>

          <Link to="">
            <FormattedMessage
              id="footer__7"
              defaultMessage={"Board of members"}
            />
          </Link>

          <Link to="">
            <FormattedMessage id="footer__8" defaultMessage={"Our values"} />
          </Link>

          <Link to="">
            <FormattedMessage id="footer__9" defaultMessage={"How we work"} />
          </Link>

          <Link to="">
            <FormattedMessage id="footer__10" defaultMessage={"Contact us"} />
          </Link>
        </div>

        <div className="footer__links">
          <h2>
            <FormattedMessage id="footer__11" defaultMessage={"What we do"} />
          </h2>

          <Link to="">
            <FormattedMessage id="footer__12" defaultMessage={"Education"} />
          </Link>

          <Link to="">
            <FormattedMessage id="footer__13" defaultMessage={"Innovation"} />
          </Link>

          <Link to="">
            <FormattedMessage id="footer__14" defaultMessage={"Advocating"} />
          </Link>

          <Link to="">
            <FormattedMessage
              id="footer__15"
              defaultMessage={"Youth empowerment"}
            />
          </Link>

          <Link to="">
            <FormattedMessage
              id="footer__16"
              defaultMessage={"Education enhancement"}
            />
          </Link>

          <Link to="">
            <FormattedMessage
              id="footer__17"
              defaultMessage={"Early childhood"}
            />
          </Link>

          <Link to="">
            <FormattedMessage
              id="footer__18"
              defaultMessage={"Skills and work"}
            />
          </Link>
        </div>

        <div className="footer__links">
          <h2>
            <FormattedMessage id="footer__19" defaultMessage={"platform"} />
          </h2>

          <Link to="">
            <FormattedMessage id="footer__20" defaultMessage={"Projects"} />
          </Link>

          <Link to="">
            <FormattedMessage id="footer__21" defaultMessage={"Schools"} />
          </Link>

          <Link to="">
            <FormattedMessage id="footer__22" defaultMessage={"Academies"} />
          </Link>

          <Link to="">
            <FormattedMessage
              id="footer__23"
              defaultMessage={"Learning Center"}
            />
          </Link>

          <Link to="">
            <FormattedMessage id="footer__24" defaultMessage={"Courses"} />
          </Link>

          <Link to="">
            <FormattedMessage id="footer__25" defaultMessage={"Library"} />
          </Link>

          <Link to="">
            <FormattedMessage id="footer__26" defaultMessage={"Learning App"} />
          </Link>
        </div>

        <div className="footer__links">
          <h2>
            <FormattedMessage id="footer__27" defaultMessage={"Other"} />
          </h2>

          <Link to="">
            <FormattedMessage id="footer__28" defaultMessage={"Resources"} />
          </Link>

          <Link to="">
            <FormattedMessage id="footer__29" defaultMessage={"Get Involved"} />
          </Link>

          <Link to="">
            <FormattedMessage id="footer__30" defaultMessage={"Donate"} />
          </Link>

          <Link to="">
            <FormattedMessage id="footer__31" defaultMessage={"Sponsor"} />
          </Link>

          <Link to="">
            <FormattedMessage id="footer__32" defaultMessage={"Blog"} />
          </Link>

          <Link to="">
            <FormattedMessage id="footer__33" defaultMessage={"Support"} />
          </Link>

          <Link to="">
            <FormattedMessage id="footer__34" defaultMessage={"Shop"} />
          </Link>
        </div>

        <div className="footer__group">
          <div className="footer__logo">
            <div className="onlyLogo">
              <div className="hat"></div>
              <div className="kosova"></div>
            </div>
            <p>
              Kosova
              <span>Education</span>
            </p>
          </div>

          <p className="p80width">
            <FormattedMessage
              id="footer__35"
              defaultMessage={
                "Working together towards a better future for the children of Kosovo."
              }
            />
          </p>

          <div className="footer__buttons__group">
            <Link to="" className="footerButton orangeButton">
              <FormattedMessage id="footer__36" defaultMessage={"Donate"} />
            </Link>

            <Link to="" className="footerButton orange">
              <FormattedMessage id="footer__37" defaultMessage={"Sponsor"} />
            </Link>
          </div>

          <div className="footerSocial">
            <p>
              <FormattedMessage
                id="footer__38"
                defaultMessage={"Connect with us:"}
              />
            </p>

            <Link to="https://www.facebook.com/kosovaeducationcom">
              <span className="facebook">
                <RiFacebookBoxFill />
              </span>
            </Link>

            <Link to="https://www.instagram.com/kosovaedu/">
              <span className="instagram">
                <FaInstagramSquare />
              </span>
            </Link>

            <Link to="">
              <span className="twitter">
                <FaTwitterSquare />
              </span>
            </Link>

            <Link to="">
              <span className="linkedin">
                <FaLinkedin />
              </span>
            </Link>
          </div>

          <div className="footerContact">
            <span className="contactIcon"></span>
            <p>
              <FormattedMessage id="footer__39" defaultMessage={"Contacts:"} />
            </p>
            <span>+383 (44) 0000000</span>
          </div>

          <div className="footerContact">
            <span className="emailIcon"></span>
            <p>Email:</p>
            <span>info@kosovaeducation.com</span>
          </div>
        </div>
      </div>

      <div className="footer__4">
        <p>
          <FormattedMessage
            id="footer__40"
            defaultMessage={"Download our Learning App for free"}
          />
        </p>

        <div className="downloadAt">
          <Link to="https://store.google.com/regionpicker" target="_blank">
            <span className="googlePlay"></span>
          </Link>

          <Link to="https://www.apple.com/" target="_blank">
            <span className="appStore"></span>
          </Link>
        </div>

        <ColorSwitch />
      </div>

      <div className="footer__3">
        <p className="footer__3__1">
          &copy; 2024 KosovaEducation.
          <FormattedMessage
            id="footer__41"
            defaultMessage={"All Rights Reserved"}
          />
        </p>

        <div className="footer__3__2">
          <Link to="">
            <FormattedMessage
              id="footer__42"
              defaultMessage={"Terms & Conditions"}
            />
          </Link>

          <Link to="">
            <FormattedMessage id="footer__43" defaultMessage={"Privacy"} />
          </Link>

          <Link to="">
            <FormattedMessage id="footer__44" defaultMessage={"Cookies"} />
          </Link>

          <Link to="">FAQs</Link>
        </div>

        <div className="footer__3__3">
          <ThemeSwitcher />

          <SelectLanguage
            setLanguage={props.setLanguage}
            language={props.language}
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
