import React, { useEffect, useState } from 'react'
import './BottomActiveProejcts.scss'
import { IoIosArrowDown, IoIosArrowDropdown } from "react-icons/io";
import { BiMenuAltLeft } from "react-icons/bi";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { FaList } from "react-icons/fa";
import axios from 'axios';
import Page4Card from '../../Home/Page4/Page4Card/Page4Card';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl'
import { IoMdCloseCircleOutline } from "react-icons/io";
import Page8Card from '../../Home/Page8/Page8Card/Page8Card';

const BottomActiveProejcts = () => {

    const [pageData, setPageData] = useState([])
    const [inGrid, inLine] = useState(false)
    const [perPageNumber ,setPerPageNumber] = useState(6)
    const [openLocation, setOpenLocation] = useState(false)
    const locationData = [
        "Deçan",
        "Dragash",
        "Drenas",
        "Ferizaj",
        "Fushë Kosovë",
        "Gjakovë",
        "Gjilan",
        "Istog",
        "Kaçanik",
        "Kamenicë",
        "Klinë",
        "Lipjan",
        "Malishevë",
        "Mitrovicë",
        "Obiliq",
        "Pejë",
        "Podujevë",
        "Prishtinë",
        "Prizren",
        "Rahovec",
        "Skenderaj",
        "Suharekë",
        "Shtërpcë",
        "Shtime",
        "Viti",
        "Vushtrri",
      ];

    useEffect(() => {
      axios.get(`${process.env.REACT_APP_API_ROUTE}ActiveProjects/ActiveProjects.json`)
      .then(res => {
        setPageData(res.data)
      })
      .catch(error => {
          console.error("ActiveProjects : ", error)
      })
    }, [])

    const handeGrid = () => {
        inLine(!inGrid)
    }

    const [rangeNumber, setRangeNumber] = useState(0);
    const handleRangeChange = (event) => {
      setRangeNumber(event.target.value);
    };

    const [selectedCategory, setSelectedCategory] = useState(["All"]);
    const handleCategorySelect = (category) => {
        if(category) {
            const updatedSelectedCategory = selectedCategory.includes(category)
            ? selectedCategory.filter((selectedCategory) => selectedCategory !== category)
            : [...selectedCategory, category];
            setSelectedCategory(updatedSelectedCategory);
          } else {
            setSelectedCategory([]);
          }
    };
      
    const [selectedLocation, setSelectedLocation] = useState([]);
    const handleLocationSelect = (city) => {
      if(city) {
        const updatedSelectedLocation = selectedLocation.includes(city)
        ? selectedLocation.filter((selectedLocation) => selectedLocation !== city)
        : [...selectedLocation, city];
        setSelectedLocation(updatedSelectedLocation);
      } else {
        setSelectedLocation([]);
      }
    };


    const filteredData = pageData.filter(
    (item) =>
    // (selectedCategory.includes("All") || item.smallText === selectedCategory) 
    // &&
    (selectedCategory.includes("All") || selectedCategory.includes(item.smallText))
    &&
    (selectedLocation.length === 0 || selectedLocation.includes(item.city))
    
    );


    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = perPageNumber;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = pageData.slice(firstIndex, lastIndex);
    const npage = Math.ceil(filteredData.length / recordsPerPage);

    function prePage() {
        if (currentPage !== 1) {
        setCurrentPage(currentPage - 1);
        }
    }

    function changeCPage(id) {
        setCurrentPage(id);
    }

    function nextPage() {
        if (currentPage !== npage) {
        setCurrentPage(currentPage + 1);
        }
    }

    const numbersToShow = 3;
    const totalPages = npage;

    const getPageNumbers = () => {
        const midNumber = Math.floor(numbersToShow / 2);
        let startPage = Math.max(1, currentPage - midNumber);
        let endPage = Math.min(startPage + numbersToShow - 1, totalPages);

        if (endPage - startPage + 1 < numbersToShow) {
        startPage = Math.max(1, endPage - numbersToShow + 1);
        }

        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
        }

        return pages;
    };
    // End Pagination

 


  const filteredAndPaginatedData = filteredData.slice(
    (currentPage - 1) * perPageNumber,
    currentPage * perPageNumber
  );



  const[openCategoriesFilter, setOpenCategoriesFilter] = useState(true)
  const [openLocationFilter, setOpenLocationFilter] = useState(true)
  const [openDonationFilter, setOpenDonationFilter] = useState(true)

  const[openFilter, setOpenFilter] = useState(false)

    

  return (
    <div className='BottomActiveProejcts'>

<div className="forMobile" onClick={() => setOpenFilter(!openFilter)}>
        <p>
            Filters 
            <IoIosArrowDropdown className={`${openFilter ? "" : "rotateSvg"}`} />
        </p>
      </div>



<div className={`bottomEducation__left ${openFilter ? "openFilter" : "closeFilter"}`}>
            <div className="bottomEducation__left__options bottomEducation__left__1">
                <h2 onClick={() => setOpenCategoriesFilter(!openCategoriesFilter)}>
                    <FormattedMessage id='BottomEducation__1' defaultMessage={"Categories"} />
                </h2>

                <ul className={`mobileEducation ${openCategoriesFilter ? "hide" : "show"}`}>
                    <li>
                    <input
                        type="checkbox"
                        id="All"
                        checked={selectedCategory.includes("All")}
                        onChange={() => handleCategorySelect("All")}
                    />
                    <label htmlFor="All">
                        {/* <span className="bottomIcon bottom__icon1"></span> */}
                        <FormattedMessage
                        id="BottomEducation__2"
                        defaultMessage={"All"}
                        />
                    </label>
                    </li>

                    <li>
                    <input
                        type="checkbox"
                        id="Sponsor-students"
                        checked={selectedCategory.includes("Sponsor students")}
                        onChange={() => handleCategorySelect("Sponsor students")}
                    />
                    <label htmlFor="Sponsor-students">
                        {/* <span className="bottomIcon bottom__icon2"></span> */}
                        <FormattedMessage
                        id="BottomEducation__3__2"
                        defaultMessage={"Sponsor students"}
                        />
                    </label>
                    </li>

                    <li>
                    <input
                        type="checkbox"
                        id="build-schools"
                        checked={selectedCategory.includes("Build schools")}
                        onChange={() => handleCategorySelect("Build schools")}
                    />
                    <label htmlFor="build-schools">
                        {/* <span className="bottomIcon bottom__icon3"></span> */}
                        <FormattedMessage
                        id="BottomEducation__4__2"
                        defaultMessage={"Build schools"}
                        />
                    </label>
                    </li>

                    <li>
                    <input
                        type="checkbox"
                        id="Donate"
                        checked={selectedCategory.includes("Donate")}
                        onChange={() => handleCategorySelect("Donate")}
                    />
                    <label htmlFor="Donate">
                        {/* <span className="bottomIcon bottom__icon4"></span> */}
                        <FormattedMessage
                        id="BottomEducation__5__2"
                        defaultMessage={"Donate"}
                        />
                    </label>
                    </li>

                    <li>
                    <input
                        type="checkbox"
                        id="Emergencies"
                        checked={selectedCategory.includes("Emergencies")}
                        onChange={() => handleCategorySelect("Emergencies")}
                    />
                    <label htmlFor="Emergencies">
                        {/* <span className="bottomIcon bottom__icon5"></span> */}
                        <FormattedMessage
                        id="BottomEducation__6__2"
                        defaultMessage={"Emergencies"}
                        />
                    </label>
                    </li>

                    <li>
                    <input
                        type="checkbox"
                        id="Campaigns"
                        checked={selectedCategory.includes("Campaigns")}
                        onChange={() => handleCategorySelect("Campaigns")}
                    />
                    <label htmlFor="Campaigns">
                        {/* <span className="bottomIcon bottom__icon6"></span> */}
                        <FormattedMessage
                        id="BottomEducation__7__2"
                        defaultMessage={"Campaigns"}
                        />
                    </label>
                    </li>

                </ul>
            </div>

            <div className="bottomEducation__left__options bottomEducation__left__3">
                <h2 onClick={() => setOpenLocationFilter(!openLocationFilter)}>
                <FormattedMessage id='BottomEducation__16' defaultMessage={"location"} />
                </h2>

                <ul className={`mobileEducation ${openLocationFilter ? "hide" : "show"}`}>
                    {locationData.slice(0, 8).map((location, index) => (
                        <li key={index}>
                            <input
                            type="checkbox"
                            id={location}
                            checked={selectedLocation.includes(location)}
                            onChange={() => handleLocationSelect(location)}
                            />
                            <label htmlFor={location}>{location}</label>
                        </li>
                        ))}

                        <li onClick={() => setOpenLocation(!openLocation)} className={`orange loadMore ${openLocation ? "close" : "open" }`}>
                        <Link to="" className={`orange loadMore ${openLocation ? "close" : "open" }`}>
                            <FormattedMessage
                            id="bottomFeatured__15"
                            defaultMessage={"Load more"}
                            />
                        </Link>
                        </li>

                        {
                            locationData.slice(8, locationData.length).map((location, index) => (
                            <li key={index} className={`location ${!openLocation ? "close" : "open"} `}>
                                <input 
                                type="checkbox" 
                                id={location}
                                checked={selectedLocation.includes(location)}
                                onChange={() => handleLocationSelect(location)}
                                />
                                <label htmlFor={location}>
                                {location}
                                </label>
                            </li>
                            ))
                        }

                        <li onClick={() => setOpenLocation(!openLocation)} className={`orange loadMore ${openLocation ? "open" : "close" }`}>
                        <Link to="" className="orange">
                            <FormattedMessage
                            id="bottomFeatured__15__2"
                            defaultMessage={"Close"}
                            />
                        </Link>
                        </li>
                </ul>
            </div>

            <div className="bottomEducation__left__options bottomEducation__left__4">
                <h2  onClick={() => setOpenDonationFilter(!openDonationFilter)}>
                <FormattedMessage id='BottomEducationActive__18' defaultMessage={"Donations goal"} />
                </h2>

                <ul className={`mobileEducation ${openDonationFilter ? "hide" : "show"}`}>
                    <li className='rangeLi'>
                    <span>0</span>
                        <div>
                            <p className='removeBorder'>{rangeNumber}</p>
                            <input 
                                type='range'
                                min="0"
                                max="1000000"
                                step="10"
                                value={rangeNumber}
                                onChange={handleRangeChange} 
                            />
                        </div>
                    <span>1M</span>
                    </li>

                    <div className='rangeButtons'>
                        <button onClick={() => setRangeNumber(10000)}>10k $</button>
                        <button onClick={() => setRangeNumber(30000)}>30k $</button>
                        <button onClick={() => setRangeNumber(50000)}>50k $</button>
                    </div>
                </ul>
            </div>


        </div>

        <div className="bottomEducation__right">
            <div className="bottomEducation__right__top">



                <div className="right">
                    <p>
                    Your filters:
                    </p>

                    <p className="pBorder perPageMain allCategory">{selectedCategory.slice(0, 1)}
                        <IoMdCloseCircleOutline onClick={() => setSelectedCategory('All')}/>
                    </p>

                    <p className="orange clearAll" onClick={() => {
                        setSelectedCategory('All');
                        setSelectedLocation([]);
                        setRangeNumber(0);
                    }}>Clear all</p>

                    <p className='showing'> 
                    Showing&nbsp;
                    <span>
                    {filteredData.length} results
                    </span>
                    </p>

                    <div className="activeSearch">
                        <span className="search"></span>
                        <input type="search" placeholder='Search here' />
                        <span className="lines"></span>
                    </div>


                    <p>
                    <FormattedMessage id='BottomEducation__21' defaultMessage={"view"} />
                    </p>

                    <p className='pBorder perPageMain'>
                        {perPageNumber}
                        <FormattedMessage id='BottomEducation__22' defaultMessage={" per page "} />
                        <IoIosArrowDown />

                        <div className="perPage">
                            <p onClick={() => setPerPageNumber(6)}>6
                                <FormattedMessage id='BottomEducation__22' defaultMessage={" per page "} />
                            </p>

                            <p onClick={() => setPerPageNumber(12)}>12
                                <FormattedMessage id='BottomEducation__22' defaultMessage={" per page "} />
                            </p>

                            <p onClick={() => setPerPageNumber(24)}>24
                                <FormattedMessage id='BottomEducation__22' defaultMessage={" per page "} />
                            </p>
                        </div>
                    </p>

                    <p className='pBorder inRightpBorder' onClick={handeGrid}>
                    <BsFillGrid3X3GapFill className={`${inGrid ? "" : "orange"} `} />
                    <span className="line"></span>
                    <FaList className={`${inGrid ? "orange" : ""} `} />
                    </p>

                </div> 
            </div>


            <div className={`bottomEducation__right__mid ${inGrid ? "makeInLine" : ""}`} >
                {
                    filteredAndPaginatedData.map((item) => (
                    <Page8Card 
                        key={item.id}
                        bgImage={`${process.env.REACT_APP_ACIVEPROJECTS_IMAGE}${item.bgImage}`}
                        smallIcon={`${process.env.REACT_APP_ACIVEPROJECTS_IMAGE}${item.smallIcon}`}
                        smallText={
                        <FormattedMessage id={`page8SmallText__${item.id}`} defaultMessage={item.smallText} />
                        }
                        title={
                        <FormattedMessage id={`page8Title__${item.id}`} defaultMessage={item.title} />
                        }
                        description={
                        <FormattedMessage id={`page8Description__${item.id}`} defaultMessage={item.description} />
                        }
                        raised={item.raised}
                        goal={item.goal}
                    />
                    ))
                }
            </div>


            <div className="bottomEducation__right__bottom">
                <ul className="bottom__numbers">
                    <p onClick={prePage}>
                        <MdKeyboardArrowLeft />
                        <FormattedMessage id="BottomEducation__24" defaultMessage={"Previous"} />
                    </p>

                    {currentPage > numbersToShow && (
                        <li>
                        <Link to="#" onClick={() => changeCPage(1)} className="page-link">
                            1
                        </Link>
                        </li>
                    )}
                    {currentPage > numbersToShow && (
                        <li>
                        <span>...</span>
                        </li>
                    )}

                    {getPageNumbers().map((n) => (
                        <li key={n} className={`page-item ${currentPage === n ? "activeBottom" : ""}`}>
                        <Link to="#" onClick={() => changeCPage(n)} className="page-link">
                            {n}
                        </Link>
                        </li>
                    ))}

                    {currentPage > numbersToShow && (
                        <li>
                        <span>...</span>
                        </li>
                    )}
                    {currentPage > numbersToShow  && (
                        <li>
                        <Link to="#" onClick={() => changeCPage(totalPages)} className="page-link">
                            {totalPages}
                        </Link>
                        </li>
                    )}

                    <p onClick={nextPage}>
                        <FormattedMessage id="BottomEducation__25" defaultMessage={"Next"} />
                        <MdKeyboardArrowRight />
                    </p>
                </ul>
            </div>
        </div>








    </div>
  )
}

export default BottomActiveProejcts