import React from 'react'
import './Page8Card.scss'
import { Link } from 'react-router-dom'
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import { FormattedMessage } from 'react-intl'

const Page8Card = ({
    bgImage,
    smallIcon,
    smallText,
    title,
    description,
    raised,
    goal,

}) => {

    const bgImageDiv = {
        backgroundImage: `url(${bgImage})`
    }

    const smallIconDiv = {
        content: `url(${smallIcon})`
    }


  return (
    <div className='Page8Card'>
        
        <div className="page8Card__top" style={bgImageDiv}>
            <div className="page8Card__top__filter"></div>

            <p className="page8Card__top__title">
            {title}
            </p>

            <div className="page8Card__top__build">
                <span className="page8__smallIcon" style={smallIconDiv}></span>
                <span>
                {smallText}
                </span>
            </div>
        </div>

        <div className="page8Card__bottom">

            <p>
            {description}
            </p>

            <div className="page8Card__prices">
                <div className="page8Card__price">
                    <HiOutlineCurrencyDollar className='page8Card__price__left'/>
                    <div className="page8Card__price__right">
                        <p>
                        <FormattedMessage id='page8Card__1' defaultMessage={"raised"} />
                        </p>

                        <span>
                        ${raised}
                        </span>
                    </div>
                </div>    

                <div className="page8Card__price">
                    <HiOutlineCurrencyDollar className='page8Card__price__left'/>
                    <div className="page8Card__price__right">
                        <p>
                        <FormattedMessage id='page8Card__2' defaultMessage={"goal"} />
                        </p>

                        <span>
                        ${goal}
                        </span>
                    </div>
                </div>   

            </div>

            <Link to="">
                <FormattedMessage id='page8Card__3' defaultMessage={"Donate now"} />
            </Link>
        </div>


    </div>
  )
}

export default Page8Card