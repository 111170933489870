import Footer from "../../components/shared/Footer/Footer";
import Navbar from "../../components/shared/Navbar/Navbar";
import "./EducationProfile.scss";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { IoLocationSharp } from "react-icons/io5";
import { FaStar } from "react-icons/fa6";
import { CiStar } from "react-icons/ci";
import { GrShare } from "react-icons/gr";
import { Link, useLocation, useParams } from "react-router-dom";
import { FiPhone } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import { LuClock } from "react-icons/lu";
import { IoIosArrowRoundBack, IoLogoFacebook } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import ServicesCard from "../../components/EducationProfile/ServicesCard/ServicesCard";
import BenefitsCard from "../../components/EducationProfile/BenefitsCard/BenefitsCard";
import TeamCard from "../../components/EducationProfile/TeamCard/TeamCard";
import Carousel from "react-elastic-carousel";
import { useEffect, useRef, useState } from "react";
import ReviewsCard from "../../components/EducationProfile/ReviewsCard/ReviewsCard";
import axios from "axios";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 300, itemsToShow: 2 },
  { width: 450, itemsToShow: 3 },
  { width: 800, itemsToShow: 4 },
  { width: 1000, itemsToShow: 5 },
];

const EducationProfile = (props) => {
  const location = useLocation();
  const educationData = location.state?.educationData || {};
  const { id, name } = useParams();

  const [pageData, setPageData] = useState([]);
  const [team, setTeam] = useState([]);
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ROUTE}BottomEducation/BottomEducation.json`)
      .then((res) => {
        setPageData(res.data);
        setTeam(res.data[id - 1].team);
        setReviews(res.data[id - 1].review);
      })
      .catch((error) => {
        console.error("Page4 : ", error);
      });
  }, [id]);

  const item =
    pageData.find((item) => item.id === Number(id) && item.name.replace(/ /g, "_") === name) ||
    educationData;

  const carouselRef = useRef(null);
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo("single-slide".length);
    }
  };

  const [benefits, setBenefits] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ROUTE}Benefits/Benefits.json`)
      .then((res) => {
        setBenefits(res.data);
      })
      .catch((error) => {
        console.error("Benefits : ", error);
      });
  }, []);

  const [services, setServices] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ROUTE}Services/Services.json`)
      .then((res) => {
        setServices(res.data);
      })
      .catch((error) => {
        console.error("Services : ", error);
      });
  }, []);

  const gallery1Div = {
    content: `url(${item.gallery1})`,
  };

  const gallery2Div = {
    content: `url(${item.gallery2})`,
  };

  const gallery3Div = {
    content: `url(${item.gallery3})`,
  };

  const gallery4Div = {
    content: `url(${item.gallery4})`,
  };

  const gallery5Div = {
    content: `url(${item.gallery5})`,
  };

  const gallery6Div = {
    content: `url(${item.gallery6})`,
  };

  const imageDiv = {
    backgroundImage: `url(${process.env.REACT_APP_BottomEducation_IMAGE}${item.image})`,
  };

  const reviewInputRef = useRef(null);

  const scrollToReviewInput = () => {
    if (reviewInputRef.current) {
      reviewInputRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      reviewInputRef.current.focus();
    }
  };

  return (
    <div className="EducationProfile">
      <Navbar />
      <div className="EducationProfileContent">
        <div className="EducationProfileContent__1">
            <Link to='/education' className="goBack">
              <IoIosArrowRoundBack />
              Go Back
            </Link>
          <div className="left">
            <div className="educationImage" style={imageDiv}></div>

            <p className="nivel">{item.category}</p>

            <h1 className="title">{item.name}</h1>

            <p className="education__description">
              Enroll your kids in the best pre-school institution now
            </p>

            <div className="education__buttons">
              <p>
                <HiOutlineUserGroup />
                {item.yoStart}-{item.yoEnd} yo
              </p>

              <p>
                <IoLocationSharp />
                {item.city}, Kosova
              </p>

              <p>
                <FaStar />
                5.0 / 5.0
                <span className="gray">({item.reviews})</span>
                <span className="orange">Reviews</span>
              </p>
            </div>

            <div className="education__write">
              <p className="review" onClick={scrollToReviewInput}>
                <CiStar />
                Write a review
              </p>

              <p className="website">
                Visit Website
                <GrShare />
              </p>
            </div>
          </div>

          {item.category == "Pre-schools" ? (
            <div className="right"></div>
          ) : (
            <div className="right1" style={imageDiv}></div>
          )}
        </div>

        <div className="EducationProfileContent__2">
          <div className="education__header">
            <Link to="">About</Link>

            <Link to="">Contacts</Link>

            <Link to="">Services</Link>

            <Link to="">Benefits</Link>

            <Link to="">Team</Link>

            <Link to="">Gallery</Link>

            <Link to="">Reviews</Link>
          </div>

          <div className="education__about">
            <h1 className="educationTitle">About the institution</h1>

            <div className="education__text">
              <p>{item.about1}</p>

              <p>{item.about2}</p>
            </div>
          </div>

          <div className="education__contacts">
            <h1 className="educationTitle">Contacts</h1>

            <p>Get in touch</p>

            <div className="education__contacts__cards">
              <div className="contacts__card">
                <FiPhone />

                <div className="contacts__card__right">
                  <p>Phone Number</p>

                  <span>{item.phone}</span>
                </div>
              </div>

              <div className="contacts__card">
                <AiOutlineMail />

                <div className="contacts__card__right">
                  <p>Email</p>

                  <span>{item.email}</span>
                </div>
              </div>

              <div className="contacts__card">
                <IoLocationOutline />

                <div className="contacts__card__right">
                  <p>Location</p>

                  <span>{item.location}</span>
                </div>
              </div>

              <div className="contacts__card">
                <LuClock />

                <div className="contacts__card__right">
                  <p>Working hours</p>

                  <span>{item.workingHours}</span>
                </div>
              </div>

              <div className="contacts__card makeHover">
                <IoLogoFacebook />

                <div className="contacts__card__right ">
                  <p>Facebook</p>

                  <span>{item.facebook}</span>
                </div>
              </div>

              <div className="contacts__card makeHover">
                <FaInstagram />

                <div className="contacts__card__right ">
                  <p>Instagram</p>

                  <span>{item.instagram}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="education__contacts education__services">
            <h1 className="educationTitle">Services</h1>

            <p>Services this institution offers</p>

            {item.category == "Pre-schools" ? (
              <div className="education__services__cards">
                {services.slice(0, 2).map((item) => (
                  <ServicesCard
                    key={item.id}
                    bgImage={`${process.env.REACT_APP_Services_IMAGE}${item.bgImage}`}
                    description={item.description}
                    firstTitle={item.firstTitle}
                    title={item.title}
                    year={item.year}
                  />
                ))}
              </div>
            ) : (
              <div className="education__services__cards">
                {services.slice(2, 4).map((item) => (
                  <ServicesCard
                    key={item.id}
                    bgImage={`${process.env.REACT_APP_Services_IMAGE}${item.bgImage}`}
                    description={item.description}
                    firstTitle={item.firstTitle}
                    title={item.title}
                    year={item.year}
                  />
                ))}
              </div>
            )}
          </div>

          {item.category == "Pre-schools" ? (
            <div className="education__contacts education__services">
              <h1 className="educationTitle">Benefits</h1>

              <p>Why choose this institution?</p>

              <div className="education__benefits__cards">
                {benefits.map((item) => (
                  <BenefitsCard
                    key={item.id}
                    bgImage={`${process.env.REACT_APP_BENEFITS_IMAGE}${item.bgImage}`}
                    title={item.title}
                    description={item.description}
                  />
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="education__contacts education__services">
            <h1 className="educationTitle">Meet the team</h1>

            <p>
              At {item.name}, our team comprises passionate educators and
              caregivers who are experts in their fields.
            </p>

            <div className="education__team__cards">
              <div className="carouselPage8">
                <Carousel
                  className="slider styled-arrows"
                  breakPoints={breakPoints}
                  ref={carouselRef}
                  onPrevStart={onPrevStart}
                  onNextStart={onNextStart}
                  disableArrowsOnEnd={false}
                >
                  {team.map((item) => (
                    <TeamCard
                      key={item.id}
                      bgImage={`${process.env.REACT_APP_Team_IMAGE}${item.image}`}
                      name={item.name}
                      profession2={item.proffesion}
                    />
                  ))}
                </Carousel>
              </div>
            </div>
          </div>


          <div className="education__contacts education__reviews">
            <h1 className="educationTitle">Reviews</h1>

            <p>What people have to say</p>

            <span>
              <FaStar />
              &nbsp; 5.0 / {item.star}
              <span className="gray"> ({item.reviews})</span>
              <span className="orange"> Reviews</span>
            </span>

            <div className="leaveReviews">
              <div className="left">
                <CiStar />
                <CiStar />
                <CiStar />
                <CiStar />
                <CiStar />
              </div>

              <div className="line"></div>

              <input
                type="text"
                placeholder="Leave a review"
                ref={reviewInputRef}
                // onKeyDown={handleReviewSubmission}
              />
            </div>

            <div className="education__reviews__cards">
              {reviews.map((item) => (
                <ReviewsCard
                  key={item.id}
                  bgImage={`${process.env.REACT_APP_Reviews_IMAGE}${item.image}`}
                  name={item.name}
                  description={item.description}
                  dataWhere={item.dataWhere}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default EducationProfile;
