import React from 'react'
import './Page7Card.scss'
import { Link } from 'react-router-dom'

const Page7Card = ({ bgImage, title, description, buttonText }) => {

  const bgImageDiv = {
    content: `url(${bgImage})`
  }

  
  return (
    <div className='Page7Card'>
        <div className="page7Card__top">
            <div className="page7Card__top__inside" style={bgImageDiv}></div>
        </div>

        <div className="page7Card__bottom">
            <div className="page7Card__text">
                <h2>
                {title}
                </h2>

                <p>
                {description}
                </p>
            </div>

            <Link to="">
            {buttonText}
            </Link>
        </div>

    </div>
  )
}

export default Page7Card