import axios from 'axios'
import React, { useEffect, useState } from 'react'
import MobileCard from '../MobileCard/MobileCard'
import './EducationMobile.scss'
import { FormattedMessage } from 'react-intl'

import icon1 from '../../../../assets/EducationNav/icon1.svg'
import icon2 from '../../../../assets/EducationNav/icon2.svg'
import icon3 from '../../../../assets/EducationNav/icon3.svg'
import icon4 from '../../../../assets/EducationNav/icon4.svg'
import icon5 from '../../../../assets/EducationNav/icon5.svg'
import icon6 from '../../../../assets/EducationNav/icon6.svg'
import icon7 from '../../../../assets/EducationNav/icon7.svg'
import icon8 from '../../../../assets/EducationNav/icon8.svg'
import image1 from '../../../../assets/EducationNav/img1.webp'

const leftData = [
    {
        id: 1,
        title: "Educational Institutions",
        description: "Explore all institutions in Kosovo",
        image: icon1,
    },
    {
        id: 2,
        title: "Advocating",
        description: "An enhanced and innovative education",
        image: icon2,
    },
    {
        id: 3,
        title: "Courses",
        description: "Search for professional courses",
        image: icon3,
    },
    {
        id: 4,
        title: "Mobile App",
        description: "Download our learning mobile app",
        image: icon4,
    },
    {
        id: 5,
        title: "Teachers",
        description: "Find out more about teachers",
        image: icon5,
    },
    {
        id: 6,
        title: "Library",
        description: "Discover more",
        image: icon6,
    },
    {
        id: 7,
        title: "Learning Center",
        description: "Learn new skills every day",
        image: icon7,
    },
    {
        id: 8,
        title: "Research",
        description: "Exploring new ideas",
        image: icon8,
    },
];


const EducationMobile = () => {
  return (
    <div className='EducationMobile'>
        {
            leftData.map((item) => (
                <div 
                    className='insideMobileMega'
                    key={item.id}
                >
                    <MobileCard 
                        bgImage={item.image}
                        titleMobile={
                            <FormattedMessage id={`educationTitle__${item.id}`} defaultMessage={item.title} />
                        }
                    />
                </div>
            ))
        }
    </div>
  )
}

export default EducationMobile
