import React, { useEffect, useState } from 'react'
import './Page9Card.scss'
import { FormattedMessage } from 'react-intl'

const Page9Card = ({ bgImage, bgColor, circle1, circle2, circle3, title, price, donationPage9 }) => {
    const [currentBgColor, setCurrentBgColor] = useState(`#${bgColor}`);
    const [isFullCircle1, setIsFullCircle1] = useState(false);
    const [isFullCircle2, setIsFullCircle2] = useState(false);
    const [isFullCircle3, setIsFullCircle3] = useState(false);

    const circle1Div = {
        backgroundColor: `#${circle1}`,
        className: `page9Circle circle1 ${isFullCircle1 ? 'fullCircle' : ''}`
    }

    const circle2Div = {
        backgroundColor: `#${circle2}`,
        className: `page9Circle circle2 ${isFullCircle2 ? 'fullCircle' : ''}`
    }
    
    const circle3Div = {
        backgroundColor: `#${circle3}`,
        className: `page9Circle circle3 ${isFullCircle3 ? 'fullCircle' : ''}`
    }

    const bgColorDiv = {
        backgroundColor: currentBgColor,
        backgroundImage: `url(${bgImage})`,
    }

    const donationPage9Div = {
        content: `url(${donationPage9})`,
    }

    const handleCircleClick = (color, circleNumber) => {
        setCurrentBgColor(color);

        switch (circleNumber) {
            case 1:
                setIsFullCircle1(!isFullCircle1);
                setIsFullCircle2(false);
                setIsFullCircle3(false);
            break;
            case 2:
                setIsFullCircle2(!isFullCircle2);
                setIsFullCircle1(false);
                setIsFullCircle3(false);
            break;
            case 3:
                setIsFullCircle3(!isFullCircle3);
                setIsFullCircle1(false);
                setIsFullCircle2(false);
            break;
            default:
            break;
        }
    };

    useEffect(() => {
        setCurrentBgColor(`#${circle1}`);
    }, [circle1]);



  return (
    <div className='Page9Card'>
        <div className="page9Card__top" style={bgColorDiv}></div>

        <div className="page9Card__bottom">
            <div className="page9Card__1">
                <p>
                {title}
                </p>

                <div className="page9__colors">
                    <div className={circle1Div.className} style={circle1Div} onClick={() => handleCircleClick(`#${circle1}`, 1)}></div>
                    <div className={circle2Div.className} style={circle2Div} onClick={() => handleCircleClick(`#${circle2}`, 2)}></div>
                    <div className={circle3Div.className} style={circle3Div} onClick={() => handleCircleClick(`#${circle3}`, 3)}></div>
                </div>
            </div>

            <h4>
            {price}€
            </h4>

            <div className="page9Card__footer">
                <p>
                    <FormattedMessage id='page9Card__1' defaultMessage={"Donation goes to:"} />
                </p>

                <div className="page9Card__dontaion" style={donationPage9Div}>
                </div>
            </div>

        </div>

    </div>
  )
}

export default Page9Card