import React, { useEffect, useRef, useState } from "react";
import "./Resources.scss";
import axios from "axios";
import NavbarButtons from "../../NavbarButtons/NavbarButtons";
import NavbarCard from "../../NavbarCard/NavbarCard";
import Carousel from "react-elastic-carousel";
import { FormattedMessage } from "react-intl";

import icon1 from "../../../../assets/Resources/icon1.svg";
import icon2 from "../../../../assets/Resources/icon2.svg";
import icon3 from "../../../../assets/Resources/icon3.svg";
import icon4 from "../../../../assets/Resources/icon4.svg";
import icon5 from "../../../../assets/Resources/icon5.svg";
import icon6 from "../../../../assets/Resources/icon6.svg";
import icon7 from "../../../../assets/Resources/icon7.svg";
import icon8 from "../../../../assets/Resources/icon8.svg";
import image1 from "../../../../assets/Resources/img1.webp";
import image2 from "../../../../assets/Resources/img2.webp";

const leftData = [
  {
    id: 1,
    title: "Clubs",
    description: "Discover our clubs and communities",
    image: icon1,
  },
  {
    id: 2,
    title: "Careers",
    description: "Become a team member",
    image: icon2,
  },
  {
    id: 3,
    title: "Webinars",
    description: "Join our online webinars",
    image: icon3,
  },
  {
    id: 4,
    title: "Blog",
    description: "Read our latest articles",
    image: icon4,
  },
  {
    id: 5,
    title: "Events",
    description: "Reserve your spot",
    image: icon5,
  },
  {
    id: 6,
    title: "FAQs",
    description: "Get answers to all your questions",
    image: icon6,
  },
  {
    id: 7,
    title: "Partners",
    description: "Meet our partners",
    image: icon7,
  },
  {
    id: 8,
    title: "Guides",
    description: "Explore career guides",
    image: icon8,
  },
];

const rightData = [
  {
    id__right: 1,
    title__right: "KosovaEducation changing the education systems",
    image__right: image1,
  },
  {
    id__right: 2,
    title__right: "How early education can help a child develop better?",
    image__right: image2,
  },
];

const breakPoints = [
  { width: 1, itemsToShow: 2 },
  { width: 650, itemsToShow: 2 },
  { width: 1200, itemsToShow: 2 },
];

const Resources = () => {
  const carouselRef = useRef(null);
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo("single-slide".length);
    }
  };

  return (
    <div className="Resources">
      <div className="resources__head">
        <h3 className="percent50">
          <FormattedMessage id="resources__1__1" defaultMessage={"resources"} />
        </h3>

        <h3>
          <FormattedMessage id="resources__2__1" defaultMessage={"articles"} />
        </h3>
      </div>

      <div className="insideAbout">
        <div className="aboutUs__left">
          <div className="inside__about">
            {leftData.map((item) => (
              <NavbarButtons
                key={item.id}
                bgImage={item.image}
                title={
                  <FormattedMessage
                    id={`resourcesTitle__${item.id}`}
                    defaultMessage={item.title}
                  />
                }
                description={
                  <FormattedMessage
                    id={`resourcesDescription__${item.id}`}
                    defaultMessage={item.description}
                  />
                }
              />
            ))}
          </div>
        </div>

        <div className="aboutUs__right">
          <div className="carouselAboutUs">
            <Carousel
              className="slider styled-arrows"
              breakPoints={breakPoints}
              ref={carouselRef}
              onPrevStart={onPrevStart}
              onNextStart={onNextStart}
              disableArrowsOnEnd={false}
            >
              {rightData.map((item) => (
                <NavbarCard
                  key={item.id__right}
                  bgImage={item.image__right}
                  title={
                    <FormattedMessage
                      id={`resourcesTitleRight__${item.id__right}`}
                      defaultMessage={item.title__right}
                    />
                  }
                />
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
