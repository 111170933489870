import React, { useEffect, useRef, useState } from 'react'
import './Page6.scss'
import Page6Card from './Page6Card/Page6Card'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Carousel from 'react-elastic-carousel'
import { FormattedMessage } from 'react-intl'
import AOS from 'aos';
import "aos/dist/aos.css";


const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width:650, itemsToShow: 2 },
  { width:1200, itemsToShow: 3 },
];

const Page6 = () => {
  const [pageData, setPageData] = useState([])
  useEffect(() => {
    AOS.init();
  }, [])
  useEffect(() => {

    axios
    .get(`${process.env.REACT_APP_API_ROUTE}FeaturedPage/FeaturedPage.json`)
    .then(res => {
      setPageData(res.data)
    })
    .catch(error => {
        console.error("FeaturedCourses : ", error)
    })
  }, [])
  
  const carouselRef = useRef(null);
  const onNextStart = (currentItem, nextItem) => {
      if (currentItem.index === nextItem.index) {
        carouselRef.current.goTo(0);
      }
  };
  const onPrevStart = (currentItem, nextItem) => {
      if (currentItem.index === nextItem.index) {
        carouselRef.current.goTo('single-slide'.length);
      }
  };

  return (
    <div className='Page6'>
        <h1 data-aos="fade-right">
          <FormattedMessage id="page6__1" defaultMessage={"Featured Courses"} />
        </h1>

        <div className="carouselPage6" data-aos="fade-left">
          <Carousel className="slider styled-arrows" 
                breakPoints={breakPoints}
                ref={carouselRef}
                onPrevStart={onPrevStart}
                onNextStart={onNextStart}
                disableArrowsOnEnd={false}
            >
              {
                pageData.map((item) => (
                  <Link 
                  className='hoverA'
                  to={
                    { 
                      pathname: `/featured/${item.id}/${item.title.replace(/ /g, "_")}`, 
                      state: { featuredData: item } 
                    }
                  } 
                  key={item.id}
                  >
                    <Page6Card 
                      key={item.id}
                      bgImage={`${process.env.REACT_APP_FeaturedPage_IMAGE}${item.bgImage}`}
                      offerdBy={item.offerdBy}
                      title={
                        <FormattedMessage id={`page6Title__${item.id}`} defaultMessage={item.title} />
                      }
                      description={
                        <FormattedMessage id={`page6Description__${item.id}`} defaultMessage={item.description} />
                      }
                      months={item.months}
                    />
                  </Link>
                ))
              }
            </Carousel>
          </div>

        <Link to="/featured" className="page4Explore" data-aos="fade-right">
          <FormattedMessage id="page6__2" defaultMessage={"View all"} />
        </Link>
        
    </div>
  )
}

export default Page6