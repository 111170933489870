import React, { useEffect, useRef, useState } from "react";
import Footer from "../../components/shared/Footer/Footer";
import Navbar from "../../components/shared/Navbar/Navbar";
import "./FeaturedDetails.scss";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { IoLocationOutline, IoLocationSharp } from "react-icons/io5";
import { IoIosArrowRoundBack, IoIosCalendar } from "react-icons/io";
import { FaFacebook, FaFacebookF, FaInstagram, FaLinkedinIn, FaRegStar, FaStar, FaTiktok, FaTwitter } from "react-icons/fa";
import { CiShare1, CiStar } from "react-icons/ci";
import { GrShare } from "react-icons/gr";
import { FiPhone } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";
import { CiCirclePlus } from "react-icons/ci";
import { CiCircleMinus } from "react-icons/ci";
import Page6Card from "../../components/Home/Page6/Page6Card/Page6Card";
import Carousel from "react-elastic-carousel";
import ReviewsCard from "../../components/EducationProfile/ReviewsCard/ReviewsCard";
import { MdKeyboardArrowDown, MdOutlineSort } from "react-icons/md";
import { BsSortAlphaDown, BsSortAlphaDownAlt, BsSortDownAlt } from "react-icons/bs";
import { BsSortDown } from "react-icons/bs";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 650, itemsToShow: 2 },
  { width: 1200, itemsToShow: 3 },
];

const FeaturedDetails = () => {
  const [faq1, setFaq1] = useState(false);
  const [faq2, setFaq2] = useState(false);
  const [faq3, setFaq3] = useState(false);
  const [faq4, setFaq4] = useState(false);
  const [faq5, setFaq5] = useState(false);

  const location = useLocation();
  const blogData = location.state?.blogData || {};
  const { id, title } = useParams();

  const [pageData, setPageData] = useState([]);
  const [carouselData, setCarouselData] = useState([]);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ROUTE}FeaturedPage/FeaturedPage.json`)
      .then((res) => {
        setPageData(res.data);
        const institutionName = res.data[id - 1].institutions;
        const filteredData = res.data.filter(
          (item) => item.institutions === institutionName
        );
        setCarouselData(filteredData);
        setReviews(res.data[id - 1].review)
      })
      .catch((error) => {
        console.error("FeaturedPage : ", error);
      });
  }, []);

  const item =
    pageData.find((item) => item.id === Number(id) && item.title.replace(/ /g, "_") === title) ||
    blogData;

  const smallImage = {
    backgroundImage: `url(${process.env.REACT_APP_FeaturedPage_IMAGE}${item.offerdBy})`,
  };

  const bigImage = {
    backgroundImage: `url(${process.env.REACT_APP_FeaturedPage_IMAGE}${item.bgImage})`,
  };

  const carouselRef = useRef(null);
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo("single-slide".length);
    }
  };

  const reviewInputRef = useRef(null);

  const scrollToReviewInput = () => {
    if (reviewInputRef.current) {
      reviewInputRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      reviewInputRef.current.focus();
    }
  };

  const [selectedStar, setSelectedStar] = useState([])
  const handleStarSelect = (star2) => {
    if(star2) {
      const updatedSelectedStar = selectedStar.includes(star2)
      ? selectedStar.filter((selectedStar) => selectedStar !== star2)
      : [...selectedStar, star2];
      setSelectedStar(updatedSelectedStar);
    } else {
      setSelectedStar([]);
    }
  };

  const [activeSortOption, setActiveSortOption] = useState("");
  const [sortOrder, setSortOrder] = useState("ASC");
  const handleSort = (property, label) => {
    if (activeSortOption === label) {
      return; // Do nothing if the active option is clicked again
    }
    const sortedData = [...reviews].sort((a, b) => {
      if (sortOrder === "ASC") {
        return a[property] > b[property] ? 1 : -1;
      } else {
        return a[property] < b[property] ? 1 : -1;
      }
    });
  
    setReviews(sortedData);
    setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
    setActiveSortOption(label);
  };

  const filteredData = reviews.filter(
    (item) =>
    (selectedStar.length === 0 || selectedStar.includes(item.reviewStar))
  );



  return (
    <div>
      <Navbar />

      <div className="inside__FeaturedDetails">
        <div className="EducationProfileContent__1">
          <div className="left">

            <Link to='/featured' className="goBack">
              <IoIosArrowRoundBack />
              Go Back
            </Link>

            <span className="course">
              Course offered by: {item.title}
              <div className="smallImage" style={smallImage}></div>
            </span>

            <p className="nivel">{item.categories}</p>

            <h1 className="title">{item.title}</h1>

            <p className="education__description">{item.description}</p>

            <div className="education__buttons">
              <p>
                <IoIosCalendar />
                {item.months} months
              </p>

              <p>
                <FaStar />
                5.0 / {item.star}.0
                <span className="gray">({item.reviews})</span>
                <span className="orange">Reviews</span>
              </p>
            </div>

            <div className="priceandSchedule">
              <p>
                Price:&nbsp;
                <span>{item.price}$</span>
              </p>

              <p>
                Schedule:&nbsp;
                <span>{item.schedule}</span>
              </p>
            </div>

            <div className="education__write">
              <Link className="review" onClick={scrollToReviewInput}>
                <CiStar />
                Write a review
              </Link>

              <Link className="website">
                Visit Website
                <GrShare />
              </Link>

              <Link className="applyNow">
                Apply now
              </Link>
            </div>

            <p className="published">
            Date published: {item.published}
            </p>
          </div>

          <div className="right" style={bigImage}></div>
        </div>

        <div className="secondFeatured">
          <div className="featuredDiv">
            <h1 className="firstTitle">{item.title1}</h1>

            <p className="textBlog">{item.description1}</p>
          </div>

          <div className="featuredDiv">
            <h1 className="firstTitle">{item.title2}</h1>

            <ul>
              <li>{item.choose1}</li>
              <li>{item.choose2}</li>
              <li>{item.choose3}</li>
              <li>{item.choose4}</li>
            </ul>
          </div>

          <div className="featuredDiv">
            <h1 className="firstTitle">{item.title3}</h1>

            <ul>
              <li>{item.course1}</li>
              <li>{item.course2}</li>
              <li>{item.course3}</li>
            </ul>
          </div>

          <div className="education__contacts featuredDiv">
            <h1 className="firstTitle">Contacts</h1>

            <p className="textBlog">Get in touch with academy</p>

            <div className="education__contacts__cards">
              <div className="contacts__card">
                <FiPhone />

                <div className="contacts__card__right">
                  <p>Phone Number</p>

                  <span>{item.phoneNumber}</span>
                </div>
              </div>

              <div className="contacts__card">
                <AiOutlineMail />

                <div className="contacts__card__right">
                  <p>Email</p>

                  <span>{item.email}</span>
                </div>
              </div>

              <div className="contacts__card">
                <IoLocationOutline />

                <div className="contacts__card__right">
                  <p>Location</p>

                  <span>{item.location}</span>
                </div>
              </div>

              <div className="contacts__card">
                <div className="contacts__card__right">
                  <p>Social Medias</p>

                  <div className="socialFeatured">
                    {
                      item.facebook && (
                        <span><FaFacebookF /></span>
                      )
                    }
                    {
                      item.instagram && (
                        <span><FaInstagram /></span>
                      )
                    }
                   {
                      item.linkedin && (
                        <span><FaLinkedinIn /></span>
                      )
                    }
                   {
                      item.twitter && (
                        <span><FaTwitter /></span>
                      )
                    }
                   {
                      item.tiktok && (
                        <span><FaTiktok /></span>
                      )
                    }

                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="faqs">
            <h1 className="firstTitle">FAQs</h1>

            <p className="faqtext">
              Check some of the frequently asked questions about the course.
            </p>

            <div className="questions">
              <div className="faqQuestions">
                <p className="show" onClick={() => setFaq1(!faq1)}>
                  What payment methods are acceptable for the course?
                  {!faq1 ? <CiCirclePlus /> : <CiCircleMinus />}
                </p>

                {faq1 ? <p className="hide">{item.faq1}</p> : <></>}
              </div>

              <div className="faqQuestions">
                <p className="show" onClick={() => setFaq2(!faq2)}>
                  Do I get a certificate after I finish this course?
                  {!faq2 ? <CiCirclePlus /> : <CiCircleMinus />}
                </p>

                {faq2 ? <p className="hide">{item.faq2}</p> : <></>}
              </div>

              <div className="faqQuestions">
                <p className="show" onClick={() => setFaq3(!faq3)}>
                  Is the internship guaranteed after the course?
                  {!faq3 ? <CiCirclePlus /> : <CiCircleMinus />}
                </p>

                {faq3 ? <p className="hide">{item.faq3}</p> : <></>}
              </div>

              <div className="faqQuestions">
                <p className="show" onClick={() => setFaq4(!faq4)}>
                  What technology will be used during this course?
                  {!faq4 ? <CiCirclePlus /> : <CiCircleMinus />}
                </p>

                {faq4 ? <p className="hide">{item.faq4}</p> : <></>}
              </div>

              <div className="faqQuestions">
                <p className="show" onClick={() => setFaq5(!faq5)}>
                  How long will the Web Development course take?
                  {!faq5 ? <CiCirclePlus /> : <CiCircleMinus />}
                </p>

                {faq5 ? <p className="hide">{item.faq5}</p> : <></>}
              </div>
            </div>

            <p className="faqtext">Contact the academy for more questions</p>
          </div>



          <div className="education__contacts education__reviews">
              <div className="reviews__top">
                <div className="reviews__1">
                <h1 className="firstTitle">Reviews</h1>

                <p>What people have to say</p>

                <span>
                  <FaStar />
                  &nbsp; 5.0 / {item.star}
                  <span className="gray"> ({item.reviews})</span>
                  <span className="orange"> Reviews</span>
                </span>
              </div>

                <div className="starandsort">
                  
                  <div className="star">
                    <p className="starP">
                      <FaRegStar />
                      Stars
                      <MdKeyboardArrowDown />
                    </p>

                    <div className="show__star">
                      <ol>
                        <li>
                          <input 
                            type="checkbox" 
                            id="star5"
                            checked={selectedStar.includes(5)}
                            onChange={() => handleStarSelect(5)}
                          />
                          <label htmlFor="star5">
                            5
                            <p>
                              <FaStar />
                              <FaStar />
                              <FaStar />
                              <FaStar />
                              <FaStar />
                            </p>
                          </label>
                        </li>

                        <li>
                          <input 
                            type="checkbox" 
                            id="star4"
                            checked={selectedStar.includes(4)}
                            onChange={() => handleStarSelect(4)}
                          />
                          <label htmlFor="star4">
                            4
                            <p>
                              <FaStar />
                              <FaStar />
                              <FaStar />
                              <FaStar />
                              <FaStar className="grayStar"/>
                            </p>
                          </label>
                        </li>

                        <li>
                          <input 
                            type="checkbox" 
                            id="star3"
                            checked={selectedStar.includes(3)}
                            onChange={() => handleStarSelect(3)}
                          />
                          <label htmlFor="star3">
                            3
                            <p>
                              <FaStar />
                              <FaStar />
                              <FaStar />
                              <FaStar className="grayStar"/>
                              <FaStar className="grayStar"/>
                            </p>
                          </label>
                        </li>

                        <li>
                          <input 
                            type="checkbox" 
                            id="star2"
                            checked={selectedStar.includes(2)}
                            onChange={() => handleStarSelect(2)}
                          />
                          <label htmlFor="star2">
                            2
                            <p>
                              <FaStar />
                              <FaStar />
                              <FaStar className="grayStar"/>
                              <FaStar className="grayStar"/>
                              <FaStar className="grayStar"/>
                            </p>
                          </label>
                        </li>

                        <li>
                          <input 
                            type="checkbox" 
                            id="star1"
                            checked={selectedStar.includes(1)}
                            onChange={() => handleStarSelect(1)}
                          />
                          <label htmlFor="star1">
                            1
                            <p>
                              <FaStar />
                              <FaStar className="grayStar"/>
                              <FaStar className="grayStar"/>
                              <FaStar className="grayStar"/>
                              <FaStar className="grayStar"/>
                            </p>
                          </label>
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div className="star sort">



                  <p className="starP">
                      <MdOutlineSort />
                      Sort by
                      <MdKeyboardArrowDown />
                    </p>

                    <div className="show__star show_sort_by">
                      <ol>
                        <li
                          onClick={() => handleSort("id", "Newest")}
                          className={`${activeSortOption === "Newest" ? "activeClick" : "deactiveClick"}`}
                        >
                          <MdOutlineSort />
                          Newest
                        </li>

                        <li
                          onClick={() => handleSort("id", "Oldest")}
                          className={`${activeSortOption === "Oldest" ? "activeClick" : "deactiveClick"}`}
                        >
                          <MdOutlineSort className="oldest" />
                          Oldest
                        </li>

                        <li
                          onClick={() => handleSort("reviewStar", "Most Rated")}
                          className={`${activeSortOption === "Most Rated" ? "activeClick" : "deactiveClick"}`}
                        >
                          <BsSortDown />
                          Most Rated
                        </li>

                        <li
                          onClick={() => handleSort("reviewStar", "Least Rated")}
                          className={`${activeSortOption === "Least Rated" ? "activeClick" : "deactiveClick"}`}
                        >
                          <BsSortDownAlt />
                          Least Rated
                        </li>

                        <li
                          onClick={() => handleSort("name", "Name (ASC)")}
                          className={`${activeSortOption === "Name (ASC)" ? "activeClick" : "deactiveClick"}`}
                        >
                          <BsSortAlphaDown />
                          Name (ASC)
                        </li>

                        <li
                          onClick={() => handleSort("name", "Name (DESC)")}
                          className={`${activeSortOption === "Name (DESC)" ? "activeClick" : "deactiveClick"}`}
                        >
                          <BsSortAlphaDownAlt />
                          Name (DESC)
                        </li>
                      </ol>
                    </div>
                  </div>

                </div>
              </div>



            <div className="leaveReviews">
              <div className="left">
                <CiStar />
                <CiStar />
                <CiStar />
                <CiStar />
                <CiStar />
              </div>

              <div className="line"></div>

              <input
                type="text"
                placeholder="Leave a review"
                ref={reviewInputRef}
                // onKeyDown={handleReviewSubmission}
              />
            </div>

            <div className="education__reviews__cards">
              {filteredData.map((item) => (
                <ReviewsCard
                  key={item.id}
                  bgImage={`${process.env.REACT_APP_Reviews_IMAGE}${item.image}`}
                  name={item.name}
                  description={item.description}
                  dataWhere={item.dateWhere}
                  star={item.reviewStar}
                />
              ))}
            </div>
          </div>





          <div className="featuredCourses">
            <h1>More courses offered by {item.institutions}</h1>

            <div className="carouselPage6">
              <Carousel
                className="slider styled-arrows"
                breakPoints={breakPoints}
                ref={carouselRef}
                onPrevStart={onPrevStart}
                onNextStart={onNextStart}
                disableArrowsOnEnd={false}
              >
                {carouselData.map((item) => (
                  <Link
                    to={{
                      pathname: `/featured/${item.id}/${item.title.replace(/ /g, "_")}`,
                      state: { featuredData: item },
                    }}
                    key={item.id}
                  >
                    <Page6Card
                      key={item.id}
                      bgImage={`${process.env.REACT_APP_FeaturedPage_IMAGE}${item.bgImage}`}
                      offerdBy={item.offerdBy}
                      title={item.title}
                      description={item.description}
                      months={item.months}
                    />
                  </Link>
                ))}
              </Carousel>
            </div>

            <Link to="" className="visitWebsite">
              Visit Website
              <CiShare1 />
            </Link>
          </div>

          <div className="featuredLast featuredDiv">
            <h2>
              Register your course or training in KosovaEducation platform
            </h2>

            <p>
              Help students find your courses faster through our community
              platfrom.
            </p>

            <Link to="">Get started</Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FeaturedDetails;
