import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import { Link } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BiMenuAltRight } from "react-icons/bi";
import { VscChromeClose } from "react-icons/vsc";
import AboutUs from "./AboutUs/AboutUs";
import WhatWeDo from "./WhatWeDo/WhatWeDo";
import Education from "./Education/Education";
import Resources from "./Resources/Resources";
import TakeAction from "./TakeAction/TakeAction";
import MobileNavbar from "../MobileNavbar/MobileNavbar";
import SelectLanguage from "../SelectLanguage/SelectLangugage";

import { FaInstagramSquare } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import { FaSquareFacebook } from "react-icons/fa6";
import Settings from "../Settings/Settings";
import { IoMdSearch } from "react-icons/io";
import axios from "axios";

const Navbar = (props) => {
  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarClasses = `Navbar ${scrolled ? "scrolled" : ""}`;

  const [pageData, setPageData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ROUTE}BottomEducation/BottomEducation.json`)
      .then((res) => {
        setPageData(res.data);
      })
      .catch((error) => {
        console.error("Page4 : ", error);
      });
  }, []);

  const filteredData = pageData.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={navbarClasses}>
      <div className="navbar__top">
        <div className="navbar__top__left">
          <div className="language">
            <SelectLanguage
              setLanguage={props.setLanguage}
              language={props.language}
            />
          </div>

          <div className="navbar__top__social">
            <Link to="https://www.facebook.com/kosovaeducationcom">
              <span className="socialIcon" id="facebook">
                <FaSquareFacebook />
              </span>
            </Link>
            <Link to="https://www.instagram.com/kosovaedu/">
              <span className="socialIcon" id="instagram">
                <FaInstagramSquare />
              </span>
            </Link>
            <Link to="">
              <span className="socialIcon" id="twitter">
                <FaTwitterSquare />
              </span>
            </Link>
            <Link to="">
              <span className="socialIcon" id="linkedin">
                <FaLinkedin />
              </span>
            </Link>
          </div>
        </div>

        <div className="navbar__top__right">
          <div className="navSettings">
            <Settings />
          </div>

          <Link to="" >
            <p className="makeBold">
              <span className="navbar__top__icons" id="donate"></span>
              <FormattedMessage id="navbar__1" defaultMessage={"Donate"} />
            </p>
          </Link>

          <Link to="">
            <p>
              <span className="navbar__top__icons" id="apps"></span>
              <FormattedMessage id="navbar__2" defaultMessage={"Apps"} />
            </p>
          </Link>

          <Link to="">
            <p>
              <span className="navbar__top__icons" id="contact"></span>
              <FormattedMessage id="navbar__3" defaultMessage={"Contact us"} />
            </p>
          </Link>
        </div>
      </div>

      <div className="navbar__bottom">
        <div className="navbar__bottom__left">
          <Link to="/">
            <div className="navbar__logo">
              <div className="onlyLogo">
                <div className="hat"></div>
                <div className="kosova"></div>
              </div>
              <p>
                Kosova
                <span>Education</span>
              </p>
            </div>
          </Link>

          <div className="navbar__head">
            <div className="navbar__link">
              <Link to="/aboutus">
                <FormattedMessage id="navbar__4" defaultMessage={"About us "} />
                <MdKeyboardArrowDown id="onHoverUp" />
              </Link>

              <div className="navbar__mega">
                <AboutUs />
              </div>
            </div>

            <div className="navbar__link">
              <Link to="">
                <FormattedMessage
                  id="navbar__5"
                  defaultMessage={"What we do"}
                />
                <MdKeyboardArrowDown id="onHoverUp" />
              </Link>

              <div className="navbar__mega">
                <WhatWeDo />
              </div>
            </div>

            <div className="navbar__link">
              <Link to="">
                <FormattedMessage id="navbar__6" defaultMessage={"Education"} />
                <MdKeyboardArrowDown id="onHoverUp" />
              </Link>

              <div className="navbar__mega">
                <Education />
              </div>
            </div>

            <div className="navbar__link">
              <Link to="">
                <FormattedMessage id="navbar__7" defaultMessage={"Resources"} />
                <MdKeyboardArrowDown id="onHoverUp" />
              </Link>

              <div className="navbar__mega">
                <Resources />
              </div>
            </div>

            <div className="navbar__link">
              <Link to="">
                <FormattedMessage
                  id="navbar__8"
                  defaultMessage={"Take action"}
                />
                <MdKeyboardArrowDown id="onHoverUp" />
              </Link>

              <div className="navbar__mega">
                <TakeAction />
              </div>
            </div>
          </div>
        </div>

        <div className="navbar__bottom__right">
          <div className="navbar__search">
            <span id="search"></span>
            <input
              type="search"
              placeholder="Search by keyword"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {searchTerm && (
            <div className="filteredData">
              {filteredData.map((item) => (
                <Link
                  key={item.id}
                  to={{
                    pathname: `/education/${item.id}/${item.name.replace(/ /g, "_")}`,
                    state: { educationData: item },
                  }}
                >
                  <h2>{item.name}</h2>
                </Link>
              ))}
            </div>
          )}

          <Link to="/login">
            <FormattedMessage id="navbar__9" defaultMessage={"Log in"} />
          </Link>

          <Link className="navbar__button" to="/register">
            <span id="createAccount"></span>
            <FormattedMessage
              id="navbar__10"
              defaultMessage={"Create account"}
            />
          </Link>
        </div>

        <div className="mobile">
          <div className="mobileSearch">
            <IoMdSearch
              onClick={() => {
                setOpenSearch(!openSearch);
                setSearchTerm("");
              }}
              className=""
            />

            <div
              className={`mobile__navbar__search ${
                !openSearch ? "closeSearch" : ""
              }`}
            >
              <IoMdSearch />
              <input
                type="search"
                placeholder="Search by keyword"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            {searchTerm && (
              <div className="filteredData">
                {filteredData.map((item) => (
                  <Link
                    key={item.id}
                    to={{
                      pathname: `/education/${item.id}/${item.name.replace(/ /g, "_")}`,
                      state: { educationData: item },
                    }}
                  >
                    <h2>{item.name}</h2>
                  </Link>
                ))}
              </div>
            )}
          </div>

          <div className="mobileIconCloseOpen" onClick={handleOpen}>
            {!open ? (
              <BiMenuAltRight />
            ) : (
              <VscChromeClose className="closeBtn" />
            )}
          </div>

          <div className={`openMobile ${!open ? "closeMobile" : ""}`}>
            <MobileNavbar setOpenMobile={setOpen} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
