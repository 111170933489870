import React from "react";
import "./Education.scss";
import Navbar from "../../components/shared/Navbar/Navbar";
import Footer from "../../components/shared/Footer/Footer";
import TopEducation from "../../components/Education/TopEducation/TopEducation";
import BottomEducation from "../../components/Education/BottomEducation/BottomEducation";

const EducationPage = () => {
  return (
    <div className="EducationInstitution">
      <Navbar />

      <div className="inside__EducationInstitution">
        <TopEducation />
        <BottomEducation />
      </div>

      <Footer />
    </div>
  );
};

export default EducationPage;
