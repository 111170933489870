import React, { useEffect, useRef, useState } from 'react'
import './Page8.scss'
import Page8Card from './Page8Card/Page8Card'
import axios from 'axios';
import Carousel from 'react-elastic-carousel'
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl'
import AOS from 'aos';
import "aos/dist/aos.css";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width:550, itemsToShow: 2 },
  { width:900, itemsToShow: 3 },
  { width:1400, itemsToShow: 4 }
];

const Page8 = () => {
  const [pageData, setPageData] = useState([])
  
  useEffect(() => {
    AOS.init();
  }, [])

  useEffect(() => {

    axios.get(`${process.env.REACT_APP_API_ROUTE}ActiveProjects/ActiveProjects.json`)
    .then(res => {
      setPageData(res.data)
    })
    .catch(error => {
        console.error("ActiveProejcts : ", error)
    })

  }, [])
  
  const carouselRef = useRef(null);
  const onNextStart = (currentItem, nextItem) => {
      if (currentItem.index === nextItem.index) {
        carouselRef.current.goTo(0);
      }
  };
  const onPrevStart = (currentItem, nextItem) => {
      if (currentItem.index === nextItem.index) {
        carouselRef.current.goTo('single-slide'.length);
      }
  };

  return (
    <div className='Page8'>

      <h1 data-aos="fade-right">
      <FormattedMessage id="page8__1" defaultMessage={"Active projects"} />
      </h1>

      <div className="page8__buttons">
          <Link to="" id='preSchools' className='educationButton page8active' data-aos="fade-up">
            <FormattedMessage id="page8__2" defaultMessage={"All Projects"} />
          </Link>

          <Link to="" id='elementarySchool' className='educationButton' data-aos="fade-down">
            <FormattedMessage id="page8__3" defaultMessage={"Build schools"} />
          </Link>

          <Link to="" id='highSchool' className='educationButton' data-aos="fade-up">
            <FormattedMessage id="page8__4" defaultMessage={"Scholarships"} />
          </Link>

          <Link to="" id='universities' className='educationButton' data-aos="fade-down">
            <FormattedMessage id="page8__5" defaultMessage={"Fundraiser"} />
          </Link>
      </div>

      <div className="carouselPage8" data-aos="fade-left">
        <Carousel className="slider styled-arrows" 
            breakPoints={breakPoints}
            ref={carouselRef}
            onPrevStart={onPrevStart}
            onNextStart={onNextStart}
            disableArrowsOnEnd={false}
        >
          {
            pageData.map((item) => (
              <Link
                className='hoverA'
                to=""
              >
                <Page8Card 
                  key={item.id}
                  bgImage={`${process.env.REACT_APP_ACIVEPROJECTS_IMAGE}${item.bgImage}`}
                  smallIcon={`${process.env.REACT_APP_ACIVEPROJECTS_IMAGE}${item.smallIcon}`}
                  smallText={
                    <FormattedMessage id={`page8SmallText__${item.id}`} defaultMessage={item.smallText} />
                  }
                  title={
                    <FormattedMessage id={`page8Title__${item.id}`} defaultMessage={item.title} />
                  }
                  description={
                    <FormattedMessage id={`page8Description__${item.id}`} defaultMessage={item.description} />
                  }
                  raised={item.raised}
                  goal={item.goal}
                />
              </Link>
            ))
          }
        </Carousel>
      </div> 

      <Link to="/activeprojects" className='page8Explore' data-aos="fade-up">
        <FormattedMessage id="page8__6" defaultMessage={"View all"} />
      </Link>

    </div>
  )
}

export default Page8